import { Component, EventEmitter, HostBinding, Input, OnInit, Output, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AUTH_SERVICE,
  ChangeRole,
  ClassGroupInterface,
  LoginCredentials,
  UrlLoginCredentials,
} from '@campus/public-portal';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { PersistedUsersFacade } from '../../facades/persisted-users.facade';
import { URL_LOGIN_ADAPTER } from '../../services/auth/url-login.adapter';
import { ClassGroupService } from '../../services/classgroup.service';
import { PersistedUserCredentialsInterface } from '../../services/persisted-users.service.interface';
import { RedirectService } from '../../services/redirect.service';

@Component({
  selector: 'kabas-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @HostBinding('class') class = 'kabas-login';

  private store = inject(Store);
  private classGroupService = inject(ClassGroupService);
  private persistedUsers = inject(PersistedUsersFacade);
  private redirectService = inject(RedirectService);
  private authService = inject(AUTH_SERVICE);

  public formBuilder = inject(FormBuilder);
  public scanError$?: Observable<string>;

  @Input() role: string;
  @Input() success: boolean;
  @Input() qrScanner: boolean;

  @Output() formSubmitted = new EventEmitter<LoginCredentials>();
  @Output() toggleQrScanner = new EventEmitter<boolean>();
  @Output() resetPasswordEvent = new EventEmitter<string>();

  /**
   * The login form
   *
   * @type {FormGroup}
   * @memberof LoginFormComponent
   */
  loginForm: FormGroup;
  classGroups$: Observable<ClassGroupInterface[]>;
  persistedUsers$: BehaviorSubject<PersistedUserCredentialsInterface[]>;

  /**
   * Life cycle hook: initializes the login form with default values and validators
   *
   * @memberof LoginFormComponent
   */
  ngOnInit() {
    this.createLoginForm();
    this.persistedUsers$ = this.persistedUsers.getAll();
    this.scanError$ = this.authService.error$;
  }

  /**
   * Creates the login form
   *
   * @memberof LoginFormComponent
   */
  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      emailOrUsername: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  /**
   * EmailorUsername form control
   *
   * @readonly
   * @memberof LoginFormComponent
   */
  get emailOrUsername(): AbstractControl {
    return this.loginForm.get('emailOrUsername');
  }

  /**
   * Password form control
   *
   * @readonly
   * @type {AbstractControl}
   * @memberof LoginFormComponent
   */
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  get qrScannerVisible(): boolean {
    return this.qrScanner && this.role === 'student';
  }

  /**
   * Handles logging in the user, success or failure
   *
   * @memberof LoginFormComponent
   */
  public login(data: LoginCredentials) {
    this.formSubmitted.emit(data);
  }

  public toggleRole() {
    this.store.dispatch(new ChangeRole({ role: this.role === 'teacher' ? 'student' : 'teacher' }));
  }

  public schoolCodeSet(schoolCode: number) {
    this.classGroups$ = this.classGroupService.getForSchoolCode(schoolCode);
  }

  public showQrScanner(event: boolean) {
    this.toggleQrScanner.emit(event);
  }

  public goToPlatform() {
    const url = this.redirectService.getPlatformUrl(this.role);
    return this.redirectService.redirect(url);
  }

  public forgetPerson(person: PersistedUserCredentialsInterface) {
    return this.persistedUsers.remove(person.id);
  }

  public resetPassword(email: string) {
    this.resetPasswordEvent.emit(email);
  }

  public ssoLogin(adapter: string): void {
    this.authService.setAdapter(adapter).login();
  }

  public onScanResultReceived(result: string) {
    this.authService
      .setAdapter(URL_LOGIN_ADAPTER)
      .withCredentials({ url: result } as UrlLoginCredentials)
      .login();
  }
}
