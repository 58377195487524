import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { PersistedUserCredentialsInterface } from '../../services/persisted-users.service.interface';

@Component({
  selector: 'kabas-persisted-user-card',
  styleUrls: ['./persisted-user-card.component.scss'],
  templateUrl: './persisted-user-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersistedUserCardComponent {
  @HostBinding('class.kabas-persisted-user-card')
  hasPersistedUserCardClass = true;

  menuOpen = false;

  @Input() person: PersistedUserCredentialsInterface;
  @Input() isQuickLogin = false;
  @Input() isAddNew = false;
  @Input() isQRLogin = false;
  @Input() isLeerIDLogin = false;

  @Output() forgetAboutMe = new EventEmitter<PersistedUserCredentialsInterface>();

  @Output() quickLogin = new EventEmitter<PersistedUserCredentialsInterface>();
  @Output() addNew = new EventEmitter<void>();
  @Output() userCardClicked = new EventEmitter<void>();

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
  clickOutsideMenu() {
    this.menuOpen = false;
  }

  @HostListener('click')
  onClick() {
    if (this.isQuickLogin) {
      this.quickLogin.emit(this.person);
    } else {
      this.userCardClicked.emit();
    }
  }
}
