<h1>Privacy</h1>
<div>
  <div class="[ stack stack-space-2xl ]">
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Inleiding </h2>
        <p>
          In dit document worden er meer details gegeven over de manier waarop er met ‘persoonsgegevens’ wordt omgegaan.
          Hetzij bewust, hetzij onbewust, geef je als gebruiker van KABAS steeds gegevens vrij (van jezelf of van
          anderen) die ons toelaten om jou als persoon te identificeren, ongeacht of we dit effectief doen of niet.
          Bepaalde informatie is al een persoonsgegeven zodra men een directe of indirecte link kan leggen tussen deze
          informatie en jou.
        </p>
        <p>
          Die Keure, de organisatie achter KABAS, hecht een groot belang aan de wijze waarop er met jouw
          persoonsgegevens en die van anderen omgesprongen wordt. Ook de wetgever onderstreept het belang hiervan, en
          heeft een aantal verplichtingen hieromtrent neergeschreven in de ‘Europese Algemene Verordening
          Gegevensverwerking’ (ook gekend als ‘GDPR’). In het onderstaande document komen de verschillende aspecten aan
          bod die deel uitmaken van deze verordening.
        </p>
        <p>
          De policy is opgesteld in zo duidelijk mogelijke bewoordingen, ook voor leerlingen. Wij gaan er echter van uit
          dat er af en toe nog vragen kunnen blijven bestaan. Indien bepaalde woorden of zinnen onduidelijk zijn, of
          indien u in het algemeen meer informatie wenst, aarzel dan niet om contact op te nemen met onze helpdesk via
          <a href="mailto:kabas@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >kabas&#64;diekeure.be</a
          >.
        </p>
        <p> Die Keure, de organisatie achter KABAS is de volgende onderneming: </p>
        <p>
          die Keure NV <br />
          Kleine Pathoekeweg 3 <br />
          8000 Brugge België <br />
          BTW 0405.108.325
        </p>
        <p>
          Indien je als bezoeker gebruik maakt van KABAS en indien je besluit jezelf en/of leerlingen en leerkrachten te
          registreren of ervoor kiest gegevens op KABAS mee te delen, verklaar je jezelf akkoord met het gebruik van de
          door jou opgegeven gegevens in overeenstemming met deze verklaring. Houd er rekening mee dat KABAS links kan
          bevatten naar andere websites die mogelijks niet onderworpen zijn aan deze verklaring en waarvoor die Keure
          dus niet aansprakelijk of verantwoordelijk is.
        </p>
        <p>
          Als die Keure deze verklaring wijzigt nadat je die eerder goedkeurde, dan zal die Keure er alles aan doen om
          je die wijziging ook te laten weten. Wanneer je na de wijziging inlogt zal je, via een banner of via een
          pop-up, op de hoogte gebracht worden van de nieuwe verklaring. Op dat moment zal je mogelijks verplicht zijn
          om eerst de nieuwe verklaring door te lezen en een goedkeuring te geven, voordat je verder kan werken op
          KABAS.
        </p>
        <p>
          Die Keure wil graag benadrukken dat zij zich bewust is van de moeilijkheden en uitdagingen waarmee ouders
          (en/of bevoegde meerderjarigen zoals leerkrachten, voogd, ...) mogelijk worden geconfronteerd bij het
          hedendaagse internetgebruik van minderjarige kinderen respectievelijk leerlingen. Die Keure stelt dan ook
          alles in het werk om een zo veilig mogelijke internetomgeving te creëren voor minderjarigen met inachtneming
          van een gezonde dosis voorzichtigheid bij het ontwerpen van de inhoud van KABAS en het nodige respect bij de
          verwerking van de gegevens van deze minderjarigen.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Het verzamelen van gegevens en bijhorende goedkeuringen </h2>
        <p>
          Indien in deze verklaring gesproken wordt over ‘gegevens’ kan dit zowel over gegevens gaan van meerderjarigen
          (leerkrachten) als van minderjarigen (leerlingen).
        </p>
        <p>
          Wat de minderjarige kinderen betreft (kinderen uit het basisonderwijs) zijn ouders en bevoegde meerderjarigen
          aansprakelijk voor de handelingen van minderjarige kinderen/leerlingen. Gezien de leeftijd van de kinderen en
          gezien de voorziene wetgeving is het niet aan de kinderen om toelating te geven voor het gebruik van KABAS,
          maar wel aan de mensen die aansprakelijk zijn voor hun handelingen (ouders, voogd, ...).
        </p>
        <h3>Hoe gaat die Keure om met de goedkeuring van ouders als de ouders zelf niet met KABAS werken? </h3>
        <p>
          De verantwoordelijkheid om kinderen te registreren in klassen ligt in handen van een schoolbeheerder binnen
          een school. Kinderen kunnen nooit zelf een profiel maken en op deze manier ook nooit zelf persoonsgegevens
          achterlaten.
        </p>
        <p>
          De schoolbeheerder zal dit enkel doen wanneer de ouders een goedkeuring gegeven hebben. De school als
          verantwoordelijke voor de verwerking van gegevens neemt hiervoor de nodige actie.
        </p>
        <p>
          Die Keure gaat ervan uit dat de goedkeuring door de ouders het best via het schoolreglement gegeven wordt.
          Ouders moeten dus expliciet aanduiden dat zij akkoord gaan met het gebruik van KABAS (en het bewaren van
          persoonsgegevens door die Keure via KABAS) door hun kinderen. Het is uiteraard aan de school om op een
          alternatieve wijze goedkeuring te vragen aan de ouders.
        </p>
        <p>
          Indien de school geen goedkeuring van de ouders heeft kan de schoolbeheerder altijd beslissen om de gegevens
          te anonimiseren. In dat geval geeft de schoolbeheerder het kind een pseudoniem ipv de echte naam.
        </p>
        <h3>Over welke persoonsgegevens gaat het eigenlijk? </h3>
        <p>
          Die Keure verzamelt via KABAS persoonsgegevens. Die Keure zal niet meer gegevens verzamelen dan nodig voor
          haar opdracht t.a.v. de scholen. In het kader van deze opdracht zal die Keure de volgende persoonsgegevens
          verzamelen:
        </p>
        <ul class="[ title-small ]">
          <li>
            <b>Naam en voornaam</b> van alle gebruikers wordt opgeslagen en verwerkt om tot een efficiënt gebruik van
            KABAS te komen. Zo kunnen leerkrachten de resultaten per leerling bekijken en kunnen zij differentiëren bij
            het toekennen van oefeningen.
          </li>
          <li>
            <b>E-mailadres</b> van leerkrachten en schoolbeheerders. Zo kunnen leerkrachten aanmelden, hun wachtwoord
            wijzigen. Die Keure gebruikt het e-mailadres ook om leerkrachten nieuwsbrieven te sturen. Zij kunnen hierop
            uitschrijven. Van leerlingen wordt geen e-mailadres bijgehouden.
          </li>
          <li>
            <b>School</b> wordt opgeslagen voor een vlotte werking van KABAS. Via de naam van de school wordt support
            een stuk makkelijker gemaakt indien er zich problemen voordoen. Naast de school worden ook de klassen, het
            klasnummer en het leerjaar bijgehouden om leerlingen te kunnen laten aanmelden, ze vlot aan de juiste
            leerkrachten te koppelen, en ervoor te zorgen dat ze de juiste lesmaterialen zien, en dat deze juist
            gefactureerd worden aan de school.
          </li>
          <li>
            <b>Uniek nummer van de school</b> (het zogenaamde stamnummer) wordt opgeslagen als unieke sleutel en link
            naar het administratiesysteem van de school.
          </li>
          <li> <b>IP-adres</b> wordt opgeslagen en verwerkt voor rapporteringsdoeleinden. </li>
          <li> Het leerID van de leerling zoals voorzien door Onderwijs Vlaanderen </li>
          <li>
            In de Kweetet Game wordt de keuze van de kinderen opgeslagen voor het geslacht van de avatar omdat deze in
            het spel vrouwelijk of mannelijk kan zijn. Het geslacht van de kinderen zelf wordt niet gevraagd.
          </li>
          <li>
            Om inzicht te krijgen in bekwaamheid van leerling, om hun voortgang op te volgen, en om de performantie van
            de oefeningen te verbeteren en om leerinhoud op maat van de leerling te kunnen voorstellen worden volgende
            zaken opgeslagen:
            <ul>
              <li>
                <b>Voortgang</b> in leersporen (lln): om inzicht te geven waar een lln zich bevindt in het traject
              </li>
              <li>
                <b>Bekwaamheid</b> bij lesdoelen: om gericht oefeningen te kunnen voorstellen voor leerlingen, en inzage
                in de bekwaamheid te krijgen
              </li>
              <li>
                <b>Scores</b>, met daarbij volgende extra data: tijd gespendeerd, afgewerkt (boolean), aantal pogingen,
                hulp nodig (boolean), tijdstip -> bij digitale oefeningen: om gericht oefeningen te kunnen voorstellen
                voor leerlingen, en inzage in de bekwaamheid te krijgen
              </li>
              <li>
                Registratie van <b>leerhandelingen</b> (answered, experienced, completed, rated, …) met bijhorende
                details
              </li>
            </ul>
          </li>
          <li>Historiek van handelingen in het platform </li>
          <li>
            Gegevens bewaard bij ingave contactformulier ( Om een correcte service te kunnen leveren als
            helpdesk/besteldienst):
            <ul>
              <li>Naam & voornaam</li>
              <li>Schoolnaam</li>
              <li>E-mail</li>
              <li>Telefoon</li>
              <li>Bericht gericht aan die Keure</li>
            </ul>
          </li>
        </ul>
        <p>
          Die Keure verzamelt geen gevoelige persoonsgegevens over personen (zoals gegevens over geslacht, ras,
          politieke opvattingen, gezondheid, godsdienstige en andere geloofsovertuigingen)
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>De school als verantwoordelijke voor de verwerking van persoonsgegevens </h2>
        <p>
          Aangezien de school verantwoordelijk is voor de verwerking van de persoonsgegevens in KABAS (zowel
          leerkrachten als leerlingen) is het ook aan de school om de gebruikers correct te informeren over het gebruik
          van deze hun persoonsgegevens. Eventuele uitoefeningen van rechten (bijvoorbeeld om gewist te worden als
          gebruiker) dienen gericht te zijn aan de school, aangezien het de school is die de gegevens registreert en
          onderhoudt.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Die keure als verwerker van persoonsgegevens op eigen initiatief </h2>
        <p class="[ title-small ]"
          >Die Keure zal persoonsgegevens op eigen initiatief steeds zo weinig mogelijk gebruiken, en zal altijd
          duidelijk zijn wanneer ze dit wel doet. De reden om persoonsgegevens als niet-verantwoordelijke voor de
          verwerking toch te gebruiken heeft te maken met directe marketing, met de goede technische en inhoudelijke
          werking van het systeem, alsook met veiligheid (bijvoorbeeld de detectie van fraude), of met het doel om de
          werking van het systeem te verbeteren op basis van verzamelde gegevens. Meer bepaald kunnen persoonsgegevens
          gebruikt worden voor de volgende doeleinden:
        </p>
        <ul class="[ title-small ]">
          <li> bezoekersadministratie (o.a. bevestigingsmail betreffende inloggegevens) van leerkrachten; </li
          ><li>
            directe marketing waaronder het verzenden van minstens één nieuwsbrief met product-gerelateerde informatie
            (iedereen kan zich altijd uitschrijven bij het ontvangen van een nieuwsbrief, vanaf dan zal ons systeem geen
            nieuwe brieven meer sturen tenzij iemand zich opnieuw inschrijft). Dit geldt enkel voor volwassenen. Die
            Keure zal zich nooit rechtstreeks naar kinderen richten voor directe marketing; </li
          ><li> het verbeteren van de inhoud en de kwaliteit van KABAS; </li
          ><li>
            algemene monitoring van de acties ondernomen op het onderwijsplatform door de verschillende gebruikers
            (leerlingen en leerkrachten);
          </li>
          <li> een betere afstemming van KABAS op de interesses, noden en wensen van de bezoekers ervan en </li>
          <li>
            het accuraat bijhouden, verbeteren en vervolledigen van de persoonsgegevens zoals door de wet vereist.
          </li>
          <li> Het detecteren van fraude. </li>
          <li> Het melden van een datalek. </li>
          <li
            >Het verbeteren van AI-algoritmes a.d.h.v. trainingsdata.<br />
            Leerlingen kunnen oefeningen maken in taallessen om hun uitspraak te verbeteren. Een AI-algoritme evalueert
            dan de uitspraak en geeft feedback aan de leerling. Opnames van uitspraakoefeningen worden pseudoniem
            opgeslagen als trainingsdata met als doel het algoritme te trainen en zo te verbeteren. Aangezien de
            beslissing om deze oefeningen te gebruiken bij de school ligt, is het aan de school om hierover te
            communiceren naar de ouders van de leerlingen. (Indien ouders hiertegen bezwaar hebben, kunnen ze zelf op
            het platform, bij de profielinstellingen van de leerling, aangeven dat deze data niet bijgehouden en
            hergebruikt mag worden. Instructies hiervoor zijn ook beschikbaar in de veelgestelde vragen van het
            platform. Indien u meer informatie wilt hieromtrent, neem dan contact op met
            <a href="mailto:kabas@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
              >kabas&#64;diekeure.be</a
            >.)
          </li>
        </ul>
        <h3>Opslag en bewaring van de persoonsgegevens </h3>
        <p>
          De persoonsgegevens van de gebruikers worden bewaard via een hosting oplossing van Amazon webservices. De
          spelgegevens van de Kweetet game worden bewaard via een hosting oplossing van een externe Belgische
          leverancier (Combell). Via up to date beveiligingsmechanismen worden deze gegevens beveiligd zodat er optimale
          bescherming is naar mogelijke datalekken toe. </p
        ><p>
          Indien je meer informatie wil over onze overeenkomst met AWS of Combell hieromtrent, neem dan contact op via
          <a href="mailto:kabas@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >kabas&#64;diekeure.be</a
          >.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Het doorgeven van persoonsgegevens aan sub-verwerkers. </h2>
        <p>
          Die Keure zal geen gegevens met derden delen voor secundaire of niet-gerelateerde doeleinden tenzij anders
          vermeld op het moment van het verzamelen van deze gegevens. De werknemers van die Keure of de door die Keure
          ingeschakelde derden zijn verplicht om de vertrouwelijkheid van de gegevens op KABAS te respecteren. </p
        ><p>
          Waar gepast of vereist kunnen en zullen persoonsgegevens door die Keure worden verstrekt aan de
          rechtshandhaving, regelgevende of andere overheidsinstanties, of derde partijen indien dit noodzakelijk of
          wenselijk is om te voldoen aan wettelijke of reglementaire verplichtingen of in het kader van de hierboven
          vermelde doeleinden. </p
        ><p>
          Voor specifieke verklaringen van derde partijen verwijzen we naar de verklaringen van onderstaande gekende
          partijen. Deze lijst kan toekomstig nog aangepast worden:
        </p>
        <h3> Integratie met Adaptemy </h3>
        <p>
          Voor de realisatie van de adaptieve leersporen doet die Keure beroep op de AI engine van Adaptemy. Hiervoor
          worden gebruikersgegevens geanonimiseerd doorgeven. </p
        ><p>
          Adaptemy:
          <a
            href="https://www.adaptemy.com/privacy-policy/"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >https://www.adaptemy.com/privacy-policy/</a
          >
        </p>
        <h3> Integratie met Google Analytics </h3>

        <p>
          Log-informatie wordt doorgegeven en verwerkt door Google Analytics voor analytische doeleinden (bijv. om het
          bezoek aan de Website op te volgen (daluren, piekmomenten, ...). Met log-informatie bedoelen we informatie
          zoals ‘tijdstip’ en ‘IP-adres’. Ook locatiegegevens worden doorgegeven, zodat jouw vermoedelijke locatie op
          een kaart kan worden weergegeven. Ook informatie met betrekking tot het gebruikte toestel, zoals hardware- en
          software-informatie, wordt gebruikt om het gebruik van Kabas te monitoren. </p
        ><p>
          Google Analytics :
          <a
            href="http://www.google.com/analytics/terms/nl.html"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >http://www.google.com/analytics/terms/nl.html</a
          >
        </p>
        <h3>Integratie met SmartGames Playroom</h3>
        <p>
          Scholen hebben de mogelijkheid om een licentie aan te kopen voor SmartGames Playroom via Kabas of de die Keure
          Webshop. Hierbij kunnen kinderen via Kabas in 1 klik aanmelden en worden ze automatisch doorgestuurd naar
          Playroom. Daar zijn ze automatisch gekoppeld aan de juiste leerkracht en klas. Om dit te kunnen doen, sturen
          we de volgende gegevens door: naam, voornaam, klasnummer en klasnaam i.g.v. een leerling en de schoolgegevens.
          Playroom stuurt geen gegevens terug over de activiteiten van de leerlingen op Playroom.
        </p>
        <p>
          Meer info over hoe SmartGames omgaat met data vind je op hun
          <a
            href="https://playroom.smartgames.com/nl/privacybeleid"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >playroom privacy policy</a
          >.
        </p>
        <h3>Integratie met Learnosity</h3>
        <p>
          Digitale oefeningen op Kabas worden gemaakt met het beoordelingssoftware van Learnosity. Dit is een online
          dienst die geïntegreerd is in Kabas. Alles wat ingegeven wordt in de oefeningen wordt opgeslagen bij
          Learnosity en resultaten/scores van deze oefeningen worden doorgegeven aan Kabas. Enkel een gepseudonimiseerd
          userId wordt doorgegeven aan Learnosity, zodat resultaten bij Learnosity niet direct herleidbaar zijn tot een
          persoon.
        </p>
        <p>
          Meer info over hoe Learnosity omgaat met data vind je op hun
          <a href="https://www.learnosity.com/dpa" class="[ weight-bold text-primary hover:underline ]" target="_blank"
            >privacy policy</a
          >.
        </p>
        <h3>Integratie met NovoLearning</h3>
        <p>
          Digitale spraak-oefeningen op Kabas worden gemaakt met het software van NovoLearning. Dit is een online dienst
          die geïntegreerd is in Kabas. Alle input bij het maken van de oefeningen wordt opgeslagen bij NovoLearning, en
          resultaten/scores van deze oefeningen worden doorgegeven aan Kabas. Enkel een gepseudonimiseerd userId wordt
          doorgegeven aan NovoLearning, zodat resultaten bij NovoLearning niet direct herleidbaar zijn tot een persoon.
        </p>
        <p>
          Meer info over hoe NovoLearning omgaat met data vind je op hun
          <a
            href="https://www.novo-learning.com/privacy/"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >privacy policy</a
          >.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Goede technische werking </h2>
        <p class="[ title-small ]"
          >Met het oog op een degelijke en adequate besturing van KABAS en teneinde een verbeterde navigatie mogelijk te
          maken binnen KABAS, is het mogelijk dat die Keure of die Keure’s service providers zogenaamde cookies zullen
          gebruiken. Die Keure maakt gebruik van de volgende cookies:
        </p>
        <ul class="[ title-small ]">
          <li> authentication cookies </li
          ><li> load balancing session cookies </li>
        </ul>
        <p>
          Cookies zijn kleine tekstbestanden of informatiedeeltjes die opgeslagen worden op de harde schijf van een
          gebruiker. Cookies zijn bestanden die als het ware de sporen bijhouden van de voorafgaande bezoeken van
          bepaalde websites en de informatie die met deze bezoeken gepaard gaat. Er bestaan technische cookies
          (bijvoorbeeld m.b.t. taalinstellingen), sessiecookies (tijdelijke cookies) en tracking cookies (cookies die
          betrekking hebben op het gedrag van een website-bezoeker die deze bezoekers a.h.w. op KABAS gaan volgen en de
          gedragingen bijhouden met het oog op een optimale gebruikservaring voor KABAS-bezoekers).
        </p>
        <p>
          Web beacons zijn elektronische beelden die het mogelijk maken het aantal bezoekers op bepaalde websites te
          tellen en die toegang verlenen tot bepaalde cookies.
        </p>
        <p>
          Die Keure kan deze instrumenten gebruiken om informatie op haar systemen op te volgen en om categorieën van
          gebruikers te identificeren aan de hand van gegevens zoals het IP-adres, het domein, het type van browser en
          de bezochte pagina's. Deze informatie wordt meegedeeld aan die Keure’s webmasters die deze informatie
          gebruiken om het aantal bezoekers op de verschillende delen van KABAS te analyseren en om ervoor te zorgen dat
          KABAS functioneert als een bruikbare en effectieve bron van informatie.
        </p>
        <h3>Soorten cookies</h3>
        <ul class="[ title-small ]">
          <li>
            Functionele <br />
            Functionele cookies helpen een website gebruiksvriendelijk te maken doordat ze basisfunctionaliteit, zoals
            toegang verlenen tot een bepaalde zone van de website, mogelijk maken. De website kan zonder deze cookies
            niet goed functioneren.
          </li>
          <li>
            Voorkeuren <br />
            Dit zijn cookies die informatie over je keuzes en voorkeuren op een website bijhouden. Zo onthouden ze
            bijvoorbeeld je taalkeuze, inloggegevens of de regio waarin je woont.
          </li>
          <li>
            Marketing <br />
            Marketing cookies worden gebruikt om een bezoeker te volgen over meerdere websites. Het is de bedoeling
            advertenties weer te geven die relevant en aantrekkelijk zijn voor de individuele gebruiker en daardoor
            waardevoller zijn voor uitgevers en externe adverteerders.
          </li>
          <li>
            Statistieken <br />
            Statistische cookies verzamelen informatie over het gedrag van websitebezoekers en de prestaties van de
            website. Aan de hand van de resultaten kan de website verbeterd worden en krijgen bezoekers een betere
            gebruikservaring.
          </li>
          <li>
            Sociale media / derde partij <br />
            Social media cookies worden gebruikt om de integratie van sociaalnetwerksites op onze websites mogelijk te
            maken. Onze website kan ingebedde elementen van derde partijen bevatten. Dat kan gaan om inhoud die bij een
            andere partij opgeslagen is, maar die via onze website wordt getoond. Die derden kunnen via onze website
            cookies plaatsen om gegevens te verzamelen. Wijzelf hebben geen invloed op wat die sociale media met hun
            cookies doen. Voor meer informatie over deze cookies verwijzen we je graag naar de verklaringen die de
            derden hierover op hun eigen websites geven. Merk wel op dat die verklaringen regelmatig kunnen wijzigen.
          </li>
        </ul>
        <h3>Cookies die wij gebruiken op KABAS.be: </h3>
        <ul class="[ title-small ]">
          <li>
            Statistische cookies: worden gebruikt om Google Analytics te integreren.<br />
            Verdere details over de cookies kan je
            <a
              href="https://business.safety.google/adscookies/"
              class="[ weight-bold text-primary hover:underline ]"
              target="_blank"
              >hier</a
            >
            vinden.
          </li>
          <li> 3rd party: wordt gebruikt om de Wistia media player te integreren en media af te spelen. </li>
          <li>
            Voorkeurencookies
            <ul>
              <li>
                Voor leerlingen worden de aanmeldgegevens opgeslagen in de localstorage, om de volgende maal makkelijker
                te kunnen aanmelden, enkel als de leerling dit wenst.
              </li>
              <li>
                Zaken zoals gebruiksvoorkeuren en reeds bekeken rondleidingen worden ook in de localstorage opgeslagen,
                deze worden niet naar de server gestuurd en enkel lokaal gebruikt.
              </li>
            </ul>
          </li>
        </ul>

        <p> Een gebruiker van KABAS kan altijd de cookies verwijderen via zijn of haar browserinstellingen. </p>
        <ul class="[ title-small ]">
          <li>
            <a
              href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen"
              target="_blank"
              class="[ weight-bold text-primary hover:underline ]"
              >Mozilla Firefox
            </a> </li
          ><li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=nl"
              class="[ weight-bold text-primary hover:underline ]"
              target="_blank"
              >Google Chrome
            </a> </li
          ><li>
            <a
              href="https://support.apple.com/nl-nl/HT201265"
              class="[ weight-bold text-primary hover:underline ]"
              target="_blank"
              >Safari (iOS)
            </a> </li
          ><li>
            <a
              href="https://support.apple.com/nl-be/guide/safari/sfri11471/mac"
              class="[ weight-bold text-primary hover:underline ]"
              target="_blank"
              >Safari (macOS)
            </a> </li
          ><li>
            <a
              href="https://support.microsoft.com/nl-nl/help/10607/windows-10-view-delete-browser-history-microsoft-edge"
              target="_blank"
              class="[ weight-bold text-primary hover:underline ]"
              >Edge
            </a>
          </li>
        </ul>
        <p> De bezoeker kan te allen tijde de cookies verwijderen via zijn of haar browserinstellingen. </p>
        <p>
          Die Keure voorziet in een banner bij het eerste bezoek aan de Website waarin toestemming wordt gevraagd voor
          het plaatsen van cookies.
        </p>
        <p>
          Noch de cookies noch de web beacons gebruikt op KABAS verzamelen de naam of het e-mailadres van de bezoeker.
          Onder bepaalde omstandigheden is het echter mogelijk dat de bezoekers geen toegang verkrijgen tot bepaalde
          delen van KABAS, indien hun webbrowser zodanig is ingesteld dat ze cookies weigeren.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Wat zijn jouw rechten als gebruiker? </h2>
        <p>
          De school is de verantwoordelijke voor de verwerking van de persoonsgegevens in KABAS. Om rechten m.b.t. deze
          persoonsgegevens uit te oefenen dien je je eigen school te contacteren. De schoolbeheerder kan de
          persoonsgegevens aanpassen. Als iets niet duidelijk is, neem dan zeker even contact op met onze helpdesk via
          <a href="mailto:kabas@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >kabas&#64;diekeure.be</a
          >.
        </p>
        <h3>Rechtmatige en veilige verwerking van persoonsgegevens </h3>
        <p>
          Die Keure zal er op toezien dat de gegevensverwerking gebeurt op basis van het gezond verstand (doeltreffend,
          niet overmatig). </p
        ><p>
          De persoonsgegevens zullen niet langer worden opgeslagen dan nodig. Zolang je account actief is zullen we de
          persoonsgegevens bijhouden. </p
        ><p>
          Die Keure heeft naar haar mening voldoende beveiligingsmaatregelen getroffen om een veilige verwerking van de
          persoonsgegevens te waarborgen. </p
        ><p>
          De risico’s van een toevallige of ongeoorloofde vernietiging, toevallig verlies, de wijziging van of de
          toegang tot, en iedere andere niet toegelaten verwerking zijn zo goed mogelijk gereduceerd. Dit betekent
          helaas niet dat alle risico’s uitgesloten kunnen worden. Bij een inbreuk op de wettelijk toegestane
          verwerkingen in KABAS, zal die Keure onmiddellijk alle mogelijke maatregelen nemen om schade tot een minimum
          te beperken en het misbruik zo snel mogelijk rapporteren.
        </p>
        <h3>Recht op verzet, restrictie en gegevensoverdracht </h3>
        <p>
          Je kan je als gebruiker te allen tijde kosteloos en zonder motivering verzetten tegen de verwerking van je
          persoonsgegevens indien 1) die Keure je persoonsgegevens verwerkt op basis van een legitiem doel (en die Keure
          de persoonsgegevens niet verder moet verwerken op een andere juridische basis); of 2) deze persoonsgegevens
          verkregen werden met het oog op direct marketing. </p
        ><p>
          In de beperkte gevallen waar dit wettelijk is toegelaten kan je als gebruiker ook een verbod van de verwerking
          eisen. </p
        ><p>
          Verder kan je als gebruiker in bepaalde gevallen zoals bepaald door de wet ook de gegevensoverdracht eisen van
          de persoonsgegevens die je aan die Keure hebt verstrekt. Die Keure verbindt zich ertoe je deze
          persoonsgegevens op een gestructureerde, gangbare en machine-leesbare vorm mee te delen, zodat je deze
          persoonsgegevens indien gewenst kan doorgeven aan een andere verwerkingsverantwoordelijke. </p
        ><p>
          Als gebruiker kan je je rechten uitoefenen door ons te contacteren via
          <a href="mailto:kabas@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >kabas&#64;diekeure.be</a
          >. Mogelijks zal die Keure jou als gebruiker doorverwijzen naar de school als verantwoordelijke voor de
          verwerking.
        </p>
        <h3> Recht op toegang tot, verbetering en verwijdering van je persoonsgegevens </h3>
        <p>
          Je beschikt over een recht op toegang tot je persoonsgegevens. Die Keure engageert zich tot een zo nauwkeurig
          mogelijke gegevensverzameling. Onnauwkeurige of onvolledige persoonsgegevens kunnen steeds verbeterd of
          eventueel zelfs verwijderd worden. </p
        ><p>
          Jij als gebruiker hebt het recht onnauwkeurigheden en onvolledigheden te melden indien de nodige aanpassingen
          niet door jou binnen je gebruikersregistratie of profiel gemaakt kunnen worden. </p
        ><p>
          Als gebruiker kan je je rechten uitoefenen door ons te contacteren via
          <a href="mailto:kabas@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >kabas&#64;diekeure.be</a
          >. Mogelijks vragen wij jou als gebruiker om de school als verantwoordelijke van de verwerking te contacteren.
        </p>
        <h3>Recht om klacht in te dienen bij de Privacy-commissie </h3>
        <p>
          Je hebt als gebruiker het recht om een klacht in te dienen bij de Belgische Privacy-commissie, ook de
          ‘Commissie voor de bescherming van de persoonlijke levenssfeer’ genaamd. Voor meer informatie kan je terecht
          op
          <a
            href="https://www.gegevensbeschermingsautoriteit.be/burger"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >https://www.gegevensbeschermingsautoriteit.be/burger</a
          >.
        </p>
      </div>
    </section>
  </div>
</div>
