import { Injectable, InjectionToken, inject } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { AuthAdapterInterface, BaseAdapter, LoginUserFail, UrlLoginCredentials } from '@campus/public-portal';

export const URL_LOGIN_ADAPTER = new InjectionToken<AuthAdapterInterface>('URL_LOGIN_ADAPTER')

@Injectable({
  providedIn: 'root'
})
export class UrlLoginAdapter extends BaseAdapter {
  private window = inject(WINDOW);

  public login(): void {
    const credentials = this.credentials as UrlLoginCredentials;
    const isApiUrl = this.validateUrl(credentials.url);
    if (isApiUrl) {
      this.window.location.href = credentials.url;
    } else {
      this.store.dispatch(new LoginUserFail({ error: 'Deze URL is niet geldig. Probeer opnieuw.' }));
    }

  }

  private validateUrl(url: string): boolean {
    return url.startsWith(this.environment.api.APIBase);
  }
}
