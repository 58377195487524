<form
  class="kabas-form kabas-login-teacher__form"
  [formGroup]="form"
  (ngSubmit)="submit('basic')"
  data-cy="form-teacher-login"
>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'login'">
      <div
        class="kabas-form-field"
        [class.kabas-form-field--invalid]="
          form.get('email').touched && (form.get('email').hasError('required') || form.get('email').hasError('email'))
        "
        [@wiggle]="state === 'error'"
      >
        <label class="kabas-form-field__label" for="email" data-cy="label-login-form-email"
          >E-mailadres / Gebruikersnaam</label
        >
        <input
          class="kabas-form-field__control"
          id="email"
          formControlName="email"
          type="text"
          data-cy="input-login-form-email"
        />
        <div
          *ngIf="form.get('email').touched && form.get('email').hasError('required')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat je je e-mailadres / gebruikersnaam vergat in te vullen.</div
        >
        <div
          *ngIf="form.get('email').touched && form.get('email').hasError('email')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat dit geen geldig e-mailadres is.<br />Controleer je nog even?</div
        >
      </div>
      <div
        class="kabas-form-field"
        [class.kabas-form-field--invalid]="form.get('password').touched && form.get('password').hasError('required')"
        [@wiggle]="state === 'error'"
      >
        <label class="kabas-form-field__label" for="password" data-cy="label-login-form-password">Wachtwoord</label>
        <input
          class="kabas-form-field__control"
          id="password"
          required
          formControlName="password"
          type="password"
          data-cy="input-login-form-password"
        />
        <div
          *ngIf="form.get('password').touched && form.get('password').hasError('required')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat je je wachtwoord vergat.</div
        >
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'forgot-password'">
      <div
        class="kabas-form-field"
        [class.kabas-form-field--invalid]="
          form.get('email').touched && (form.get('email').hasError('required') || form.get('email').hasError('email'))
        "
        [@wiggle]="state === 'error'"
      >
        <label class="kabas-form-field__label" for="email" data-cy="label-request-password-form-email"
          >E-mailadres</label
        >
        <input
          class="kabas-form-field__control"
          id="email"
          required
          formControlName="email"
          type="text"
          data-cy="input-request-password-form-email"
        />
        <div
          *ngIf="form.get('email').touched && form.get('email').hasError('required')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat je je e-mailadres vergat in te vullen.</div
        >
        <div
          *ngIf="form.get('email').touched && form.get('email').hasError('email')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat dit geen geldig e-mailadres is.<br />Controleer je nog even?</div
        >
      </div>
    </ng-container>
  </ng-container>

  <div class="kabas-form__success" *ngIf="state === 'success'" data-cy="login-form-success">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 132.84 89.09"
      class="kabas-login__success-image"
      *ngIf="mode === 'login'"
    >
      <defs>
        <clipPath id="kabas-login__success-clip-path">
          <circle cx="62.79" cy="28.12" r="7.43" style="fill: none" />
        </clipPath>
        <linearGradient
          id="kabas-login__success-linear-gradient"
          x1="4489.81"
          y1="350.74"
          x2="4495.53"
          y2="349.1"
          gradientTransform="matrix(0.97, -0.33, 0.33, 0.95, -4409.44, 1174.6)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f7a68d" />
          <stop offset="1" stop-color="#eb8b78" />
        </linearGradient>
      </defs>
      <g>
        <g id="fire">
          <polygon points="81.59 88.2 44.01 88.2 53.92 39.19 71.68 39.19 81.59 88.2" style="fill: #ffd480" />
          <polygon points="71.13 88.2 54.47 88.2 58.57 39.19 67.03 39.19 71.13 88.2" style="fill: #ffb366" />
        </g>
        <g id="clouds">
          <path
            d="M5,88.94H127.81a10,10,0,0,0,5-8.59c0-5.62-4.82-10.17-10.77-10.17a11,11,0,0,0-8.46,3.88,10.77,10.77,0,0,0-10.09-6.62,11.65,11.65,0,0,0-2.79.35,10.87,10.87,0,0,0-9.41-5.23,11.13,11.13,0,0,0-5.87,1.64c0-.14,0-.28,0-.42A9.21,9.21,0,0,0,76,54.85a9.81,9.81,0,0,0-5.15,1.44,9.78,9.78,0,0,0-9.72-7.55,9.65,9.65,0,0,0-9.91,9.35c0,.26,0,.5,0,.75A9.75,9.75,0,0,0,45,56.56a9.31,9.31,0,0,0-9.37,7.71,11.09,11.09,0,0,0-7.14-2.55c-5.95,0-10.77,4.55-10.77,10.16,0,.24,0,.47,0,.7a11.16,11.16,0,0,0-6.94-2.4C4.82,70.18,0,74.73,0,80.35a10,10,0,0,0,5,8.59Z"
            style="fill: #f1f1f1"
          />
          <path
            d="M109.33,88.94a8.14,8.14,0,0,0-8.59-9,8.89,8.89,0,0,0-6,2.33c0-.15,0-.31,0-.46A7,7,0,0,0,87.5,75a7.38,7.38,0,0,0-5.07,2,7.78,7.78,0,0,0-7.51-5.33,8.12,8.12,0,0,0-4.44,1.3,8.56,8.56,0,0,0-8.35-6.1,8.43,8.43,0,0,0-8.63,7.88,7.43,7.43,0,0,0-4.63-1.59,7,7,0,0,0-7.21,6.81c0,.23,0,.45,0,.67a8.87,8.87,0,0,0-5.56-1.92,8.41,8.41,0,0,0-8.64,8.16,7.5,7.5,0,0,0,.31,2.12Z"
            style="fill: #e4e7ec"
          />
        </g>
        <g id="rocket">
          <path d="M62.79,9.4S50.09,18.47,50.09,49c0,9.33,4,21.81,4,21.81h8.66Z" style="fill: #fff" />
          <path d="M62.79,9.4S75.49,18.47,75.49,49c0,9.33-4,21.81-4,21.81H62.79Z" style="fill: #fff" />
          <path d="M75.3,42.08A89.73,89.73,0,0,1,87.38,59.8s-.81,14.77-5.24,23.36l-11-20.54Z" style="fill: #b3364a" />
          <path d="M50.3,42.08A89.73,89.73,0,0,0,38.22,59.8S39,74.57,43.46,83.16l11-20.54Z" style="fill: #b3364a" />
          <path
            d="M62.79,1.72S47,11.47,47,44.32c0,10,5,23.45,5,23.45H73.53s5-13.43,5-23.45C78.54,11.47,62.79,1.72,62.79,1.72Z"
            style="fill: #e64e67"
          />
          <circle cx="62.79" cy="28.12" r="7.43" style="fill: #fff" />
          <path
            d="M62.79,37.26a9.14,9.14,0,1,1,9.14-9.14A9.15,9.15,0,0,1,62.79,37.26Zm0-18.88a9.74,9.74,0,1,0,9.74,9.74A9.75,9.75,0,0,0,62.79,18.38Z"
            style="fill: #b3364a"
          />
          <g style="clip-path: url(#kabas-login__success-clip-path)">
            <polygon
              points="56.6 78.61 56.87 80.44 51.26 80.44 51.26 79.96 53.5 79.32 54.78 78.48 56.6 78.61"
              style="fill: #252f48"
            />
            <polygon
              points="65.21 78.61 64.95 80.44 68.58 80.44 68.58 79.96 67.03 78.48 65.21 78.61"
              style="fill: #252f48"
            />
            <path
              d="M59.58,51.07,57.32,65.52a1.6,1.6,0,0,0,1.37,1.83h0a1.61,1.61,0,0,0,1.77-1.22l3.39-14.3a2.16,2.16,0,0,0-1.59-2.57h0A2.18,2.18,0,0,0,59.58,51.07Z"
              style="fill: #212b4d"
            />
            <path
              d="M54.13,78.91l2.62.2,4-14.07a1.66,1.66,0,0,0-1.41-2h0a1.68,1.68,0,0,0-1.87,1.37Z"
              style="fill: #212b4d"
            />
            <path
              d="M64.82,50.46l.4,14.62a1.6,1.6,0,0,0,1.69,1.54h0a1.58,1.58,0,0,0,1.51-1.51l.73-14.68a2.16,2.16,0,0,0-2-2.24h0A2.16,2.16,0,0,0,64.82,50.46Z"
              style="fill: #212b4d"
            />
            <path
              d="M65.17,79.1l2.45-.1.85-15.06a1.66,1.66,0,0,0-1.73-1.74h0a1.66,1.66,0,0,0-1.6,1.67Z"
              style="fill: #212b4d"
            />
            <path d="M59.55,45.26v4.53a4,4,0,0,0,4,4h2.09a3.54,3.54,0,0,0,3.54-3.53l-.21-5Z" style="fill: #212b4d" />
            <path
              d="M56.47,37l3.14,13.34,9.59-1.45L68.73,35.1a2.82,2.82,0,0,0-3.18-2.47l-6.73.92A2.81,2.81,0,0,0,56.47,37Z"
              style="fill: #784896"
            />
            <path
              d="M59.71,52.78a4.22,4.22,0,0,0-.33-.8l-4.64-8.6a1.81,1.81,0,0,0-2.43-.75h0a1.8,1.8,0,0,0-.55,2.78l6.59,7.74-.15,1.46c0,.61-.12,1.47.49,1.42l.51.46c.65-.05,1.13-1.25.94-1.88Z"
              style="fill: #f3a470"
            />
            <path
              d="M62.09,35.79h0a1.9,1.9,0,0,1-2-1.84L60,28.08l3.8-.11.18,5.86A1.9,1.9,0,0,1,62.09,35.79Z"
              style="fill: #f3a470"
            />
            <path d="M64.56,23c.67,1.1.89,3.42,0,4.06s-2.37-3.66-2.37-3.66Z" style="fill: #93412f" />
            <path
              d="M60.35,23.23c2.29-1.42,3.53-.55,4.21,1.32s1.06,5.4-.19,5.93a3.29,3.29,0,0,1-4.17-1.9C59.56,26.67,59.05,24,60.35,23.23Z"
              style="fill: #f3a470"
            />
            <path
              d="M64.58,21.53c1.42,1.19,1.15,2.09-.09,2.35a16.37,16.37,0,0,1-2.75.27s-.52,4,.42,4.31,1-.82,2.79-.68c1.07.08,1.08,3.39-.09,3.64S61.56,31,61.22,30s.2-3.56-.42-4.21-1.06-.1-1,.44.29,3,.29,3a14.47,14.47,0,0,1-1.28-4.06c-.08-1.58.41-3,2.07-2.95s1.92.53,2.87.21S64.58,21.53,64.58,21.53Z"
              style="fill: #93412f"
            />
            <path
              d="M59.45,34.21l-.23-.12a1.79,1.79,0,0,0-2.42.63L51.61,43.3a1.79,1.79,0,0,0,.74,2.53h0a1.79,1.79,0,0,0,2.31-.64l5.42-8.45A1.8,1.8,0,0,0,59.45,34.21Z"
              style="fill: #f3a470"
            />
            <path
              d="M57.24,41.63l-3.67-2.24,2.92-4.74a2.15,2.15,0,0,1,2.95-.71h0a2.14,2.14,0,0,1,.71,3Z"
              style="fill: #784896"
            />
            <path
              d="M68.79,51.36a4,4,0,0,1,.17-.85l2.91-9.33A1.82,1.82,0,0,1,74.12,40h0a1.81,1.81,0,0,1,1.07,2.62l-5,8.86.43,1.4c.13.59.39,1.42-.21,1.49L70,54.9c-.65.07-1.34-1-1.28-1.67Z"
              style="fill: #f3a470"
            />
            <path
              d="M65.51,33.07l.19-.16a1.81,1.81,0,0,1,2.51.16l6.72,7.43a1.79,1.79,0,0,1-.24,2.63h0a1.78,1.78,0,0,1-2.39-.19l-6.93-7.27A1.79,1.79,0,0,1,65.51,33.07Z"
              style="fill: #f3a470"
            />
            <path
              d="M69.09,39.94,72.26,37,68.5,32.94a2.16,2.16,0,0,0-3-.13h0a2.13,2.13,0,0,0-.13,3Z"
              style="fill: #784896"
            />
          </g>
          <path
            d="M62.79,46.91a8.29,8.29,0,0,0-3.24,7.52l3.24,29.51L66,54.43A8.26,8.26,0,0,0,62.79,46.91Z"
            style="fill: #b3364a"
          />
          <path
            d="M75,30.47c.07-.36-.29-1.49-.19-2.24a8.36,8.36,0,0,0,.17-3c0-.13.36-.15.51.26s.17,1,.34,1.48,1.4-.66,1.75.09a3.77,3.77,0,0,1,.22,2.18c-.1.44-1.34.9-1.34,1.13C76.44,32,76.39,42,74.33,41a56.12,56.12,0,0,1-5.82-3.81l1.61-2.37s2.79,2,3.25,2.27S74.85,31.31,75,30.47Z"
            style="fill: #f7a68d"
          />
          <path
            d="M75.84,26.94c-.17-.52-.24-1.17-.34-1.48S75,25.07,75,25.2a8.36,8.36,0,0,1-.17,3c-.1.75.26,1.88.19,2.24C75,30.47,76.27,31.49,75.84,26.94Z"
            style="fill: url(#kabas-login__success-linear-gradient)"
          />
          <path
            d="M69.09,39.94l3.69-3.86L68.5,32.94a2.16,2.16,0,0,0-3-.13h0a2.13,2.13,0,0,0-.13,3Z"
            style="fill: #784896"
          />
        </g>
      </g>
    </svg>
    <span [innerHTML]="uiState[mode].success" data-cy="login-mode-message"></span>
  </div>

  <div class="kabas-form__errors" *ngIf="state === 'error'" @fadeIn @slideIn data-cy="login-error">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 132.84 89.09"
      class="kabas-login__failure-image"
    >
      <defs>
        <clipPath id="kabas-login__failure-clip-path">
          <rect width="132.84" height="89.09" style="fill: none" />
        </clipPath>
        <clipPath id="kabas-login__failure-clip-path-2">
          <path
            d="M43.88,52.91c-1.15.64-3.19-.09-4,.57,0,0,.53,16.07,2.56,21.56S45,96.86,44,98.7s-2.33,2.45-1.68,3.73,2.24,3.62,2.33,4.08-1.83,3.28-1.79,4.63,5.53,3.2,8.45,3.59,12.13.19,15.23-.49,5.68.78,11.15,1.26a13.06,13.06,0,0,0,3.9,0,9,9,0,0,0,3.51-1.26,5.63,5.63,0,0,0,1.46-5.74,28.62,28.62,0,0,1-1.87-10.87c0-6.77,1.17-24,1.72-32.24.19-2.75.82-10.69-1.5-12.19l-6-1.15c-1.69-.29-6-1.61-7.75-1.43-.93.1,3.09,3.65,2.84,3.79-3.47,1.91-10.3,12.78-20.63.65C51.71,53.13,46.1,51.69,43.88,52.91Z"
            style="fill: none"
          />
        </clipPath>
      </defs>
      <g>
        <g style="clip-path: url(#kabas-login__failure-clip-path)">
          <polygon
            points="105.06 59.19 107.92 54.22 107.83 48.62 110.57 47.1 114.17 49.63 121.36 49.63 129.24 44.65 132.84 47.35 123.07 55.18 113.31 60.57 110.34 64.03 105.06 59.19"
            style="fill: #f3a470"
          />
          <path
            d="M78.33,52.91c2.14,1.37,1.77.58,8.9.58,6,0,10.53,16.36,11.32,17.74a61.06,61.06,0,0,1,3,8.32c.33,1.36,2.11,2.61,2.81,4.4s.86,4.9,1.17,6.19S104,95.42,100,96.66s-7.69-1.21-9-2.89S82,76.49,79.14,69.11,73.34,49.72,78.33,52.91Z"
            style="fill: #3d4797"
          />
          <path
            d="M93.54,92.45c-4.4-4.42-2.56-6.18-1.47-10s1.79-4.44,2.3-7,7.28-12.42,7.12-14,2.94-5.79,5.59-4.1,4,2.67,4.58,3,.68,2-.13,5.14-4.84,22.11-6.74,26.9S95.5,94.42,93.54,92.45Z"
            style="fill: #3d4797"
          />
          <polygon
            points="26.61 59.19 23.75 54.22 23.84 48.62 21.11 47.1 17.52 49.63 10.34 49.63 2.47 44.65 -1.12 47.35 8.63 55.18 18.37 60.57 21.33 64.03 26.61 59.19"
            style="fill: #f3a470"
          />
          <path
            d="M45.61,52.85c-2.14,1.37,2.07,2.79-5,2.93-4.27.08-6.68,14.07-7.47,15.45a59.67,59.67,0,0,0-3,8.32c-.33,1.36-2.11,2.61-2.8,4.4s-.86,4.9-1.17,6.19,1.52,5.28,5.49,6.52,7.68-1.21,9-2.89S49.6,76.49,52.47,69.11,50.59,49.66,45.61,52.85Z"
            style="fill: #3d4797"
          />
          <path
            d="M38.1,92.45c4.39-4.42,2.56-6.18,1.47-10s-1.79-4.44-2.3-7-7.26-12.42-7.1-14-2.93-5.79-5.59-4.1-4,2.67-4.56,3-.68,2,.12,5.14S25,87.66,26.87,92.45,36.15,94.42,38.1,92.45Z"
            style="fill: #3d4797"
          />
          <path
            d="M59.93,53.54s-13.37-1.48-14.4,1.12c-.09.21-3.43,19.48-1,29,2.7,10.44,3.71,25.71,4.47,26.65,6,7.41,23.38,8.84,30.42,0l3.61-26.65,3.45-24.79c-1.27-2.71-17.12-3.19-17.12-3.19S66.49,57.44,59.93,53.54Z"
            style="fill: #f3a470"
          />
          <g style="clip-path: url(#kabas-login__failure-clip-path-2)">
            <path
              d="M44.16,52.63c-1.37.72-3.46-1.82-4.45-1,0,0,.7,17.93,2.73,23.42S45,95.75,44,97.59s-2.3,3.56-1.65,4.84,2.24,3.62,2.33,4.08-1.83,3.28-1.79,4.63,5.53,3.2,8.45,3.59,12.13.19,15.23-.49,10.65,1.54,11.33,2.12,1,1.75,3.1,1.47a18.28,18.28,0,0,1,4.39,0s2.29-5.88,1.2-9.37a28.62,28.62,0,0,1-1.87-10.87c.06-9,3.63-47.08,3.63-47.08L78.07,53.63s-12.26,3.26-28-.93C48.11,52.19,45.94,51.69,44.16,52.63Z"
              style="fill: #37388b"
            />
            <path
              d="M53.76,107.54c.09-.19.17-.36.23-.51A11.91,11.91,0,0,0,55.06,103c0-2.32-1.31-4.25-2.76-6a15.87,15.87,0,0,0,1.19-4.45,8.2,8.2,0,0,0-2.68-6.43,15.86,15.86,0,0,0,1.92-4.05,8.73,8.73,0,0,0-2.21-8.63A11.57,11.57,0,0,0,51.19,72c1.11-3.16-.22-5.89-2-8.41C50.27,61.6,51.5,59.85,52,59a1.16,1.16,0,0,0-2-1.17c-.59,1-1.48,2.42-2.37,3.94a5.14,5.14,0,0,1-1-4.09A1.16,1.16,0,0,0,44.41,57c-.65,2.47.37,4.71,1.76,6.72a1.36,1.36,0,0,0,.22.25c-1.94,3.89-3,8.14,1.1,10.17a14.76,14.76,0,0,0-1.25,2.7,10.37,10.37,0,0,0,1.64,9.63,12.58,12.58,0,0,0-1.18,3.25,8.62,8.62,0,0,0,3.06,8c-1.32,3.75-2.64,7.48.7,10a1.19,1.19,0,0,0,.38.4.43.43,0,0,0,0,.07,7.94,7.94,0,0,0-.7,2.57,3.74,3.74,0,0,0,.1,2c.23,1.17.88,2.94,1.91,3.56a1.1,1.1,0,0,0,1.63-.59,1.21,1.21,0,0,0,1.82-.6A7.4,7.4,0,0,0,53.76,107.54Zm-.4,6.9-.06,0c.13,0-.37-.74-.36-.73-.09-.17-.16-.33-.23-.5a1,1,0,0,0-.16-.8,1.08,1.08,0,0,1-.12-.24,5,5,0,0,1,0-1.22,7.71,7.71,0,0,1,.31-1A5.13,5.13,0,0,1,53.36,114.44ZM48,65.9c1.11,1.8,1.75,3.73.76,5.93,0,.1-.09.2-.14.3C46.34,71.14,46.76,68.56,48,65.9Zm.82,10.84a12.09,12.09,0,0,1,.6-1.26c2.52,2.6,1.25,5.64-.31,8.67A8.31,8.31,0,0,1,48.78,76.74Zm1.78,18.62a6.4,6.4,0,0,1-.9-7.17C51.59,90.21,51.33,92.73,50.56,95.36Zm.84,4.22a6.38,6.38,0,0,1,1.22,2.67,7,7,0,0,1-.69,3.75C50.22,104.58,50.59,102.13,51.4,99.58Z"
              style="fill: #3d4797"
            />
            <path
              d="M72.55,114.63a1.21,1.21,0,0,0,1.82.6,1.11,1.11,0,0,0,1.63.6c1-.63,1.69-2.4,1.91-3.57a3.64,3.64,0,0,0,.1-2,7.94,7.94,0,0,0-.7-2.57.43.43,0,0,0,0-.07,1.19,1.19,0,0,0,.38-.4c3.34-2.56,2-6.28.7-10a8.62,8.62,0,0,0,3.06-8,12.58,12.58,0,0,0-1.18-3.25,10.37,10.37,0,0,0,1.64-9.63,14.41,14.41,0,0,0-1.25-2.7c4.1-2,3-6.27,1.1-10.17a1.07,1.07,0,0,0,.22-.25c1.39-2,2.41-4.25,1.76-6.72a1.16,1.16,0,0,0-2.24.62,5.14,5.14,0,0,1-1,4.09c-.88-1.52-1.78-2.9-2.37-3.94a1.16,1.16,0,0,0-2,1.17C76.62,59.26,77.84,61,79,63c-1.81,2.52-3.14,5.25-2,8.41a13.14,13.14,0,0,0,.67,1.48,8.71,8.71,0,0,0-2.2,8.63,15.51,15.51,0,0,0,1.91,4A8.2,8.2,0,0,0,74.63,92a15.81,15.81,0,0,0,1.18,4.45c-1.45,1.7-2.77,3.63-2.76,6a11.65,11.65,0,0,0,1.08,4.08,4.9,4.9,0,0,0,.22.51A7.42,7.42,0,0,0,72.55,114.63Zm2.85-5.35a7.71,7.71,0,0,1,.31,1,4.54,4.54,0,0,1,0,1.22.9.9,0,0,1-.12.24,1,1,0,0,0-.16.8,4.52,4.52,0,0,1-.23.5s-.49.76-.36.73l-.06,0A5.16,5.16,0,0,1,75.4,109.28Zm4.13-37.74c0-.1-.1-.2-.14-.3-1-2.2-.35-4.13.77-5.93C81.35,68,81.78,70.56,79.53,71.54Zm-.49,12c-1.55-3-2.83-6.07-.31-8.66a11,11,0,0,1,.6,1.25A8.28,8.28,0,0,1,79,83.56Zm-.59,4a6.39,6.39,0,0,1-.9,7.16C76.78,92.14,76.53,89.62,78.45,87.61Zm-2.27,17.8a7.08,7.08,0,0,1-.69-3.75A6.44,6.44,0,0,1,76.71,99C77.52,101.54,77.89,104,76.18,105.41Z"
              style="fill: #3d4797"
            />
            <path
              d="M62.16,114.84a1.21,1.21,0,0,0,1.87.41,1.12,1.12,0,0,0,1.69.42c1-.73,1.42-2.57,1.53-3.75a3.74,3.74,0,0,0-.12-2,7.86,7.86,0,0,0-1-2.49l0-.06a1.07,1.07,0,0,0,.34-.44c3-2.9,1.34-6.46-.37-10a8.64,8.64,0,0,0,2.2-8.32,13.11,13.11,0,0,0-1.52-3.11,10.39,10.39,0,0,0,.61-9.75,14.85,14.85,0,0,0-1.53-2.55c3.86-2.45,2.36-6.56,0-10.23a1.35,1.35,0,0,0,.2-.27c1.17-2.14,2-4.48,1-6.87a1.17,1.17,0,0,0-2.17.85,5.14,5.14,0,0,1-.52,4.17c-1.05-1.42-2.08-2.69-2.78-3.67a1.17,1.17,0,0,0-1.88,1.38c.6.83,2,2.44,3.32,4.26-1.54,2.7-2.57,5.55-1.13,8.58a12.2,12.2,0,0,0,.82,1.4,8.75,8.75,0,0,0-1.28,8.82,15.55,15.55,0,0,0,2.34,3.82,8.22,8.22,0,0,0-2,6.68,16.11,16.11,0,0,0,1.66,4.3c-1.27,1.85-2.38,3.9-2.12,6.2a11.78,11.78,0,0,0,1.5,4c.07.14.17.3.27.48A7.43,7.43,0,0,0,62.16,114.84Zm2.27-5.62a7.74,7.74,0,0,1,.42,1,4.54,4.54,0,0,1,.1,1.22,1.06,1.06,0,0,1-.09.25,1,1,0,0,0-.08.81,5.25,5.25,0,0,1-.17.52s-.41.81-.29.77l0,0A5.16,5.16,0,0,1,64.43,109.22Zm.11-38c-.06-.1-.12-.19-.17-.29-1.22-2.08-.79-4.07.13-6C66,67.52,66.67,70,64.54,71.26Zm.78,12c-1.86-2.85-3.45-5.74-1.22-8.58a12.9,12.9,0,0,1,.73,1.18A8.25,8.25,0,0,1,65.32,83.26Zm-.16,4.08A6.4,6.4,0,0,1,65,94.56C64,92,63.47,89.55,65.16,87.34Zm-.36,18a7,7,0,0,1-1.09-3.66,6.43,6.43,0,0,1,.94-2.78C65.72,101.3,66.35,103.69,64.8,105.29Z"
              style="fill: #3d4797"
            />
          </g>
          <path d="M57.11,45.63s1.2,7.69,1.19,8.47S65,65.85,69.5,56.62l1-11.56Z" style="fill: #e79d6b" />
          <path
            d="M84.76,97.4C89.11,95.82,89,91.08,89,91.08c.81-37.27-10.16-41.54-10.16-41.54H74.43c-1.77,0-1.83,2-1.83,2l2.48.22s10.71,13.16,9.59,39.6"
            style="fill: #229fa3"
          />
          <path
            d="M44.25,97.47c-4.34-1.61-4-5.07-4-5.07-.8-38.07,9.45-42.31,9.45-42.31h4.34c1.77,0,1.84,3.38,1.84,3.38l-3-.25s-9.46,12.44-8.34,39.45"
            style="fill: #229fa3"
          />
          <path
            d="M75,29.14c-17.72-5.06-23.56.33-23.89.66s-1,13.26-1,14.82,1.4,9.17,2.25,10.42a106.34,106.34,0,0,0,8.68,8.14c.46.2,3.62.29,4.22-.11s8.24-7.07,8.85-8.89A80.53,80.53,0,0,0,75.76,42.6C75.72,39.16,75,29.14,75,29.14Z"
            style="fill: #f3a470"
          />
          <path
            d="M60.46,20.19s-5.15.3-5.13-4.26,2.9-4.87,4-7S64.86,4,70.06,7.12s5.18,6.46,3.44,9.14S65.56,23.82,60.46,20.19Z"
            style="fill: #e73d5a"
          />
          <path
            d="M79.42,27.07C76.53,17.82,69.08,14.93,61.71,15s-15,8.68-17.44,18.71c-2,8.45.09,10.65,2.15,13.6a15.39,15.39,0,0,0,4.84,4.2c.58-2-1.87-9.31-.67-11,.36-.51,0-.18.43-.39,0-.13,0-.24,0-.34a21.09,21.09,0,0,1,6.29-.91c3.9-.05,4.87,0,4.87,0l0-8.54a52.43,52.43,0,0,1,.9,8.5s4.77,0,6.23,0c0,0,.09-3.55-.08-5.91.29,1.83.68,4.54.73,5.91a22,22,0,0,1,4.2,1c.07.05.1.2.12.4,0,0,1.07.72,1.09.77,1,3.49-1.23,7.42-.78,10.88.34-.38,3.73-2.36,5.32-4.44C82.77,43.73,83.51,40.16,79.42,27.07Z"
            style="fill: #e73d5a"
          />
          <path
            d="M76.73,29.77c-2.89-9.24-7.65-14.84-15-14.8S50.93,23.13,48.77,33.22s-3.92,10.12-2,13.14c.09.14.16.28.24.42.65-2.15,2-1.68,3-3.12.4-.56.42-3.31.94-3.51a3.25,3.25,0,0,1,0-.43,21.09,21.09,0,0,1,6.29-.91c3.9-.05,4.87,0,4.87,0l0-8.54a52.43,52.43,0,0,1,.9,8.5s4.77,0,6.23,0c0,0,.09-3.55-.08-5.91.29,1.83.68,4.54.73,5.91a22,22,0,0,1,4.2,1l.16.23a9.56,9.56,0,0,1,4.2,5.81l1.22,1.4C81.33,44.49,80.82,42.87,76.73,29.77Z"
            style="fill: #e73d5a"
          />
          <path
            d="M75.24,42.86s1.42-2.14,2.08-1.47,2.72,3.39-2.7,10.49S75.24,42.86,75.24,42.86Z"
            style="fill: #f3a470"
          />
          <path
            d="M50.86,42.75s-1.47-2.16-2.17-1.5-2.89,3.37,2.73,10.52S50.86,42.75,50.86,42.75Z"
            style="fill: #f3a470"
          />
          <path
            id="Tear"
            d="M59.21,51.77a1.21,1.21,0,1,1-2.42,0,5.24,5.24,0,0,1,1.21-3A5.31,5.31,0,0,1,59.21,51.77Z"
            style="fill: #fad8c3"
          />
        </g>
      </g>
    </svg>
    <span [innerHTML]="uiState[mode].error"></span>
  </div>

  <div class="kabas-form__buttons">
    <button
      type="submit"
      class="kabas-button kabas-form__submit-button"
      [ngClass]="'kabas-form__submit-button--' + mode"
      [class.kabas-button--disabled]="
        state !== 'processing' && (form.invalid || state === 'success' || state === 'error')
      "
      [class.kabas-button--pending]="state === 'processing'"
      [class.kabas-button--error]="state === 'error'"
      [class.kabas-button--success]="state === 'success'"
      [disabled]="state !== 'processing' && (form.invalid || state === 'success' || state === 'error')"
      data-cy="btn-login-form-sign-in"
      ><div class="kabas-button__label">{{ uiState[mode].cta }}</div></button
    >
    <button
      class="kabas-button kabas-button--inline"
      (click)="setMode(mode === 'login' ? 'forgot-password' : 'login')"
      data-cy="btn-login-form-login-mode"
      >{{ uiState[mode].toggleMode }}</button
    >
  </div>

  <div class="kabas-login-teacher__hint kabas-description" data-cy="login-no-access">
    <p
      >Vraag jouw inloggegevens aan de schoolbeheerder.
      <br />
      Heeft jouw school nog geen toegang?
      <br />
      Mail de schoolgegevens naar
      <a href="mailto:kabas@diekeure.be" data-cy="link-no-access-mail-to">kabas&#64;diekeure.be</a></p
    >
  </div>
</form>
