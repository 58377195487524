import { ViewportScroller } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { WindowScrollService } from '@campus/browser';
import { ComponentsModule } from '@campus/components';
import { CookiesModule } from '@campus/cookies';
import { EnvironmentModule } from '@campus/environment';
import { PublicPortalModule } from '@campus/public-portal';

import { SvgModule } from '@campus/svg';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { delay, filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppEffectsModule } from './app-effects.module';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';
import { AppTokenModule } from './app-token.module';
import { AppComponent } from './app.component';
import {
  CardActionsDirective,
  CardComponent,
  CardDescriptionDirective,
  CardImageDirective,
  CardTitleDirective,
} from './components/card/card.component';
import { LoginFormComponent } from './components/login/login-form.component';
import { LoginStudentComponent } from './components/login/login-student/login-student.component';
import { LoginTeacherComponent } from './components/login/login-teacher/login-teacher.component';
import { LoginSvgComponent } from './components/login/svg/login-svg.component';
import { ModalComponent, ModalContentDirective } from './components/modal/modal.component';
import { PersistedUserCardComponent } from './components/persisted-user-card/persisted-user-card.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LegalsHeroComponent } from './pages/legals-page/components/legals-hero/legals-hero.component';
import { LegalsPagePrivacyComponent } from './pages/legals-page/components/legals-page-privacy/legals-page-privacy.component';
import { LegalsPageSalesConditionsComponent } from './pages/legals-page/components/legals-page-sales-conditions/legals-page-sales-conditions.component';
import { LegalsPageTermsOfUseComponent } from './pages/legals-page/components/legals-page-terms-of-use/legals-page-terms-of-use.component';
import { LegalsPageContainerComponent } from './pages/legals-page/legals-page-container.component';
import { PersistedUsersService } from './services/persisted-users.service';

const disableAnimations =
  !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LoginTeacherComponent,
    CardComponent,
    CardImageDirective,
    CardTitleDirective,
    CardDescriptionDirective,
    CardActionsDirective,
    LoginStudentComponent,
    ModalComponent,
    ModalContentDirective,
    LoginFormComponent,
    LoginSvgComponent,
    // LoginFormDirective,
    PersistedUserCardComponent,
    LegalsHeroComponent,
    LegalsPageContainerComponent,
    LegalsPagePrivacyComponent,
    LegalsPageSalesConditionsComponent,
    LegalsPageTermsOfUseComponent,
  ],
  imports: [
    environment.envModules || [],
    MatMenuModule,
    BrowserModule,
    AppRoutingModule,
    SvgModule,
    EnvironmentModule.forRoot({
      environmentSvgMapping: environment.svgMapping,
      environmentIconMapping: environment.iconMapping,
      environmentRecaptcha: environment.recaptcha,
      environmentLogin: environment.login,
      environmentApi: environment.api,
      environmentAssets: environment.assets,
      environmentGoogleAnalytics: environment.googleAnalytics,
      environmentLogout: environment.afterLogout,
      environmentWebsite: environment.website,
    }),
    ReactiveFormsModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    CookiesModule,
    HttpClientModule,
    PublicPortalModule.forRoot({ apiBaseUrl: environment.api.APIBase }),
    ComponentsModule,
    AppTokenModule,
    AppEffectsModule,
    AppStoreModule,
    environment.enableDevTools ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [WindowScrollService, PersistedUsersService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        delay(1)
      )
      .subscribe((e) => {
        if (e.position) {
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
