import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PersistedUsersService } from '../services/persisted-users.service';
import { PersistedUserCredentialsInterface } from '../services/persisted-users.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PersistedUsersFacade {
  private persistedUsersService = inject(PersistedUsersService);
  public persistedUser$ = new BehaviorSubject<PersistedUserCredentialsInterface[]>([]);

  constructor() {}

  public getAll(): BehaviorSubject<PersistedUserCredentialsInterface[]> {
    this.persistedUser$.next(Array.from(this.persistedUsersService.getAll().values()));

    return this.persistedUser$;
  }

  public remove(userId: number): void {
    this.persistedUsersService.remove(userId);
    this.persistedUser$.next(Array.from(this.persistedUsersService.getAll().values()));
  }

  public save(userId: number, credentials: PersistedUserCredentialsInterface): void {
    this.persistedUsersService.save(userId, credentials);
    this.persistedUser$.next(Array.from(this.persistedUsersService.getAll().values()));
  }
}