import { Injectable, inject } from '@angular/core';
import { ClassGroupInterface, SchoolApi, SchoolInterface } from '@campus/public-portal';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClassGroupService {
  public classGroups$ = new Observable<ClassGroupInterface[]>();
  private schoolApi = inject(SchoolApi);

  constructor() {}

  getForSchoolCode(schoolCode: number): Observable<ClassGroupInterface[]> {
    this.classGroups$ = this.schoolApi.findBySchoolCode(schoolCode).pipe(
      map((school: SchoolInterface) => school.classGroups.sort(this.sortByName)),
      catchError((err) => of([]))
    );

    return this.classGroups$;
  }

  private sortByName(classGroupA: ClassGroupInterface, classGroupB: ClassGroupInterface) {
    return classGroupA.name.localeCompare(classGroupB.name, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }
}
