import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from '@campus/browser';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'kabas-legals-page-container',
  templateUrl: './legals-page-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalsPageContainerComponent implements OnInit {
  // This is a temporary fix for the padding-top of the page, until we have a theme file
  @HostBinding('style.padding-top') paddingTop = '80px';
  @HostBinding('style.display') display = 'block';

  public slug$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    @Inject(WINDOW)
    private window: Window
  ) {}

  ngOnInit() {
    this.slug$ = this.getSlug();
  }

  getSlug(): Observable<string> {
    return this.route.params.pipe(
      map((params) => params.slug),
      tap(() => this.window.scrollTo({ top: 0, behavior: 'smooth' }))
    );
  }
}
