<div class="[ stack ]" *ngIf="!showForm || qrScannerVisible">
  <div class="[ title-medium on-primary center-items ]">Aanmelden met:</div>
  <div class="[ cluster center-items ]">
    <div>
      <kabas-persisted-user-card [isAddNew]="true" (userCardClicked)="makeFormVisible()"></kabas-persisted-user-card>
      <kabas-persisted-user-card
        *ngIf="showQRLoginButton && !qrScannerVisible"
        [isQRLogin]="true"
        (userCardClicked)="showQrScanner.emit(true)"
      ></kabas-persisted-user-card>
      <kabas-persisted-user-card
        *ngIf="showLearningIdLoginButton"
        [isLeerIDLogin]="true"
        (userCardClicked)="ssoLogin('leerId')"
        [@wiggleLarge]="state === 'person_with_leerid_not_found'"
      ></kabas-persisted-user-card>
    </div>
  </div>
  <ng-container *ngIf="persistedUsers?.length">
    <div class="[ flex gap-l ]">
      <span class="[ flex-1 ] [ border-be border-on-primary ] [ transform -translate-y-1/2 ]"></span>
      <span class="[ title-small on-primary ]">of</span>
      <span class="[ flex-1 ] [ border-be border-on-primary ] [ transform -translate-y-1/2 ]"></span>
    </div>
    <div class="[ title-medium on-primary center-items ]">Snel aanmelden:</div>
    <div class="[ auto-grid auto-grid-min-column-2xl ]">
      <kabas-persisted-user-card
        @popOut
        *ngFor="let persistedUser of persistedUsers"
        [isQuickLogin]="true"
        [person]="persistedUser"
        (quickLogin)="quickLogin($event)"
        (forgetAboutMe)="forgetPerson.emit($event)"
      ></kabas-persisted-user-card>
    </div>
  </ng-container>
</div>

<div class="[ stack ]" *ngIf="showForm && !qrScannerVisible">
  <form
    class="kabas-form kabas-login-student__form [ auto-grid auto-grid-min-3xs auto-grid-space-3xs ]"
    [formGroup]="form"
    (ngSubmit)="submit()"
    data-cy="form-student-login"
  >
    <ng-container *ngIf="!quickLoginPerson; else quickLoginDescription">
      <div class="[ title-medium on-primary center-items col-span-full ]">Aanmelden met een code:</div>

      <fieldset
        class="kabas-form-field"
        [class.kabas-form-field--invalid]="
          form.get('schoolCode').touched && form.get('schoolCode').hasError('required')
        "
      >
        <label class="kabas-form-field__label" for="schoolCode" data-cy="label-login-school-code">Schoolcode</label>
        <input
          id="schoolCode"
          required
          formControlName="schoolCode"
          type="number"
          class="kabas-form-field__control"
          data-cy="input-login-school-code"
        />
        <div
          *ngIf="form.get('schoolCode').touched && form.get('schoolCode').hasError('required')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat je geen schoolcode invulde.</div
        >
      </fieldset>
      <fieldset
        class="kabas-form-field"
        [class.kabas-form-field--invalid]="
          form.get('classGroupId').touched && form.get('classGroupId').hasError('required')
        "
        [disabled]="!form.get('schoolCode').value || (classGroups && !classGroups.length)"
      >
        <label class="kabas-form-field__label" for="classGroup" data-cy="label-login-class-group">Klas</label>
        <div class="kabas-form-field__control kabas-form-field__control--select">
          <select
            class="kabas-form-field-__control--select-input"
            id="classGroup"
            formControlName="classGroupId"
            required
            data-cy="select-login-class-group"
          >
            <option [ngValue]="null">{{ uiState.classGroups[classGroupState] }}</option>
            <option
              *ngFor="let classGroup of classGroups; trackBy: trackById"
              [ngValue]="classGroup.id"
              [selected]="classGroup.id === form.get('classGroupId').value"
              data-cy="option-login-class-group"
              >{{ classGroup.name }}</option
            >
          </select>
          <span class="focus"></span>
        </div>
        <div
          *ngIf="form.get('classGroupId').touched && form.get('classGroupId').hasError('required')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat je geen klas selecteerde.</div
        >
      </fieldset>
      <fieldset
        class="kabas-form-field"
        [class.kabas-form-field--invalid]="
          form.get('classNumber').touched && form.get('classNumber').hasError('required')
        "
        [@wiggle]="isErrorState"
        [disabled]="!form.get('schoolCode').value || (classGroups && !classGroups.length)"
      >
        <label class="kabas-form-field__label" for="classNumber" data-cy="label-login-class-number">Klasnummer</label>
        <input
          class="kabas-form-field__control"
          id="classNumber"
          formControlName="classNumber"
          type="number"
          data-cy="input-login-class-number"
        />
        <div
          *ngIf="form.get('classNumber').touched && form.get('classNumber').hasError('required')"
          class="kabas-form-field__error"
          @fadeIn
          @slideIn
          data-cy="error-login"
          >Het lijkt erop dat je geen klasnummer invulde.</div
        >
      </fieldset>
    </ng-container>

    <ng-template #quickLoginDescription>
      <ng-container class="kabas-login-student__description kabas-description [ col-span-full ]">
        <h5 class="[ col-span-full on-primary ]">Hey {{ quickLoginPerson.firstName }}!</h5>
        <p class="[ col-span-full on-primary ] [ label-medium ]"
          >Voer je nog even je wachtwoord in? Zo zijn we zeker dat jij het bent.</p
        >
        <table
          class="[ col-span-full m-b-xs m-i-auto w-fit ] [ on-primary border-b border-b-on-primary border-opacity-medium ]"
        >
          <tr>
            <td class="[ w-0 p-i-xs p-b-3xs ] [ label-small ]">Schoolcode</td>
            <td class="[ p-i-xs label-large ]">{{ form.get('schoolCode').value }}</td>
          </tr>

          <tr>
            <td class="[ w-0 p-i-xs p-b-3xs ] [ label-small ]">Klas</td>
            <td class="[ p-i-xs label-large ]">
              <ng-container *ngIf="classGroupDict && classGroupDict[form.get('classGroupId').value]">
                {{ classGroupDict[form.get('classGroupId').value]?.name }}
              </ng-container>
            </td>
          </tr>

          <tr>
            <td class="[ w-0 p-i-xs p-b-3xs ] [ label-small ]">Klasnummer</td>
            <td class="[ p-i-xs label-large ]">{{ form.get('classNumber').value }}</td>
          </tr>
        </table>
      </ng-container>
    </ng-template>

    <fieldset
      class="kabas-form-field"
      [class.col-span-full]="!!quickLoginPerson"
      [class.kabas-form-field--invalid]="form.get('childCode').touched && form.get('childCode').hasError('required')"
      [@wiggle]="isErrorState"
      [disabled]="!form.get('schoolCode').value || (classGroups && !classGroups.length)"
    >
      <label class="kabas-form-field__label" for="code" data-cy="label-login-child-code">Wachtwoord</label>
      <input
        class="kabas-form-field__control"
        id="code"
        required
        formControlName="childCode"
        [type]="visibleCode ? 'text' : 'password'"
        data-cy="input-login-child-code"
      />

      <svg
        class="kabas-form-field__icon kabas-form-field__icon--suffix [ pointer-event-auto ]"
        (click)="visibleCode = !visibleCode"
      >
        <use [attr.xlink:href]="visibleCode ? '#visibility' : '#visibility-off'" />
      </svg>

      <div
        *ngIf="form.get('childCode').touched && form.get('childCode').hasError('required')"
        class="kabas-form-field__error"
        @fadeIn
        @slideIn
        >Het lijkt erop dat je geen wachtwoord invulde.</div
      >
    </fieldset>

    <div class="kabas-form__success" *ngIf="state === 'success'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 132.84 89.09"
        class="kabas-login__success-image"
      >
        <defs>
          <clipPath id="kabas-login__success-clip-path-2">
            <circle cx="63.67" cy="28.56" r="7.55" style="fill: none" />
          </clipPath>
          <linearGradient
            id="kabas-login__success-linear-gradient"
            x1="4501.05"
            y1="306.37"
            x2="4506.08"
            y2="304.93"
            gradientTransform="matrix(0.97, -0.33, 0.33, 0.95, -4405.53, 1221.39)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#f7a68d" />
            <stop offset="1" stop-color="#eb8b78" />
          </linearGradient>
        </defs>
        <g>
          <g id="fire">
            <polygon points="81.59 88.2 44.01 88.2 53.92 39.19 71.68 39.19 81.59 88.2" style="fill: #ffd480" />
            <polygon points="71.13 88.2 54.47 88.2 58.57 39.19 67.03 39.19 71.13 88.2" style="fill: #ffb366" />
          </g>
          <g id="clouds">
            <path
              d="M5,88.94H127.81a10,10,0,0,0,5-8.59c0-5.62-4.82-10.17-10.77-10.17a11,11,0,0,0-8.46,3.88,10.77,10.77,0,0,0-10.09-6.62,11.65,11.65,0,0,0-2.79.35,10.87,10.87,0,0,0-9.41-5.23,11.13,11.13,0,0,0-5.87,1.64c0-.14,0-.28,0-.42A9.21,9.21,0,0,0,76,54.85a9.81,9.81,0,0,0-5.15,1.44,9.78,9.78,0,0,0-9.72-7.55,9.65,9.65,0,0,0-9.91,9.35c0,.26,0,.5,0,.75A9.75,9.75,0,0,0,45,56.56a9.31,9.31,0,0,0-9.37,7.71,11.09,11.09,0,0,0-7.14-2.55c-5.95,0-10.77,4.55-10.77,10.16,0,.24,0,.47,0,.7a11.16,11.16,0,0,0-6.94-2.4C4.82,70.18,0,74.73,0,80.35a10,10,0,0,0,5,8.59Z"
              style="fill: #f1f1f1"
            />
            <path
              d="M109.33,88.94a8.14,8.14,0,0,0-8.59-9,8.89,8.89,0,0,0-6,2.33c0-.15,0-.31,0-.46A7,7,0,0,0,87.5,75a7.38,7.38,0,0,0-5.07,2,7.78,7.78,0,0,0-7.51-5.33,8.12,8.12,0,0,0-4.44,1.3,8.56,8.56,0,0,0-8.35-6.1,8.43,8.43,0,0,0-8.63,7.88,7.43,7.43,0,0,0-4.63-1.59,7,7,0,0,0-7.21,6.81c0,.23,0,.45,0,.67a8.87,8.87,0,0,0-5.56-1.92,8.41,8.41,0,0,0-8.64,8.16,7.5,7.5,0,0,0,.31,2.12Z"
              style="fill: #e4e7ec"
            />
          </g>
          <g id="rocket">
            <path d="M63.67,9.54s-12.9,9.22-12.9,40.26c0,9.47,4.1,22.16,4.1,22.16h8.8Z" style="fill: #fff" />
            <path d="M63.67,9.54s12.9,9.22,12.9,40.26c0,9.47-4.1,22.16-4.1,22.16h-8.8Z" style="fill: #fff" />
            <path d="M76.38,42.75a90.93,90.93,0,0,1,12.28,18s-.82,15-5.32,23.73L72.15,63.61Z" style="fill: #b3364a" />
            <path d="M51,42.75a90.63,90.63,0,0,0-12.27,18s.82,15,5.32,23.73L55.21,63.61Z" style="fill: #b3364a" />
            <path
              d="M63.67,1.73s-16,9.92-16,43.29c0,10.18,5.09,23.82,5.09,23.82H74.58S79.67,55.2,79.67,45C79.67,11.65,63.67,1.73,63.67,1.73Z"
              style="fill: #e64e67"
            />
            <circle cx="63.67" cy="28.56" r="7.55" style="fill: #fff" />
            <path
              d="M63.67,37.85A9.29,9.29,0,1,1,73,28.56,9.3,9.3,0,0,1,63.67,37.85Zm0-19.19a9.9,9.9,0,1,0,9.9,9.9A9.91,9.91,0,0,0,63.67,18.66Z"
              style="fill: #b3364a"
            />
            <g style="clip-path: url(#kabas-login__success-clip-path-2)">
              <path
                d="M65.8,32.88s2.12-.24,2.38.43.57,3.83.77,4,1,0,1.32.25.47,5.82.12,6.13-5.38.67-5.81.35.06-10.48.27-10.83S65.8,32.88,65.8,32.88Z"
                style="fill: #cb3b45"
              />
              <path
                d="M62.1,33s-2.15.07-2.31.77,0,3.87-.22,4.07-1,.17-1.27.43.34,5.83.73,6.09,5.46-.1,5.85-.47S63.35,33.53,63.1,33.21,62.1,33,62.1,33Z"
                style="fill: #cb3b45"
              />
              <path
                d="M61.19,28.48s-.75-.3-.93-.17a3.79,3.79,0,0,0,.07,1.91c.15.23,1.06.31,1.06.31A6.74,6.74,0,0,1,61.19,28.48Z"
                style="fill: #d89264"
              />
              <path
                d="M66.73,28.05s.79-.25,1-.08a4.55,4.55,0,0,1-.27,2.29c-.2.25-1.29.26-1.29.26A6.83,6.83,0,0,0,66.73,28.05Z"
                style="fill: #d89264"
              />
              <path d="M62.87,31.89S62.79,34,62.79,34A3.74,3.74,0,0,0,66,34l-.15-2.25Z" style="fill: #d89264" />
              <path
                d="M65.45,25.07s-2,0-3.35.1-1.57.63-1.58.73.16,4,.3,4.51a11.49,11.49,0,0,0,1.77,2.38,6.08,6.08,0,0,0,1.81,0,12.41,12.41,0,0,0,2.49-2.18,16.53,16.53,0,0,0,.28-3A7.85,7.85,0,0,0,65.45,25.07Z"
                style="fill: #f2a370"
              />
              <path
                d="M59.45,35.1C57.82,36.53,58,42.54,58,43s.63,1.43,1.67.42,1.75-7.15,1.79-7.37S60.68,34,59.45,35.1Z"
                style="fill: #f2a370"
              />
              <path
                d="M69.62,35.17C71.25,36.6,71,42.61,71.12,43s-.63,1.43-1.67.42-1.75-7.15-1.79-7.37S68.39,34.09,69.62,35.17Z"
                style="fill: #d89264"
              />
              <path
                d="M58,42.45a14.51,14.51,0,0,0-.47,3.72c.06,1.79.19,2.39.23,3.07s.89,1,1.27.5,1.17-6.57.65-7.25S58.5,41.64,58,42.45Z"
                style="fill: #f2a370"
              />
              <path
                d="M60.39,49s1.14,8.53,1.3,9.39c.8,4.24,1.06,2.74,2.54.53.16-.24.37-8.53.29-9.48S60.68,46.19,60.39,49Z"
                style="fill: #f9c9b3"
              />
              <path
                d="M61.75,58.52A19,19,0,0,0,62,61.86c.26,1.31.73,3.75.84,4s1.37.62,1.39-.13c.06-2.44.17-9.89-.13-11S62,57.31,61.75,58.52Z"
                style="fill: #229fa2"
              />
              <path
                d="M62.62,65.75a8.31,8.31,0,0,1-2.56,1.92c-1.09.35-1.33.48-1.31.8s1.15.32,2.19.32a11.51,11.51,0,0,0,2.94-.85c.72-.19.69-.13.64-.67a6.59,6.59,0,0,0-.31-1.57C64.06,65.38,62.94,65.14,62.62,65.75Z"
                style="fill: #427673"
              />
              <path
                d="M68.82,48.86s1,8.55,1,9.42S68.56,62,67.47,61c-.2-.2-2.48-9.85-2.6-10.8S68,46.21,68.82,48.86Z"
                style="fill: #f9c9b3"
              />
              <path
                d="M69.83,58.46s.47,3,.48,4.36.4,2.77.34,3-1,.73-1.41.11a56.44,56.44,0,0,1-1.73-8.24C67.58,56.58,69.34,57.33,69.83,58.46Z"
                style="fill: #229fa2"
              />
              <path
                d="M69,65.83a21.47,21.47,0,0,0,.22,2.82,1.89,1.89,0,0,0,1.78,1c.94,0,1.32-.43,1.42-.88s-1.6-1.71-1.81-3S69,65.19,69,65.83Z"
                style="fill: #427673"
              />
              <path
                d="M62.79,33.75a14.13,14.13,0,0,0-2.9,1.19c-.17.31,1.12,10.5,1.16,10.87s6.34.11,6.72,0S69.18,35.19,69.16,35A24.67,24.67,0,0,0,66,33.75S64.07,34.59,62.79,33.75Z"
                style="fill: #f9c9b3"
              />
              <path
                d="M67,23.58s-.88,1.9-1.26,2.06-4,.51-4.52.57-1.17.42-1.62-.35a9.16,9.16,0,0,1-.75-2,2.54,2.54,0,0,0,1.67-.21c1-.43,3.39-1.51,4.65-1.2A3,3,0,0,1,67,23.58Z"
                style="fill: #61342b"
              />
              <path
                d="M67,23.58a4,4,0,0,1,.65,2.21,12.08,12.08,0,0,1-.54,3s-.23-.14-.22-1a5.67,5.67,0,0,0-1.06-2.26S66.69,23.51,67,23.58Z"
                style="fill: #87493c"
              />
              <path
                d="M59.28,50a8.12,8.12,0,0,0,.36,1.7c.18.32,0,.34-.15.41s-.82-1.15-.82-1.15Z"
                style="fill: #f2a370"
              />
              <path
                d="M57.44,50a10.24,10.24,0,0,0-.35,2,7.54,7.54,0,0,0,1.42,1.91.31.31,0,0,0,.38-.11A11.36,11.36,0,0,0,58,52.35,20.3,20.3,0,0,1,59.28,50a2.6,2.6,0,0,0-1-.8C58,49.09,57.52,49.82,57.44,50Z"
                style="fill: #f2a370"
              />
              <path
                d="M61,45c0,.07-.71,3.73-.5,4.6s7.91.37,8.29,0S68.18,45.12,68,45,61,45,61,45Z"
                style="fill: #f9c9b3"
              />
              <path
                d="M69.12,34.55s.65,0,1.13.94,1.31,3.31,1.31,3.31l-2.72.83S68,34.41,69.12,34.55Z"
                style="fill: #229fa2"
              />
              <path d="M69,45.92l-9,.39L61.6,63.13h2.89L65,53.84l3,9.29H70.8Z" style="fill: #173152" />
              <path
                d="M63.56,47.07a42,42,0,0,0,.34,4.72,5.15,5.15,0,0,0,1.14,2l.18-.35a4.37,4.37,0,0,1-.84-2,30.62,30.62,0,0,1-.12-4.32Z"
                style="fill: #1e4468"
              />
              <path
                d="M59.63,34.68s-.27-.09-.75.9-1.68,3.34-1.68,3.34l2.72.84S60.77,34.54,59.63,34.68Z"
                style="fill: #229fa2"
              />
              <path
                d="M62.73,33.65l-3.1,1-.55,12s1.25,1.15,5.52,1a15,15,0,0,0,5.14-1.15l-.62-12L66,33.65A3.62,3.62,0,0,1,62.73,33.65Z"
                style="fill: #229fa2"
              />
              <path
                d="M61.06,36.32a1.07,1.07,0,0,0,1.34-.14.79.79,0,0,0,.18-.32,1.1,1.1,0,0,1-1-.07.77.77,0,0,1-.35-.84,1.1,1.1,0,0,0-.31.22A.75.75,0,0,0,61.06,36.32Z"
                style="fill: #41b8ba"
              />
              <path
                d="M66.23,42a.9.9,0,0,0,.17-.32,1.08,1.08,0,0,1-1-.07.77.77,0,0,1-.35-.84,1,1,0,0,0-.31.22.74.74,0,0,0,.17,1.15A1.08,1.08,0,0,0,66.23,42Z"
                style="fill: #41b8ba"
              />
              <path
                d="M60.3,46a.76.76,0,0,1-.35-.84,1,1,0,0,0-.31.23.73.73,0,0,0,.17,1.14,1.07,1.07,0,0,0,1.35-.14.71.71,0,0,0,.17-.32A1.08,1.08,0,0,1,60.3,46Z"
                style="fill: #41b8ba"
              />
              <path
                d="M63.28,37.24a.73.73,0,0,1-.23.86,1.08,1.08,0,0,1-1,.19.83.83,0,0,0,.22.29,1.07,1.07,0,0,0,1.35,0,.74.74,0,0,0,0-1.16A1.11,1.11,0,0,0,63.28,37.24Z"
                style="fill: #41b8ba"
              />
              <path
                d="M61.54,40.24a1,1,0,0,0-.34-.19.75.75,0,0,1-.22.87,1.1,1.1,0,0,1-1,.18.77.77,0,0,0,.22.29,1.1,1.1,0,0,0,1.36,0A.74.74,0,0,0,61.54,40.24Z"
                style="fill: #41b8ba"
              />
              <path
                d="M66.37,36.09a1,1,0,0,0,1.22-.51.8.8,0,0,0,0-.34,1.09,1.09,0,0,1-1,.21.87.87,0,0,1-.65-.7.9.9,0,0,0-.21.3A.8.8,0,0,0,66.37,36.09Z"
                style="fill: #41b8ba"
              />
              <path
                d="M68.4,39.13a.7.7,0,0,0,0-.35,1.06,1.06,0,0,1-1,.21.85.85,0,0,1-.64-.69.86.86,0,0,0-.21.29.79.79,0,0,0,.6,1A1,1,0,0,0,68.4,39.13Z"
                style="fill: #41b8ba"
              />
              <path
                d="M62.08,43.36a1.08,1.08,0,0,0-.33.2,1,1,0,0,1,.85.51.74.74,0,0,1-.19.87,1.39,1.39,0,0,0,.4-.06.78.78,0,0,0,.52-1.07A1,1,0,0,0,62.08,43.36Z"
                style="fill: #41b8ba"
              />
              <path
                d="M66.66,45a1.05,1.05,0,0,0-1.31.29.75.75,0,0,0,.34,1.12,1,1,0,0,0,.38.11.72.72,0,0,1,0-.88,1,1,0,0,1,.93-.4A.73.73,0,0,0,66.66,45Z"
                style="fill: #41b8ba"
              />
              <path
                d="M68.63,42.69a1,1,0,0,1,.81-.56,1.27,1.27,0,0,0-.35-.17,1,1,0,0,0-1.21.52.8.8,0,0,0,.61,1,1.42,1.42,0,0,0,.41,0A.74.74,0,0,1,68.63,42.69Z"
                style="fill: #41b8ba"
              />
              <path d="M67.74,34.06l.44,7.57A1.55,1.55,0,0,0,69.74,43l.07.53-.83-9Z" style="fill: #af2b42" />
              <path
                d="M61.59,34l-.21,2.24A27.91,27.91,0,0,1,61,39a10.89,10.89,0,0,1-1.58,3.8l-.07.11c-.08.1-.08.11-.07,0l.07-1.58,1-7Z"
                style="fill: #af2b42"
              />
              <path
                d="M68.74,39c.28,1.13.06,3.68.78,4.55.91,1.08,1.92-.24,1.85-.6a37.13,37.13,0,0,0-2.08-5.13S68.45,37.78,68.74,39Z"
                style="fill: #f2a370"
              />
              <path
                d="M69.94,37.89a7.9,7.9,0,0,0-.76-1.59,5.23,5.23,0,0,0-1.93-.22c-.08.07-.57,1.73-.29,1.83a12.16,12.16,0,0,1,1.58.48c.1.12.38.94.68,1.08S69.94,37.89,69.94,37.89Z"
                style="fill: #f2a370"
              />
              <path
                d="M69.94,37.89A7.11,7.11,0,0,1,70,36.81c.07-.31,0-.34-.22-.35s-.38.82-.38.82Z"
                style="fill: #f2a370"
              />
              <polygon points="68.72 36.7 67.11 36.43 67.09 36.53 68.7 36.8 68.72 36.7" style="fill: #f0b095" />
              <polygon points="68.63 37.17 66.99 36.89 66.97 37 68.6 37.27 68.63 37.17" style="fill: #f0b095" />
              <polygon points="66.89 37.41 66.88 37.51 68.49 37.77 68.52 37.68 66.89 37.41" style="fill: #f0b095" />
              <rect x="61.34" y="63.09" width="3.37" height="0.96" style="fill: #1e4468" />
              <rect x="67.86" y="63.09" width="3.37" height="0.96" style="fill: #1e4468" />
              <path
                d="M64.18,65.7a6.85,6.85,0,0,0-1.63-.11c-.21.12-1.81,1.67-2.41,1.7s-1.6.25-1.84,1.1.08,1.07.34,1.22a6.86,6.86,0,0,0,3.22.17,20.41,20.41,0,0,0,2.94-.71c.26-.17.1-.66,0-1A20.84,20.84,0,0,0,64.18,65.7Z"
                style="fill: #645b53"
              />
              <path
                d="M62.38,68.53a5.58,5.58,0,0,1-3.26.52,5,5,0,0,1-.9-.26.72.72,0,0,0,.42.82,6.86,6.86,0,0,0,3.22.17,20.41,20.41,0,0,0,2.94-.71c.26-.17,0-1-.07-1.34C64.73,67.73,63.18,68.21,62.38,68.53Z"
                style="fill: #4c453e"
              />
              <path d="M61.14,67.27a.16.16,0,0,0,0,.32A.16.16,0,0,0,61.14,67.27Z" style="fill: #312b26" />
              <path d="M61.84,66.86a.16.16,0,0,0,0,.32A.16.16,0,0,0,61.84,66.86Z" style="fill: #312b26" />
              <path d="M62.48,66.37a.16.16,0,0,0,0,.32A.16.16,0,0,0,62.48,66.37Z" style="fill: #312b26" />
              <path
                d="M64.17,68.38,63,68.66a.25.25,0,0,0-.19.31h0a.26.26,0,0,0,.31.2l1.17-.29a.25.25,0,0,0,.19-.31h0A.26.26,0,0,0,64.17,68.38Z"
                style="fill: #645b53"
              />
              <path
                d="M69.08,65.54s.91-.88,1.54,0a7.51,7.51,0,0,0,1.15,1.89c.54.44.9.51,1,1.34s.14.94-.7,1.24-2.57.18-3-.58S69,65.53,69.08,65.54Z"
                style="fill: #645b53"
              />
              <path
                d="M68.86,68.33a2.55,2.55,0,0,0,1.31.82A4.11,4.11,0,0,0,72.11,69a1.16,1.16,0,0,0,.7-.26.76.76,0,0,1-.37,1.13C71.61,70.3,68.62,70.61,68.86,68.33Z"
                style="fill: #4c453e"
              />
              <path d="M69.53,67.59a.16.16,0,0,0,.29.15A.16.16,0,0,0,69.53,67.59Z" style="fill: #312b26" />
              <path d="M69.49,66.77a.16.16,0,0,0,.29.15A.16.16,0,0,0,69.49,66.77Z" style="fill: #312b26" />
              <path d="M69.35,66a.16.16,0,0,0,.28.15A.16.16,0,0,0,69.35,66Z" style="fill: #312b26" />
              <path d="M71.08,67.53a.16.16,0,0,0,.31,0A.16.16,0,0,0,71.08,67.53Z" style="fill: #312b26" />
              <path d="M70.78,66.77a.16.16,0,0,0,.31,0A.16.16,0,1,0,70.78,66.77Z" style="fill: #312b26" />
              <path d="M70.39,66.06a.16.16,0,0,0,.32,0A.16.16,0,0,0,70.39,66.06Z" style="fill: #312b26" />
              <rect
                x="66.17"
                y="47.25"
                width="2.65"
                height="0.38"
                transform="translate(-8.58 15.53) rotate(-12.35)"
                style="fill: #1e4468"
              />
              <rect
                x="60.09"
                y="47.25"
                width="2.06"
                height="0.38"
                transform="translate(110.69 106.86) rotate(-167.65)"
                style="fill: #1e4468"
              />
              <rect x="61.47" y="58.04" width="1.84" height="0.25" style="fill: #1e426e" />
              <rect x="61.61" y="58.76" width="1.89" height="0.1" style="fill: #1e426e" />
              <rect x="61.83" y="58.43" width="2" height="0.14" style="fill: #1e426e" />
            </g>
            <path
              d="M63.67,47.66a8.37,8.37,0,0,0-3.29,7.63l3.29,30,3.28-30A8.34,8.34,0,0,0,63.67,47.66Z"
              style="fill: #b3364a"
            />
            <path
              d="M74.91,31.42c.06-.32-.25-1.31-.16-2a7.52,7.52,0,0,0,.15-2.67c0-.11.31-.13.44.23s.15.84.31,1.31,1.23-.59,1.54.07a3.44,3.44,0,0,1,.19,1.92c-.09.39-1.18.8-1.18,1,0,1.43-.07,10.24-1.89,9.37a50.23,50.23,0,0,1-5.12-3.35l1.42-2.09,2.86,2C73.79,37.45,74.77,32.16,74.91,31.42Z"
              style="fill: #f7a68d"
            />
            <path
              d="M75.65,28.32c-.16-.47-.21-1-.31-1.31s-.46-.34-.44-.23a7.52,7.52,0,0,1-.15,2.67c-.09.66.22,1.65.16,2C74.91,31.42,76,32.32,75.65,28.32Z"
              style="fill: url(#kabas-login__success-linear-gradient)"
            />
            <path
              d="M69.7,39.75,73,36.36l-3.77-2.77a1.89,1.89,0,0,0-2.67-.11h0a1.89,1.89,0,0,0-.12,2.67Z"
              style="fill: #229fa2"
            />
          </g>
        </g>
      </svg>
      <span [innerHTML]="uiState.success"></span>
    </div>

    <div class="kabas-form__buttons [ col-span-full ]">
      <button
        type="submit"
        class="kabas-button kabas-form__submit-button"
        [class.kabas-button--pending]="state === 'processing'"
        [class.kabas-button--error]="isErrorState"
        [class.kabas-button--success]="state === 'success'"
        [class.kabas-button--disabled]="state !== 'processing' && (form.invalid || state === 'success' || isErrorState)"
        [disabled]="state !== 'processing' && (form.invalid || state === 'success' || isErrorState)"
        data-cy="btn-login-student-submit"
        ><div class="kabas-button__label">aanmelden</div></button
      >

      <div *ngIf="!!quickLoginPerson">
        <div
          ><button class="kabas-button kabas-button--inline kabas-login-student__not-me" (click)="notMeClicked()"
            >ik ben {{ quickLoginPerson.firstName }} niet</button
          >
        </div>
        <div>
          <button class="kabas-button kabas-button--inline kabas-login-student__changed" (click)="changedClicked()"
            >ik heb andere inloggegevens</button
          >
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="showQRLoginButton || showLearningIdLoginButton">
    <div class="[ flex gap-l ]">
      <span class="[ flex-1 ] [ border-be border-on-primary ] [ transform -translate-y-1/2 ]"></span>
      <span class="[ title-small on-primary ]">of</span>
      <span class="[ flex-1 ] [ border-be border-on-primary ] [ transform -translate-y-1/2 ]"></span>
    </div>

    <div class="[ title-medium on-primary center-items ]">Aanmelden met:</div>

    <div class="[ cluster center-items ]">
      <div>
        <kabas-persisted-user-card
          *ngIf="showQRLoginButton && !qrScannerVisible"
          [isQRLogin]="true"
          (userCardClicked)="showQrScanner.emit(true)"
          data-cy="card-qr-login"
        ></kabas-persisted-user-card>
        <kabas-persisted-user-card
          *ngIf="showLearningIdLoginButton"
          [isLeerIDLogin]="true"
          (userCardClicked)="ssoLogin('leerId')"
          data-cy="card-leer-id-login"
        ></kabas-persisted-user-card>
      </div>
    </div>
  </ng-container>
</div>

<div class="kabas-form__errors" *ngIf="isErrorState" @fadeIn @slideIn data-cy="login-error">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 132.84 89.09"
    class="kabas-login__failure-image"
  >
    <defs>
      <clipPath id="kabas-login__failure-clip-path">
        <rect x="0.59" y="0.36" width="129.62" height="88.73" style="fill: none" />
      </clipPath>
      <linearGradient
        id="kabas-login-student__failure-linear-gradient"
        x1="33.3"
        y1="41.37"
        x2="94.95"
        y2="41.37"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#973745" />
        <stop offset="0.44" stop-color="#5b1828" />
        <stop offset="0.63" stop-color="#5b1828" />
        <stop offset="0.83" stop-color="#973745" />
      </linearGradient>
    </defs>
    <g>
      <g style="clip-path: url(#kabas-login__failure-clip-path)">
        <polygon
          points="27.41 60.32 24.69 55.59 24.77 50.24 22.17 48.8 18.75 51.2 11.9 51.2 4.41 46.46 0.98 49.03 10.27 56.5 19.56 61.64 22.39 64.93 27.41 60.32"
          style="fill: #f3a470"
        />
        <polygon
          points="104.17 58.81 106.86 54.08 106.77 48.73 109.34 47.28 112.71 49.69 119.45 49.69 126.84 44.95 130.21 47.52 121.06 54.99 111.91 60.13 109.12 63.42 104.17 58.81"
          style="fill: #f3a470"
        />
        <path
          d="M70.42,13.2s-8.85-4.32,3.13-7.3S90.1,15,89.68,21.85s-2,13.71,2,20,3.71,15.8,2.43,20.72S90.67,76,84.68,75.06s-14.41-8.35-19.83-7.9S55,76,49.59,77.15s-11.28-4.92-11.28-4.92c-6.84-6-5.56-18.48-2.71-24.15s-.14-11.48-.14-11.48,4.57-11.47,13-15.8S61.85,8.88,70.42,13.2Z"
          style="fill: url(#kabas-login-student__failure-linear-gradient)"
        />
        <path
          d="M56.64,50.13S39.27,56.52,38.1,59.46c-.09.25,3.82,13.26,5.52,24.28C45,92.57,48.08,110.53,48,111.27c-1.43,10.71,25.81,10,33.78,0l5.53-30.2L93,58.66c-1.45-3.07-22.64-11.32-22.64-11.32S64.08,54.55,56.64,50.13Z"
          style="fill: #bf3a2f"
        />
        <path
          d="M78.8,33.09s1.63-2,2.54-1.34.39,9.54-.4,10.82-3.2,2.09-3.2,2.09A42.83,42.83,0,0,0,78.8,33.09Z"
          style="fill: #d89264"
        />
        <path
          d="M47.48,33.21S45.73,31.28,44.86,32s.19,9.55,1,10.77,3.33,1.9,3.33,1.9A42.33,42.33,0,0,1,47.48,33.21Z"
          style="fill: #d89264"
        />
        <path
          d="M38.58,55.33c-1.88,1.92-2.63,2.48-4.44,9.44S30.76,80,30.35,81.56s-.08,2.44,0,3.85-1.44,3.11-1.62,5,.64,5,.72,6.29,3.35,4.66,8.07,4.74a10.08,10.08,0,0,0,8.87-5.33c.92-2,4.13-19.16,4.87-27.08S43,50.85,38.58,55.33Z"
          style="fill: #176c7d"
        />
        <path
          d="M41.75,99.75c4.84-5,2.86-6.68,1.7-10.54s-1.91-4.45-2.44-7S30.49,63,30.69,61.39s-3.15-5.74-6.07-3.72-4.42,3.18-5,3.61-.76,2.15.08,5.33,7.76,29.46,9.78,34.26S39.59,102,41.75,99.75Z"
          style="fill: #176c7d"
        />
        <path
          d="M94.1,55.16c2,1.82,2.79,2.34,5.07,9.18s4.4,15,4.91,16.56.25,2.43.26,3.84,1.65,3,2,4.93-.31,5-.3,6.32-3,4.83-7.73,5.16a10.21,10.21,0,0,1-9.22-4.83c-1.06-1.94-5.42-18.9-6.69-26.76S89.42,50.93,94.1,55.16Z"
          style="fill: #176c7d"
        />
        <path
          d="M90,98.31c-4.79-5.12-2.81-6.76-1.63-10.63s1.93-4.45,2.47-7.05,10.57-19.12,10.38-20.78,3.17-5.73,6.06-3.66,4.37,3.24,5,3.68.74,2.17-.11,5.36-7.89,29.55-9.93,34.34S92.15,100.6,90,98.31Z"
          style="fill: #176c7d"
        />
        <path
          d="M54.64,47.1s-13.57,5.93-16,8.1c0,0,.79,11.56,3.1,18.35s2.85,25.54,1.76,27.82-2.62,4.4-1.88,6,2.54,4.46,2.65,5-4.58,7.42-4.53,9.1,8.22,1.29,11.53,1.77,13.71,2,17.22,1.16,12.32.25,13.42.13c3.72-.4,3.72-.26,6.07-.61a19.37,19.37,0,0,1,5,0,37,37,0,0,0-1.18-9.14,37.85,37.85,0,0,1-2.12-13.42c.06-11.14,2.82-47.1,2.82-47.1L72.24,46.53S68.82,55.1,54.64,47.1Z"
          style="fill: #134b72"
        />
        <path
          d="M75.86,122.4a1.32,1.32,0,0,0,2.06.74,1.24,1.24,0,0,0,1.85.74c1.17-.77,1.91-3,2.17-4.4a5,5,0,0,0,.11-2.46,10.47,10.47,0,0,0-.79-3.17l-.05-.09a1.32,1.32,0,0,0,.43-.49c3.79-3.16,2.29-7.76.8-12.39A11.15,11.15,0,0,0,85.91,91a16.8,16.8,0,0,0-1.34-4,13.73,13.73,0,0,0,1.86-11.88A18.63,18.63,0,0,0,85,71.73c4.64-2.51,3.45-7.75,1.24-12.56a3.24,3.24,0,0,1,.73-.33c1.22-.27,1.86-2.46,1.26-3.79-.74-1.68-3.14-1.62-2.95.21a2.19,2.19,0,0,1-.81,1.43A9.91,9.91,0,0,1,81.42,53c-.84-1.6-3.11-.15-2.27,1.45a11.44,11.44,0,0,0,4,4.21c-2.06,3.11-3.57,6.48-2.3,10.38a15.34,15.34,0,0,0,.76,1.83,11.42,11.42,0,0,0-2.5,10.66,19.84,19.84,0,0,0,2.17,5,10.59,10.59,0,0,0-3,7.94,21,21,0,0,0,1.35,5.5c-1.65,2.1-3.15,4.48-3.13,7.33a15.51,15.51,0,0,0,1.22,5c.06.18.15.39.25.63A9.75,9.75,0,0,0,75.86,122.4Zm3.23-6.6a8.59,8.59,0,0,1,.35,1.29,6.36,6.36,0,0,1,0,1.51,1.6,1.6,0,0,1-.13.3,1.33,1.33,0,0,0-.19,1c-.08.21-.15.42-.25.62,0,0-.56.94-.42.9l-.06,0A6.8,6.8,0,0,1,79.09,115.8Zm4.69-46.58c-.06-.12-.12-.24-.17-.37-1.12-2.72-.39-5.1.87-7.32C85.83,64.82,86.32,68,83.78,69.22Zm-.56,14.84c-1.76-3.74-3.21-7.5-.35-10.7.25.51.48,1,.68,1.55A11.08,11.08,0,0,1,83.22,84.06Zm-.67,5a8.47,8.47,0,0,1-1,8.84C80.65,94.64,80.37,91.53,82.55,89.05ZM80,111a9.33,9.33,0,0,1-.78-4.62,8.2,8.2,0,0,1,1.38-3.3C81.5,106.25,81.92,109.27,80,111Z"
          style="fill: #176c7d"
        />
        <path
          d="M64.09,122.66a1.31,1.31,0,0,0,2.12.5,1.22,1.22,0,0,0,1.91.52c1.09-.9,1.61-3.17,1.73-4.63a5,5,0,0,0-.13-2.45,10.56,10.56,0,0,0-1.1-3.07.35.35,0,0,0-.05-.08,1.41,1.41,0,0,0,.38-.54c3.46-3.58,1.52-8-.42-12.41A11.29,11.29,0,0,0,71,90.24,16.59,16.59,0,0,0,69.3,86.4a13.83,13.83,0,0,0,.69-12,19.16,19.16,0,0,0-1.73-3.15c4.37-3,2.67-8.1,0-12.63.09-.09.13-.22.23-.33A6,6,0,0,0,69.78,52c-.6-1.73-3-.68-2.45,1A2.44,2.44,0,0,1,66.62,56c-1.73-.56-1.83-1.43-2.62-2.64-1-1.49-3.89-.23-2.9,1.27.68,1,.67,2.44,4,3.81-1.74,3.32-2.91,6.85-1.27,10.58a13.11,13.11,0,0,0,.93,1.73,11.54,11.54,0,0,0-1.45,10.89A19.7,19.7,0,0,0,66,86.33a10.69,10.69,0,0,0-2.24,8.24,20.13,20.13,0,0,0,1.87,5.31c-1.44,2.28-2.69,4.81-2.4,7.65a15.4,15.4,0,0,0,1.7,4.88c.08.17.19.37.31.59A9.82,9.82,0,0,0,64.09,122.66Zm2.57-6.94a10.17,10.17,0,0,1,.47,1.25,6.29,6.29,0,0,1,.12,1.5,1.42,1.42,0,0,1-.11.31,1.37,1.37,0,0,0-.08,1c-.06.22-.12.44-.2.65,0,0-.46,1-.32.94l-.06,0A6.81,6.81,0,0,1,66.66,115.72Zm.13-46.85c-.07-.12-.14-.23-.2-.35-1.38-2.57-.89-5,.15-7.38C68.4,64.26,69.2,67.37,66.79,68.87Zm.88,14.82c-2.11-3.52-3.91-7.09-1.38-10.6.29.48.57,1,.82,1.46A11,11,0,0,1,67.67,83.69Zm-.18,5a8.46,8.46,0,0,1-.15,8.9C66.15,94.51,65.57,91.45,67.49,88.73Zm-.42,22.14a9.2,9.2,0,0,1-1.22-4.51,8.42,8.42,0,0,1,1.05-3.44C68.12,106,68.83,108.9,67.07,110.87Z"
          style="fill: #176c7d"
        />
        <path
          d="M49.6,74.09a16.52,16.52,0,0,0-.68,1.55,11.06,11.06,0,0,0,.33,9.14C51,81,52.46,77.29,49.6,74.09Z"
          style="fill: none"
        />
        <path
          d="M48.86,69.58c1.12-2.72.39-5.1-.87-7.32-1.35,3.29-1.84,6.47.7,7.69C48.75,69.83,48.8,69.71,48.86,69.58Z"
          style="fill: none"
        />
        <path
          d="M56.6,123.13a9.74,9.74,0,0,0-2-9.48c.1-.24.19-.45.25-.63A15.57,15.57,0,0,0,56,108c0-2.86-1.48-5.24-3.13-7.34a20.38,20.38,0,0,0,1.34-5.49,10.53,10.53,0,0,0-3-7.94,19.91,19.91,0,0,0,2.17-5,11.43,11.43,0,0,0-2.5-10.66,14.55,14.55,0,0,0,.76-1.82c1.26-3.9-.25-7.27-2.3-10.38C50.61,57,52,54.79,52.57,53.7c.84-1.61-1.43-3.06-2.27-1.45-.68,1.29-1.69,3-2.7,4.87a10.86,10.86,0,0,1-1.09-5c0-.28-2.79.74-2.76,1.47A12.35,12.35,0,0,0,46,59.6a1.53,1.53,0,0,0,.25.3C44,64.71,42.82,70,47.46,72.46A18.63,18.63,0,0,0,46,75.79,13.74,13.74,0,0,0,47.9,87.67a16.8,16.8,0,0,0-1.34,4A11.13,11.13,0,0,0,50,101.6c-1.49,4.64-3,9.23.8,12.39a1.27,1.27,0,0,0,.43.5.35.35,0,0,0,0,.08,10.85,10.85,0,0,0-.8,3.18,5.08,5.08,0,0,0,.12,2.45,10.94,10.94,0,0,0,.72,2.4l5.3.65A.61.61,0,0,0,56.6,123.13ZM48,62.26c1.26,2.22,2,4.6.87,7.32-.06.13-.11.25-.17.37C46.15,68.73,46.64,65.55,48,62.26Zm.93,13.38a16.52,16.52,0,0,1,.68-1.55c2.86,3.2,1.41,7-.35,10.69A11.06,11.06,0,0,1,48.92,75.64Zm2,23a8.47,8.47,0,0,1-1-8.84C52.1,92.26,51.82,95.37,50.94,98.62Zm1,5.21a8.16,8.16,0,0,1,1.38,3.29,9.35,9.35,0,0,1-.78,4.63C50.55,110,51,107,51.89,103.83Zm2.22,18.34-.06,0c.14,0-.42-.91-.42-.9a5.36,5.36,0,0,1-.25-.62,1.36,1.36,0,0,0-.19-1,1.51,1.51,0,0,1-.13-.29,5.87,5.87,0,0,1,0-1.51,8.39,8.39,0,0,1,.35-1.3A6.83,6.83,0,0,1,54.11,122.17Z"
          style="fill: #176c7d"
        />
        <path d="M56.15,41.51V52.39c0,.94,7.83,12.46,16-.53l.21-11.12Z" style="fill: #d89264" />
        <path
          d="M74.52,14.94c-.17,0-25.62-.15-26,.26s-1.43,18.22-1.35,20.14,2.33,12.56,3.39,14.1a103,103,0,0,0,9.56,8.84c.57.24,4.29.26,5-.23s11.32-8.28,12-10.53c.33-1,2.85-11.75,1.59-14.82S74.52,14.94,74.52,14.94Z"
          style="fill: #f2a370"
        />
        <path
          d="M73.7,15.44s-4-2.13-9.61,1.78A13.73,13.73,0,0,1,60.45,19c-2.29.75-5.78,2.58-6.16,6.85-.57,6.41-5.42,10.44-7.42,11.18a36.41,36.41,0,0,0-5.7,3.43s-6.28,3-6.28-8.94,5.85-13.12,10-18.34,4.42-8.64,9.7-11.33S70-.06,73.84,5.3A8.36,8.36,0,0,1,73.7,15.44Z"
          style="fill: #973745"
        />
        <path
          d="M69.28,14.1S67.86,18.27,74.14,23c0,0,2.56.75,3.42,9.09s7.7,13.12,7.7,13.12l.15-21.76S87.55,3.81,69.86,6.79,69.28,14.1,69.28,14.1Z"
          style="fill: #973745"
        />
        <path
          id="Tear"
          d="M58.39,44.75a1.76,1.76,0,1,1-3.51,0,7.62,7.62,0,0,1,1.75-4.28A7.76,7.76,0,0,1,58.39,44.75Z"
          style="fill: #fad8c3"
        />
      </g>
    </g>
  </svg>
  <span [innerHTML]="uiState.loginErrors[state]"></span>
</div>
