<div class="kabas-login">
  <div class="kabas-login__background">
    <kabas-login-svg *ngIf="!qrScannerVisible"></kabas-login-svg>
  </div>
  <div *ngIf="qrScannerVisible" class="[ block p-i-s p-bs-2xl ]">
    <div
      class="[ block min-h-3xs corner-m relative ] [ tablet-l:corner-left-xl tablet-l:corner-right-none tablet-l:max-h-full tablet-l:absolute ] [ bottom-0 index-overlay left-0 right-1/2 top-0 overflow-hidden ]"
    >
      <campus-camera
        [showOverlay]="true"
        [isScannerEnabled]="true"
        [showCameraSelect]="true"
        (scanResultReceived)="onScanResultReceived($event)"
        data-cy="qr-scanner"
      ></campus-camera>

      <div
        *ngIf="scanError$ | async as scanError"
        class="[ absolute index-overlay text-warn warn-container bottom-0 left-0 right-0 center-content ]"
        data-cy="qr-scanner-error"
      >
        {{ scanError }}
      </div>
    </div>
  </div>
  <div class="kabas-login__form">
    <kabas-login-student
      *ngIf="role === 'student'"
      [success]="success"
      [qrScannerVisible]="qrScannerVisible"
      [classGroups]="classGroups$ | async"
      (schoolCode)="schoolCodeSet($event)"
      (submitForm)="login($event)"
      (ssoLoginClicked)="ssoLogin($event)"
      (afterSuccess)="goToPlatform()"
      (forgetPerson)="forgetPerson($event)"
      (showQrScanner)="showQrScanner($event)"
      [persistedUsers]="persistedUsers$ | async"
      data-cy="modal-login-student"
    ></kabas-login-student>

    <kabas-login-teacher
      *ngIf="role === 'teacher'"
      (submitForm)="login($event)"
      [success]="success"
      (afterSuccess)="goToPlatform()"
      (forgotPassword)="resetPassword($event)"
      data-cy="modal-login-teacher"
    ></kabas-login-teacher>
  </div>
</div>
