import { Injectable, inject } from '@angular/core';
import { PersonApi, StudentCodeLoginCredentials } from '@campus/public-portal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pessimisticUpdate } from '@nrwl/angular';
import { map, tap } from 'rxjs/operators';
import { PersistedUsersFacade } from '../../facades/persisted-users.facade';
import { AppAuthActions, StudentCodeLogin, StudentCodeLoginFail, StudentCodeLoginSuccess } from './app-auth.actions';

@Injectable()
export class AppAuthEffects {
  private actions$ = inject(Actions);
  private persistedUsers = inject(PersistedUsersFacade);
  private personApi = inject(PersonApi);

  studentCodeLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppAuthActions.STUDENT_CODE_LOGIN),
      pessimisticUpdate({
        run: (action: StudentCodeLogin) => {
          return this.personApi
            .loginWithPersonalCode(action.payload.credentials)
            .pipe(map((data) => new StudentCodeLoginSuccess(data['user'], action.payload.credentials)));
        },
        onError: (action: StudentCodeLogin, error) => {
          return new StudentCodeLoginFail({ error: 'Kon niet inloggen met de gegeven code.' });
        },
      })
    )
  );

  studentCodeLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppAuthActions.STUDENT_CODE_LOGIN_SUCCESS),
        tap((action: StudentCodeLoginSuccess) => {
          const { childCode, ...credentials } = action.payload.credentials as StudentCodeLoginCredentials;
          this.persistedUsers.save(action.payload.current.id, {
            ...action.payload.current,
            ...credentials,
            lastLogin: new Date(),
          });
        })
      ),
    { dispatch: false }
  );
}
