<h1>Algemene gebruiksvoorwaarden</h1>
<div>
  <div class="[ stack stack-space-2xl ]">
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 1 - Eigendom en wijziging website</h2>
        <p>
          De Website www.kabas.be (hierna “KABAS”) en alle afgeleide producten incluis de Kweetet game voor pc/mac en
          tablets, alsook alle mogelijke sub-domeinen van deze Website worden beheerd door - en zijn eigendom van - Die
          Keure NV, met maatschappelijke zetel te Kleine Pathoekeweg 3, 8000 Brugge, België, ingeschreven in de
          Kruispuntbank van Ondernemingen onder het nummer 0405.108.325 ("Die Keure").
        </p>
        <p>
          In geval van vragen, klachten en/of opmerkingen met betrekking tot KABAS kun je je steeds richten tot Die
          Keure, Kleine Pathoekeweg 3, 8000 Brugge, aan het e-mailadres
          <a href="mailto:info@diekeure.be">info@diekeure.be</a> of op het telefoonnummer 050/47.12.72.
        </p>
        <p>
          Die Keure behoudt zich het recht voor deze Algemene Gebruiksvoorwaarden te allen tijde, en zonder voorafgaande
          verwittiging, te wijzigen om te voldoen aan de wettelijke verplichtingen of om de service te verbeteren. De
          eventuele aangepaste versie van deze Algemene Gebruiksvoorwaarden wordt geacht van toepassing te zijn vanaf de
          eerste datum van publicatie op KABAS. Wij raden je daarom aan de KABAS Website regelmatig te raadplegen.
        </p>
        <p>
          Het gebruik van de Website impliceert dat “de gebruiker” van de Website de in de Algemene Gebruiksvoorwaarden
          opgenomen voorwaarden en richtlijnen voor gebruik van de Website volledig en onherroepelijk aanvaardt. Deze
          Algemene Gebruiksvoorwaarden gelden zodoende zowel voor de loutere bezoeker van de Website (zonder in te
          loggen) als voor de deelnemers op het digitale onderwijsplatform waar men, na registratie en het aanmaken van
          een persoonlijke account, onder een gebruikersnaam actief kan zijn.
        </p>
        <p>
          Die Keure behoudt zich te allen tijde het recht voor om een gebruiker de toegang tot de Website geheel of
          gedeeltelijk te ontzeggen, onder andere in geval van duidelijke schending of vermoeden van schending van
          onderhavige bepalingen.
        </p>
        <p>
          Die Keure wenst te benadrukken dat zij zich bewust is van de moeilijkheden en uitdagingen waarmee ouders en/of
          bevoegde meerderjarigen zoals leerkrachten mogelijkerwijze worden geconfronteerd bij het hedendaagse
          internetgebruik van hun kinderen en/of leerlingen. Die Keure stelt dan ook alles in het werk om een zo veilig
          mogelijke internetomgeving te creëren voor minderjarigen met inachtneming van een gezonde dosis
          voorzichtigheid bij het ontwerpen van de inhoud van de Website en het nodige respect bij de verwerking van
          gegevens van deze minderjarigen. Daarom zal het voor minderjarigen slechts mogelijk zijn gebruik te maken van
          de verschillende leer- en speelmogelijkheden nadat hun school of bevoegde leerkracht de leerling geregistreerd
          heeft op het Platform. Die Keure nodigt de ouders en/of bevoegde meerderjarigen, zoals leerkrachten of voogd,
          uit om ook na deze registratie steeds een oogje in het zeil te houden telkens wanneer de minderjarigen gebruik
          maken van de Website alsook het internet in het algemeen.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 2 - Voorwerp </h2>
        <p>
          KABAS vormt een zogenaamd onderwijsplatform waarin er een digitale leeromgeving wordt gecreëerd zowel online
          als via de bijbehorende applicaties voor pc, mac en tablets, waarin zowel leerlingen als leerkrachten zich
          terugvinden. Verschillende doelgroepen krijgen binnen KABAS toegang tot verschillende delen van de Website en
          de applicaties. Op heden zijn de verschillende doelgroepen: leerlingen uit het lager onderwijs, leerlingen uit
          het kleuteronderwijs, leerkrachten en onderwijspersoneel.
        </p>
        <p>
          Die Keure behoudt zich te allen tijde het recht voor om de registratie van een gebruiker te weigeren zonder
          opgave van motivering. In het bijzonder geldt dit weigeringsrecht bij een vermoeden dat de gebruiker
          onvolledige of onjuiste gegevens verschaft heeft. Verder geldt dit weigeringsrecht onder andere ook bij
          vermoeden van het gebruik van geautomatiseerde systemen waaronder zogenaamde bots.
        </p>
        <p>
          Die Keure behoudt zich eveneens het recht voor om geregistreerde gebruikers te allen tijde de toegang tot
          KABAS en bijbehorende applicaties voor pc, mac en tablets te weigeren en, indien toepasselijk, accounts
          definitief te verwijderen indien de gebruiker in kwestie op een dergelijke manier gebruik maakt van KABAS en
          bijbehorende applicaties voor pc, mac en tablets dat dit gebruik redelijkerwijze als onaanvaardbaar kan
          beschouwd worden. Een “onaanvaardbaar gebruik” van KABAS is onder andere, doch niet uitsluitend:
        </p>
        <ul class="[ title-small ]">
          <li>
            het plaatsen van kwetsende, racistische, haatdragende, extremistische, beledigende of pornografische
            berichten, mededelingen, data, ...; </li
          ><li>
            het plaatsen van berichten, mededelingen, data, ... die het risico lopen als “laster en eerroof” te worden
            beschouwd of smaad beogen; </li
          ><li>
            alle handelingen die als gelijk welke vorm van hacken of poging tot hacken van KABAS zouden kunnen worden
            beschouwd; </li
          ><li> als privaat persoon zich voordoen als een school of onderwijsinstelling; </li
          ><li>
            handelingen die op eender welke manier de rechten van Die Keure schenden – met inbegrip van, doch niet
            uitsluitend, het plaatsen van berichten, mededelingen, data, ... die een aantasting van haar goede reputatie
            uitmaken; </li
          ><li>
            handelingen die hetzelfde of een gelijkaardig effect teweeg brengen als het uploaden van bestanden of
            informatie, het gebruiken of uitwisselen van mogelijk illegale bestanden of informatie (bijvoorbeeld muziek,
            films enz.), het verzenden van spam enz. via KABAS; </li
          ><li>
            het plaatsen van inhoud die op welke manier dan ook een commerciële achtergrond heeft en bijvoorbeeld
            gericht is op het werven van cliënteel, het maken van reclame, marketinggerelateerde doeleinden, enz. </li
          ><li>
            het schenden van auteursrechten en intellectuele eigendomsrechten met betrekking tot de inhoud en de vorm
            van de Website en afgeleide producten. Een voorafgaande toestemming omtrent het delen van inhoudelijke of
            vormelijke elementen van KABAS en afgeleide producten is altijd vereist.
          </li>
        </ul>
        <h3>Licentiëring </h3>
        <p>
          De content die beschikbaar is op KABAS wordt aangeboden binnen een licentiemodel, elke jaar te hernieuwen.
          Algemeen geldt dat alle content die door Die Keure wordt aangeboden onder het auteursrecht van Die Keure valt.
          Behoudens de uitdrukkelijk bij wet bepaalde uitzonderingen mag niets uit deze uitgaven worden verveelvoudigd,
          opgeslagen in een geautomatiseerd gegevensbestand of openbaar gemaakt, op welke wijze dan ook, zonder de
          uitdrukkelijke voorafgaande en schriftelijke toestemming van de uitgever.
        </p>
        <p> Die Keure behoudt zich het recht voor om het licentiemodel na een schooljaar te wijzigen. </p>
        <p>
          Eventuele uitzonderingen op het licentiemodel dienen afzonderlijk te worden overeengekomen tussen die Keure en
          de school in kwestie. Bij vermoeden van overtreding van deze gebruiksbepaling behoudt die Keure zich het recht
          voor om leerkrachten en leerlingen de toegang tot KABAS te ontnemen.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 3 - Registratie en gebruik website </h2>
        <p>
          Naast de verplichte aanvaarding van Die Keure’s Privacyverklaring (zie hieronder artikel 6) zullen de
          gebruikers van de Website hun registratie slechts kunnen afsluiten indien zij zich akkoord verklaren met deze
          Algemene Gebruiksvoorwaarden.
        </p>
        <p>
          Voor verdere vragen en/of opmerkingen betreffende het afronden, op een geldige en correcte manier, van de
          registratie van een gebruiker op het Platform (bijvoorbeeld: het aanmaken van een uniek wachtwoord, de
          verantwoordelijkheid van de gebruikers omtrent de geheimhouding van hun gegevens, het aanmaken van een unieke
          gebruikersnaam en avatar in de Kweetet game, enz.) wordt verwezen naar de FAQ.
        </p>
        <h3>Schoolbeheerder</h3>
        <p> Aan de schoolbeheerder is de verantwoordelijkheid toegewezen om voor de school in kwestie </p>
        <ul class="[ title-small ]">
          <li> de gebruikers, nl. de leerkrachten en leerlingen, te registreren; </li>
          <li> de methodelicenties te activeren in het begin van het schooljaar. </li>
        </ul>
        <p> Het profiel van de schoolbeheerder wordt aangemaakt door die Keure in opdracht van de schooldirectie. </p>
        <h3>Leerlingen</h3>
        <p>
          Minderjarige kinderen kunnen slechts ten volle gebruik maken van KABAS en bijbehorende applicaties voor pc,
          mac en tablets wanneer de schoolbeheerder hen registreert als een gebruiker met gebruikersnaam en paswoord.
        </p>
        <p>
          Hiermee geeft respectievelijk de bevoegde school of bevoegde meerderjarige de toelating aan de minderjarige
          gebruiker om de KABAS ten volle te gebruiken.
        </p>
        <p> In hoofdzaak vervullen leerlingen op KABAS vooral één of meerdere van deze activiteiten: </p>
        <ul class="[ title-small ]">
          <li>
            Taken afwerken (binnen een bepaalde periode) die door een leerkracht aan een leerling toegewezen werden;
          </li>
          <li>
            Zelfstandig en vrijblijvend oefeningen maken op onderwerpen die door een leerkracht beschikbaar gesteld
            werden;
          </li>
          <li> Het winnen van de virtuele munteenheid “Kwetons” door het maken van oefeningen </li>
        </ul>
        <p> Met betrekking tot de Kweetet game kan de leerling volgende activiteiten uitvoeren: </p>
        <ul class="[ title-small ]">
          <li>
            Het aanmaken van een avatar (fictieve weergave van zichzelf a.d.h.v. zelf samen te stellen tekenfiguur);
          </li>
          <li> (Vooraf vastgelegde) dialogen voeren met spelletjespersonages; </li>
          <li>
            Het eenvoudigweg ronddwalen door de verschillende gangen en paden van de wereld die het Platform rijk is en
            zich laten verrassen door de avonturen die de personages kunnen beleven. Voor een algemeen overzicht van de
            Game kan men zich steeds tot het onderdeel “Kaart” (eveneens bekend als “Telepolser”) begeven.
          </li>
        </ul>
        <p>
          De in KABAS verdiende Kwetons kunnen door leerlingen aangewend worden om in de Game virtuele aankopen te
          verrichten, waaronder:
        </p>
        <ul class="[ title-small ]">
          <li>
            Het kopen van kleertjes voor hun gepersonaliseerde avatar, alsook het bestellen van een nieuwe haarsnit, het
            veranderen van de kleuren van hun kledij of het aanschaffen van nieuwe schoenen (zie onderdeel
            “Kleedkamer”);
          </li>
          <li>
            Ook het ontsluiten van een kleurplaat die nadien kan afgedrukt worden (zie onderdeel “Schildersezel”).
          </li>
        </ul>
        <p>
          Het hierboven opgesomde gamma aan activiteiten kan door Die Keure ten allen tijde en zonder opgave van reden
          eenzijdig gewijzigd worden zonder dat de gebruikers van KABAS hiertegen in beroep kunnen gaan.
        </p>
        <p>
          Tenslotte beschikt Die Keure over de exclusieve bevoegdheid aangaande de uitgave, toekenning, behandeling enz.
          van de Kwetons alsook de virtuele aankopen die hiermee mogelijkerwijze kunnen worden verricht. In geval van
          een geschil hieromtrent zal Die Keure naar alle redelijkheid een beslissing nemen, dewelke onaanvechtbaar en
          definitief is.
        </p>
        <h3>Leerkrachten </h3>
        <p>
          Leerkrachten (alsook erkende specialisten uit het onderwijssysteem zoals o.a. een zorgcoördinator) ontvangen
          een profiel op KABAS dat werd aangemaakt en verstrekt door de schoolbeheerder.
        </p>
        <p>
          De door de school of onderwijsinstelling geregistreerde leerlingen worden door de schoolbeheerder gekoppeld
          aan hun respectievelijke leerkracht en/of klas waarna de leerkracht de respectievelijke leerling terugvindt in
          een klasoverzicht.
        </p>
        <p>
          De leerkracht gaat akkoord dat de ouders (of bevoegde meerderjarige) van de door de school of
          onderwijsinstelling geregistreerde leerlingen op de hoogte gebracht worden bij aanvang van het gebruik van
          KABAS, alsook dat hun minderjarig kind werd geregistreerd op KABAS en de bijbehorende applicaties voor pc, mac
          en tablets.
        </p>
        <p> In hoofdzaak vervullen leerkrachten vooral één (of meerdere) activiteiten zoals: </p>
        <ul class="[ title-small ]">
          <li> Bekijken van al het digitale lesmateriaal van de door de school aangekochte methodes van Die Keure; </li>
          <li> Bordboeken gebruiken; </li>
          <li> Oplossingen van oefeningen raadplegen; </li>
          <li> Handleidingen raadplegen; </li>
          <li> Diverse media (audio/video) raadplegen als onderdeel van een methode; </li>
          <li> Toewijzen van vrijblijvende oefeningen en taken aan leerlingen; </li>
          <li>
            Bekijken van de resultaten van gemaakte taken en oefeningen (scores, aantal pogingen, gespendeerde tijd).
          </li>
        </ul>
        <p>
          Een leerkracht kan een oefening of taak toewijzen aan één leerling of aan een groep leerlingen, dit in het
          kader van remediëring, differentiatie, zorgwerking en het M-decreet.
        </p>
        <p>
          Ten slotte zullen leerkrachten ook de scores van leerlingen kunnen exporteren naar Excel enz. Dit laat een
          overdracht naar een zogenaamd rapportprogramma van de school toe.
        </p>
        <p>
          Bepaalde informatie over leerlingen zal de leerkrachten niet ter beschikking worden gesteld, hieronder de duur
          van de spelletjes in de Kweetet Game gespeeld door de kinderen, het tijdstip waarop het kind in de Kweetet
          game speelde enz.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 4 - Intellectuele eigendom </h2>
        <p>
          Alle bestanddelen van KABAS en de bijbehorende applicaties voor pc, mac en tablets (waaronder doch niet
          beperkt tot de gebruikte software, sourcecodes, de structuur, lay-out, teksten, avatars, logo’s, muziekjes, de
          ingesproken stemmen, foto’s, tekeningen, oefeningen, spelletjes, beeld, geluid, databanken, benamingen,
          beschrijvingen, handels- en domeinnamen) zijn werken die beschermd zijn door het auteursrecht en/of andere
          intellectuele eigendomsrechten waaronder het merkenrecht.
        </p>
        <p>
          Alle desbetreffende eigendomsrechten, inclusief intellectuele eigendomsrechten (auteursrechten, merkenrechten,
          databankrechten, tekeningen- en modellenrechten, etc.) behoren toe aan Die Keure of zijn opgenomen op de
          Website en bijbehorende applicaties voor pc, mac en tablets met toestemming van de eigenaar van de
          desbetreffende rechten.
        </p>
        <p>
          Geen enkel bestanddeel van KABAS en bijbehorende applicaties voor pc, mac en tablets zelf, noch de op KABAS en
          bijbehorende applicaties voor pc, mac en tablets aangeboden gegevens en informatie mag worden opgeslagen
          (anders dan noodzakelijk om KABAS te kunnen bezoeken), gereproduceerd, gewijzigd, vertaald, openbaar gemaakt,
          verspreid, verhuurd, verkocht, overgedragen aan anderen of op enige wijze worden gebruikt zonder voorafgaande
          en schriftelijke toelating van Die Keure.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 5 - Aansprakelijkheid </h2>
        <p>
          Die Keure waakt ervoor zo correct mogelijke informatie te verstrekken maar kan nooit de juistheid,
          volledigheid en geschiktheid van de informatie op KABAS en bijbehorende applicaties voor pc, mac en tablets
          verzekeren en kan hiervoor derhalve niet aansprakelijk worden gesteld. Dit geldt zowel voor informatie die
          door Die Keure zelf op KABAS en bijbehorende applicaties voor pc, mac en tablets werd geplaatst, alsook voor
          informatie afkomstig van gebruikers of anderen. Onder informatie wordt verstaan: al hetgeen op KABAS en
          bijbehorende applicaties voor pc, mac en tablets terug te vinden is, waaronder teksten, oefeningen,
          spelletjes, foto’s, geluid, data enz.
        </p>
        <p>
          Die Keure is niet aansprakelijk voor welke beslissing of handeling dan ook die door de gebruiker zou zijn
          genomen op basis van de verstrekte informatie t.a.v. gebruikers of anderen, noch is zij verantwoordelijk voor
          fouten of vergissingen van de gebruiker.
        </p>
        <p>
          Die Keure is evenmin aansprakelijk voor directe of indirecte schade of gelijk welke schade die veroorzaakt zou
          kunnen worden door onjuistheid, onvolledigheid, ongeschiktheid, vergetelheid of nalatigheid in het
          verschaffen, samenstellen, opstellen, schrijven en interpretatie van de informatie op KABAS en bijbehorende
          applicaties voor pc, mac en tablets.
        </p>
        <p>
          Alvorens toegang te verkrijgen tot KABAS en bijbehorende applicaties voor pc, mac en tablets is het mogelijk
          dat de gebruiker bepaalde software moet installeren (Unity). Deze software voldoet aan alle gangbare normen en
          standaarden inzake veilige en betrouwbare software. De KABAS-software voldoet aan alle gangbare normen en
          standaarden inzake veilige en betrouwbare software. Die Keure is daarom niet aansprakelijk voor permanente of
          tijdelijke schade of defecten aan gegevens of computerapparatuur van de gebruikers tijdens of na het gebruik
          van KABAS en bijbehorende applicaties voor pc, mac en tablets. In het bijzonder is Die Keure niet
          aansprakelijk voor de mogelijke overdracht van virussen, trojans en dergelijke via KABAS en bijbehorende
          applicaties voor pc, mac en tablets.
        </p>
        <p>
          Die Keure is niet aansprakelijk voor links naar websites die door anderen worden beheerd of de schade die
          veroorzaakt wordt in het kader van een bezoek aan deze websites. Die Keure kan geen enkele controle uitoefenen
          op deze websites en bijbehorende applicaties voor pc, mac en tablets en kan niet aansprakelijk worden gesteld
          voor de inhoud ervan.
        </p>
        <p>
          Voor de realisatie van KABAS en bijbehorende applicaties voor pc, mac en tablets doet Die Keure zoveel
          mogelijk beroep op de modernste technieken. Die Keure kan evenwel niet aansprakelijk gesteld worden voor het
          (tijdelijk) uitvallen of eventuele storingen of onderhoudswerken op - of van - KABAS en bijbehorende
          applicaties voor pc, mac en tablets.
        </p>
        <p>
          De gebruiker is te allen tijde exclusief verantwoordelijk voor alle berichten, data, commentaren, informatie
          enz. die de gebruiker middels zijn/haar actieve deelname op KABAS en bijbehorende applicaties voor pc, mac en
          tablets meedeelt. De gebruiker is zodoende ook exclusief verantwoordelijk voor alle mogelijke rechten,
          waaronder auteursrechten, merkenrechten en dergelijke, die kunnen rusten op de informatie die de gebruiker op
          KABAS meedeelt. Dit houdt dus in dat de gebruiker exclusief verantwoordelijk zal zijn voor elke vordering
          vanwege derden of betrokkenen wegens inbreuken op hun rechten. De gebruiker vrijwaart Die Keure volledig van
          elke vordering vanwege derden wegens dergelijke (vermeende) inbreuken op hun rechten door de informatie
          (beeld, tekst, klank enz.) die door de gebruiker op KABAS werd geplaatst.
        </p>
        <p>
          Overeenkomstig artikel 1384 van het Burgerlijke Wetboek dienen ouders te allen tijde in te staan voor de daden
          (en eventuele veroorzaakte schade) van hun kinderen. Dit geldt eveneens voor elk gebruik door een minderjarig
          kind van KABAS en bijbehorende applicaties voor pc, mac en tablets. In geval van enige vorm van schade
          berokkend door het gebruik van Kabas door een minderjarig kind zal Die Keure gerechtigd zijn de ouders (of
          bevoegde meerderjarigen) die toegestemd hebben met de registratie van het kind in kwestie in rechte aan te
          spreken.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 6 - Privacy </h2>
        <p>
          Gelet op de evolutie van de privacywetgeving behoudt Die Keure zich het recht voor haar Privacyverklaring op
          ieder moment te wijzigen. Bijgevolg raadt Die Keure de gebruiker aan regelmatig op de Website na te gaan of er
          wijzigingen zijn doorgevoerd in de Privacyverklaring.
        </p>
        <p>
          Net zoals deze Algemene Gebruiksvoorwaarden zal ook de boven vermelde Privacyverklaring dienen aanvaard te
          worden door de gebruikers van KABAS en bijbehorende applicaties voor pc, mac en tablets alvorens via hun
          persoonlijke account ten volle toegang te krijgen tot het Platform.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 7 - Afstand </h2>
        <p>
          Het ontbreken van het afdwingen door Die Keure van enige bepaling uit deze Algemene Gebruiksvoorwaarden, of
          het niet ondernemen van enige actie ten aanzien van een gebruiker van KABAS en bijbehorende applicaties voor
          pc, mac en tablets in het geval van een mogelijke inbreuk op enige bepaling, kan niet worden geïnterpreteerd
          als een afstand van vordering of recht ten aanzien van enige bepaling in het kader van een mogelijke
          toekomstige inbreuk door een gebruiker.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 8 - Deelbaarheid </h2>
        <p>
          Indien een bepaling van deze Algemene Gebruiksvoorwaarden geheel of gedeeltelijk onwettig, nietig of
          niet-afdwingbaar wordt verklaard onder het toepasselijke recht, zal deze bepaling geen deel meer uitmaken van
          deze Algemene Gebruiksvoorwaarden. De wettigheid, geldigheid en bindende aard van de andere bepalingen van
          deze Algemene Gebruiksvoorwaarden blijven onaangetast.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 9 - Bevoegde rechtbank en toepasselijk recht </h2>
        <p>
          Deze Algemene Gebruiksvoorwaarden worden uitsluitend beheerst door - en geïnterpreteerd in overeenstemming met
          - de Belgische wetgeving.
        </p>
        <p>
          De bevoegde rechtbanken en hoven voor kennisname van betwistingen omtrent deze Algemene Gebruiksvoorwaarden
          zullen de rechtbanken en hoven van het arrondissement Brugge zijn, behoudens andersluidende dwingende
          wettelijke bepalingen.
        </p>
      </div>
    </section>
  </div>
</div>
