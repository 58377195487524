import { trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ClassGroupInterface, StudentCodeLoginCredentials } from '@campus/public-portal';

import { Dictionary } from '@ngrx/entity';

import {
  ENVIRONMENT_API_TOKEN,
  ENVIRONMENT_LOGIN_TOKEN,
  EnvironmentApiInterface,
  EnvironmentLoginInterface,
} from '@campus/environment';
import { AuthAdapterInterface, BaseFormComponent, StateEnum as State } from '@campus/public-portal';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import {
  fadeInAnimation,
  popOutAnimation,
  slideInAnimation,
  wiggleAnimation,
  wiggleLargeAnimation,
} from '../../../animations';
import { STUDENT_CODE_ADAPTER } from '../../../services/auth/student-code.adapter';
import { PersistedUserCredentialsInterface } from '../../../services/persisted-users.service.interface';
@Component({
  selector: 'kabas-login-student',
  templateUrl: './login-student.component.html',
  styleUrls: ['./login-student.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', fadeInAnimation),
    trigger('slideIn', slideInAnimation),
    trigger('wiggle', wiggleAnimation),
    trigger('popOut', popOutAnimation),
    trigger('wiggleLarge', wiggleLargeAnimation),
  ],
})
export class LoginStudentComponent extends BaseFormComponent<StudentCodeLoginCredentials> implements OnInit, OnChanges {
  @Input() classGroups: ClassGroupInterface[];
  @Input() qrScannerVisible: boolean;
  @Input() persistedUsers: PersistedUserCredentialsInterface[];

  @Output() schoolCode = new EventEmitter<number>();
  @Output() showQrScanner = new EventEmitter<boolean>();
  @Output() ssoLoginClicked = new EventEmitter<string | InjectionToken<AuthAdapterInterface>>();

  @HostBinding('class') classList = 'kabas-login-student w-full';
  visibleCode = false;

  showQRLoginButton = this.loginEnvironment.qr;
  showLearningIdLoginButton = this.loginEnvironment.leerId;

  classGroupState: State = State.INITIAL;
  showForm = false;
  quickLoginPerson: PersistedUserCredentialsInterface;
  classGroupDict: Dictionary<ClassGroupInterface>;

  private getClassGroupsForSchool$: Observable<number>;

  // update texts based on the component's state
  public uiState = {
    loginErrors: {
      error: 'Ai, ai. Je vulde iets niet juist in.<br>Probeer eens opnieuw.', // api error
      person_with_leerid_not_found:
        'Ai, ai. Je leerID kennen we nog niet. Vraag aan je leerkracht hoe je moet aanmelden.',
    },
    success: 'We brengen je zo dadelijk naar het platform...',
    classGroups: {
      initial: ' Vul eerst je schoolcode in.',
      processing: 'Klassen aan het ophalen ...',
      success: 'Kies een klas',
      error: 'Geen klassen gevonden voor deze school',
    },
  };
  type: string;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(ENVIRONMENT_LOGIN_TOKEN)
    private loginEnvironment: EnvironmentLoginInterface,
    @Inject(ENVIRONMENT_API_TOKEN)
    private environmentApi: EnvironmentApiInterface
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes); // trigger manually because life cycle hooks are not triggered in base component
    const { classGroups, persistedUsers } = changes;
    if (persistedUsers) {
      this.showForm = !persistedUsers.currentValue.length || this.state === 'processing' || this.state === 'success';
    }

    if (classGroups && classGroups.currentValue) {
      this.classGroupDict = classGroups.currentValue.reduce(
        (acc: Dictionary<ClassGroupInterface>, cg: ClassGroupInterface) => ({ ...acc, [cg.id]: cg }),
        {}
      );
    }

    if (classGroups && !classGroups.firstChange && classGroups.currentValue !== null) {
      this.classGroupState = classGroups.currentValue.length ? State.SUCCESS : State.ERROR;
    }
  }

  ngOnInit() {
    this.buildForm();
    super.ngOnInit(); // trigger manually because life cycle hooks are not triggered in base component

    this.getClassGroupsForSchool$ = this.form.get('schoolCode').valueChanges.pipe(
      takeUntil(this.destroy$), // complete stream
      debounceTime(500),
      distinctUntilChanged(),
      tap((schoolCode) => {
        this.classGroupState = State.INITIAL;
        if (!this.quickLoginPerson) {
          this.form.get('classGroupId').reset();
        }
        this.classGroups = [];
      }),
      filter((schoolCode) => !!schoolCode && schoolCode.toString().length >= 4)
    );

    this.getClassGroupsForSchool$.subscribe((schoolCode) => {
      this.classGroupState = State.PROCESSING;
      this.schoolCode.emit(schoolCode);
    });
  }

  trackById(index: number, classGroup: ClassGroupInterface): number {
    return classGroup.id;
  }

  quickLogin(person: PersistedUserCredentialsInterface) {
    this.form.patchValue({
      schoolCode: person.schoolCode,
      classGroupId: person.classGroupId,
      classNumber: person.classNumber,
    });
    this.quickLoginPerson = person;
    this.showForm = true;
  }

  notMeClicked() {
    this.showForm = false;
    this.quickLoginPerson = undefined;
    this.form.reset();
  }

  changedClicked() {
    this.quickLoginPerson = undefined;
  }

  public makeFormVisible() {
    this.showForm = true;
    this.showQrScanner.emit(false);
  }

  private buildForm() {
    this.form = this.fb.group({
      schoolCode: ['', [Validators.required, Validators.min(1000)]],
      classGroupId: [null, Validators.required],
      classNumber: ['', Validators.required],
      childCode: ['', Validators.required],
    });
  }

  public submit() {
    return super.submit(STUDENT_CODE_ADAPTER);
  }

  public ssoLogin(adapter: string | InjectionToken<AuthAdapterInterface>) {
    this.ssoLoginClicked.emit(adapter);
  }
}
