<campus-portal-lib-header
  [links]="headerLinks"
  [userActions]="headerActions$ | async"
  (linkClicked)="navigateTo($event)"
>
</campus-portal-lib-header>

<ng-container *ngIf="loginForm$ | async">
  <kabas-modal (close)="closeLoginForm($event)">
    <kabas-modal-content>
      <kabas-login-form
        [role]="role$ | async"
        (formSubmitted)="login($event)"
        (schoolCode)="schoolCodeSet($event)"
        [success]="loginSucceeded$ | async"
        (toggleQrScanner)="toggleQrScanner($event)"
        [qrScanner]="showQrScanner$ | async"
        (resetPasswordEvent)="resetPassword($event)"
      >
      </kabas-login-form>
    </kabas-modal-content>
  </kabas-modal>
</ng-container>

<router-outlet></router-outlet>
<footer class="kabas-footer">
  <svg preserveAspectRatio="none" viewBox="0 0 1200 300" class="kabas-footer__wave">
    <defs>
      <linearGradient id="footer-wave-gradient" x1="600" y1="300" x2="600" gradientUnits="userSpaceOnUse">
        <stop offset="0" class="start" />
        <stop offset="1" class="stop" />
      </linearGradient>
    </defs>
    <path d="M0,43.72V300H1200V144.27S368.72-96.14,0,43.72Z" style="fill: url(#footer-wave-gradient)" />
  </svg>

  <div class="kabas-content-container kabas-footer__container">
    <span class="kabas-footer__item"
      >Kabas is een product van
      <a class="kabas-button--inline" href="https://www.diekeure.be/nl-be/educatief" target="_blank">die Keure</a></span
    >

    <a (click)="openCookieConsent()" class="kabas-button--inline kabas-footer__item">Cookievoorkeuren</a>

    <a class="kabas-button--inline kabas-footer__item" routerLink="/legals/gebruiksvoorwaarden">Gebruiksvoorwaarden</a>

    <a class="kabas-button--inline kabas-footer__item" routerLink="/legals/privacy">Privacy</a>
    <a class="kabas-button--inline kabas-footer__item" routerLink="/legals/verkoopsvoorwaarden">Verkoopsvoorwaarden</a>

    <div class="kabas-footer__social">
      <a href="https://nieuwsbriefmodule.diekeure.be" target="_blank" class="kabas-button--inline kabas-footer__item"
        ><svg><use xlink:href="#mail" /></svg
      ></a>
      <a
        href="https://www.facebook.com/DieKeureEducatief"
        target="_blank"
        class="kabas-button--inline kabas-footer__item"
        ><svg><use xlink:href="#facebook" /></svg
      ></a>
      <a href="https://www.pinterest.com/diekeure" target="_blank" class="kabas-button--inline kabas-footer__item"
        ><svg><use xlink:href="#pinterest" /></svg
      ></a>
      <a
        href="https://www.instagram.com/diekeureeducatief"
        target="_blank"
        class="kabas-button--inline kabas-footer__item"
        ><svg><use xlink:href="#instagram" /></svg
      ></a>
    </div>
  </div>
</footer>
<campus-cookies-content
  *ngIf="cookieInputs$ | async as cookieInputs"
  [linkcookies]="cookieInputs.link"
  [linkCookiesLabel]="'in onze privacy voorwaarden'"
  [setVisible]="cookieInputs.visibility"
  [errormessage]="cookieInputs.errormessage"
  (close)="closeCookieConsent()"
></campus-cookies-content>
