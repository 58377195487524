export const svgs = {
  logo: 'assets/svgs/logo.svg',
  'logo-small': 'assets/svgs/logo_small.svg',

  'dashboard-hero': 'assets/svgs/dashboard_hero.svg',
  'hero-waves': 'assets/svgs/hero_waves.svg',
  'camera-permission': 'assets/icons/outlined/camera-permission.svg',
  'contact-form': 'assets/svgs/contact_form.svg',
  'contact-success': 'assets/svgs/contact_success.svg',
  'contact-error': 'assets/svgs/contact_error.svg',

  'teacher-login': 'assets/svgs/teacher_login.svg',
  'student-login': 'assets/svgs/student_login.svg',
  'arrow-drop-down': 'assets/icons/outlined/arrow_drop_down.svg',
};
