import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@campus/browser';
import jsQR from 'jsqr';

@Component({
  selector: 'campus-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private window = inject(WINDOW);
  private cdRef = inject(ChangeDetectorRef);

  @Input() public showOverlay = false;
  @Input() public showCameraSelect = false;
  @Input() public facingMode: 'environment' | 'user' = 'environment';
  @Input() public scanInterval = 500;
  @Input() public isScannerEnabled = false;
  @Input() public selectedDeviceId: string;

  @Output() public isLoadingChange = new EventEmitter<boolean>();
  @Output() public cameraDevicesReceived = new EventEmitter<MediaDeviceInfo[]>();
  @Output() public scanResultReceived = new EventEmitter<string>();

  @HostBinding('class') public defaultClasses = ['flex', 'flex-column', 'justify-center', 'h-full', 'w-full'];

  public isLoading = true;
  public cameraDevices: MediaDeviceInfo[] = [];
  public cameraError?: string;
  public scanResult: string;
  public mirrorCamera = false;

  private scannerInterval: number;
  private currentStream: MediaStream;

  @ViewChild('camera', { static: false })
  camera: ElementRef<HTMLVideoElement>;

  ngOnInit(): void {
    this.isLoadingChange.emit(this.isLoading);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scanInterval || changes.isScannerEnabled) {
      this.startScanning();
    }
  }

  ngOnDestroy(): void {
    this.clearVideoStream();
  }

  async ngAfterViewInit(): Promise<void> {
    //this will ask for permissions and start the camera
    await this.setDefaultCamera();
    await this.setCameraList();
    //calculate mirror when we have the camera list
    this.mirrorCamera = await this.getNeedsMirror();

    this.isLoading = false;
    this.isLoadingChange.emit(this.isLoading);

    this.startScanning();

    this.cdRef.markForCheck();
  }

  public async onDeviceSelectChange(selected) {
    const deviceId: string = selected.target.value || '';
    //reset error here since it can ask for permission again
    // also be able to choose different device if its in use
    this.cameraError = undefined;
    this.cdRef.detectChanges();
    await this.setCamera(deviceId);
  }

  private async setDefaultCamera() {
    try {
      await this.setStream({
        facingMode: { ideal: this.facingMode },
      });
      if (!this.currentStream) return;
      const tracks = this.currentStream.getVideoTracks();
      const trackConfigs = tracks.map((track) => track.getSettings());
      const deviceId = trackConfigs[0].deviceId;
      //we don't want to end the stream here so no call to the setCamera method
      if (this.selectedDeviceId === deviceId) return;
      this.selectedDeviceId = deviceId;
      this.updateVideoStream();
      this.startScanning();
    } catch (err) {
      console.error(err.name);
      this.setCameraError(err.name);
    }
  }

  private async setCameraList(): Promise<void> {
    const devices = await this.window.navigator.mediaDevices.enumerateDevices();

    this.cameraDevices = devices.filter((device) => device.kind === 'videoinput');
    this.cameraDevicesReceived.emit(this.cameraDevices);
  }

  private async setCamera(deviceId: string) {
    if (this.selectedDeviceId === deviceId) {
      return;
    }
    this.clearVideoStream();
    this.selectedDeviceId = deviceId;
    await this.setStream({ deviceId: { exact: deviceId } });
    await this.updateVideoStream();
    this.cdRef.markForCheck();
  }

  private setCameraError(name: string) {
    switch (name) {
      case 'NotAllowedError':
        this.showCameraSelect = false;
        this.cameraError =
          'We hebben geen toegang tot de camera, gelieve rechten te geven om de camera te gebruiken en de pagina te herladen!';
        break;
      case 'NotFoundError':
      case 'OverconstrainedError':
        this.cameraError =
          'Er is geen camera gevonden op dit toestel, gelieve een camera aan te sluiten en de pagina te herladen!';
        break;
      case 'AbortError':
        this.cameraError =
          'De camera is momenteel in gebruik door een andere app, gelieve je andere apps of tabbladen te sluiten en de pagina te herladen!';
        break;
      default:
        this.cameraError = 'Er is een onbekende fout opgetreden, gelieve de pagina te herladen!';
    }
    this.cdRef.markForCheck();
  }

  private clearVideoStream() {
    this.stopScanning();
    if (this.currentStream) {
      this.camera.nativeElement.srcObject = null;
      this.currentStream.getVideoTracks().forEach((track) => {
        track.stop();
        this.currentStream.removeTrack(track);
      });
    }
    this.currentStream = undefined;
  }

  private async updateVideoStream() {
    this.mirrorCamera = await this.getNeedsMirror();
    this.camera.nativeElement.srcObject = this.currentStream;
    await this.camera.nativeElement.play();
  }

  private getCapabilities(device: MediaDeviceInfo): MediaTrackCapabilities {
    //the fastest way to get the capabilities if supported
    if (typeof device['getCapabilities'] === 'function') {
      return device['getCapabilities']();
    }

    if (!this.currentStream) return;
    //fallback for IOS devices
    const tracks = this.currentStream.getVideoTracks();
    const track = tracks[0];
    if (track && track.getCapabilities) return track.getCapabilities();
  }

  private async getNeedsMirror(): Promise<boolean> {
    const device = this.cameraDevices.find(({ deviceId }) => deviceId === this.selectedDeviceId);
    if (!device) return false;

    const capabilities = this.getCapabilities(device);
    if (capabilities) {
      const facingModesSet = new Set(capabilities.facingMode);
      return facingModesSet.has('user') || facingModesSet.size === 0;
    }
    // the normal camera list would be F-B or F-F-B-B or F-F-B or F-B-B, where F is front and B is back so this estimate would be closest
    // fallback for firefox
    return Math.floor(this.cameraDevices.length / 2) > this.cameraDevices.indexOf(device);
  }

  private async setStream(videoConstraints: Partial<MediaTrackConstraints> = {}): Promise<MediaStream> {
    const constraints: MediaStreamConstraints = {
      video: videoConstraints,
      audio: false,
    };

    try {
      this.currentStream = await this.window.navigator.mediaDevices.getUserMedia(constraints);
      return this.currentStream;
    } catch (err) {
      //waiting for https://bugzilla.mozilla.org/show_bug.cgi?id=1863542
      this.setCameraError(err.name);
    }
  }

  private startScanning() {
    this.stopScanning();
    if (!this.isScannerEnabled) return;

    this.scannerInterval = setInterval(() => this.scan(), this.scanInterval) as any;
  }

  private stopScanning() {
    if (this.scannerInterval) {
      clearInterval(this.scannerInterval);
      this.scannerInterval = null;
    }
  }

  private scan() {
    if (!this.camera) return;

    const { videoWidth: width, videoHeight: height } = this.camera.nativeElement;
    if (!width || !height) return;

    const canvasElement = document.createElement('canvas');
    canvasElement.width = width;
    canvasElement.height = height;

    const canvasContext = canvasElement.getContext('2d');
    canvasContext.drawImage(this.camera.nativeElement, 0, 0, width, height);
    const imageData = canvasContext.getImageData(0, 0, width, height);

    const code = jsQR(imageData.data, imageData.width, imageData.height);
    const data = code?.data || null;

    if (this.scanResult !== data) {
      this.scanResult = data;
      if (data) this.scanResultReceived.emit(data);
      this.cdRef.markForCheck();
    }
  }
}
