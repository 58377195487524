<campus-hero-section
  [user]="user$ | async"
  [inMaintenanceMode]="isMaintenanceMode$ | async"
  (goToPlatform)="goToPlatform()"
  (logout)="logoutClicked()"
  data-cy="hero-section"
>
  <ng-container operationalMode>
    <div class="[ flex gap-xl flex-wrap justify-center ]">
      <button
        filled-button
        class="[ bg-opacity-full ][ transform scale-110 ]"
        (click)="openLoginForm('student')"
        data-cy="btn-login-link-student"
      >
        Ik ben een leerling
        <campus-svg
          button-icon
          svgImage="student-login"
          class="[ absolute ][ inset-1/2 transform -translate-x-3/5 ]"
          style="width: 92px; height: 92px; inset-block-start: -58px; left: 10px"
        ></campus-svg>
      </button>

      <div>
        <div class="[ stack stack-space-xs items-center ]">
          <button
            filled-button
            class="[ bg-opacity-full ][ transform scale-110 ]"
            (click)="openLoginForm('teacher')"
            data-cy="btn-login-link-teacher"
          >
            Ik ben een leerkracht
            <campus-svg
              button-icon
              svgImage="teacher-login"
              class="[ absolute ][ inset-1/2 transform translate-x-1/4 ]"
              style="width: 92px; height: 92px; inset-block-start: -58px; right: 10px"
            ></campus-svg>
          </button>

          <button
            inline-button
            class="[ bg-opacity-full ]"
            (click)="openLoginForm('teacher')"
            data-cy="btn-login-link-admin"
          >
            Ik ben een schoolbeheerder
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container maintenanceMode>
    <campus-maintenance-mode [statusPageUrl]="statusPageUrl"></campus-maintenance-mode>
  </ng-container>

  <ng-container hero>
    <campus-svg
      svgImage="hero-waves"
      class="[ absolute w-full ][ inset-i-0 inset-be-0 ][ index-pullback ]"
    ></campus-svg>
    <campus-svg
      svgImage="dashboard-hero"
      class="[ absolute h-screen w-1/2 max-w-full max-h-full min-w-xl ][ inset-ie-0 inset-is-m inset-b-1/2 transform -translate-y-1/2 tablet-l:inset-ie-0 tablet-l:inset-is-auto ][ index-pullback ]"
    ></campus-svg>
  </ng-container>
</campus-hero-section>

<ng-container *ngIf="showModal">
  <kabas-modal *ngIf="role === 'teacher' || role === 'schooladmin'" (close)="closeModal($event)"
    ><kabas-modal-content>
      <kabas-login>
        <kabas-login-form>
          <kabas-login-teacher
            [success]="loginSuccess$ | async"
            (submitForm)="login($event)"
            (forgotPassword)="resetPassword($event)"
            (afterSuccess)="goToPlatform()"
          ></kabas-login-teacher>
        </kabas-login-form>
      </kabas-login>
    </kabas-modal-content>
  </kabas-modal>

  <kabas-modal *ngIf="role === 'student'" (close)="closeModal($event)">
    <kabas-modal-content>
      <kabas-login [showQrScanner]="qrScanner">
        <kabas-login-form>
          <kabas-login-student
            [success]="loginSuccess$ | async"
            [qrScannerVisible]="qrScanner"
            [classGroups]="classGroups$ | async"
            (schoolCode)="schoolCodeSet($event)"
            (submitForm)="loginStudent($event)"
            (afterSuccess)="goToPlatform()"
            (forgetPerson)="forgetPerson($event)"
            [persistedUsers]="persistedUsers$ | async"
            [error]="(route.queryParams | async)?.err"
            (showQrScanner)="showQrScanner($event)"
          ></kabas-login-student>
        </kabas-login-form>
      </kabas-login>
    </kabas-modal-content>
  </kabas-modal>
</ng-container>

<div class="[ stack stack-space-3xl ][ p-b-3xl p-i-xs ]">
  <campus-about-section
    class="[ w-full max-w-4xl m-i-auto ]"
    id="wat-is-kabas"
    [title]="'Wat is Kabas?'"
    readMoreLink="https://www.diekeure.be/nl-be/educatief/lager-onderwijs/kabas"
    videoId="kj689qkytq"
    player="wistia"
  >
    <p class="[ body-large ]"> Kabas maakt blended learning mogelijk, het bevat al het nodige methodemateriaal om: </p>
    <ul>
      <li> als leerkracht de leerstof te onderwijzen en de voortgang van je leerlingen te monitoren. </li>
      <li> als leerling de leerstof te ontdekken, in te oefenen en toe te passen in een nieuwe context. </li>
    </ul>
    <p class="[ body-large ]">
      Kortom: Kabas is je digitale toolbox, waarmee je op avontuur gaat met je leerlingen. In dit korte animatiefilmpje
      ontdek je alle voordelen van Kabas.
    </p>
  </campus-about-section>

  <campus-faq-section
    id="veelgestelde-vragen"
    class="[ w-full max-w-4xl m-i-auto ]"
    [faqCategories]="faqCategories"
    (faqCategoryClick)="clickedFaqCategory($event)"
  ></campus-faq-section>

  <campus-contact-section
    id="contact"
    class="[ w-full max-w-4xl m-i-auto ]"
    [user]="user$ | async"
    [success]="contactSuccess$ | async"
    [subjects]="subjects"
    (resetForm)="resetContactForm()"
    (submitForm)="contactFormSubmitted($event)"
  ></campus-contact-section>
</div>
