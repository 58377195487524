import { Component, HostBinding, HostListener, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { COOKIE_INPUT_TOKEN, CookieInputInterface } from '@campus/cookies';

import {
  AuthFacade,
  ClassGroupInterface,
  FormFacade,
  HeaderActionInterface,
  LoginCredentials,
  PersonInterface,
  UserType,
} from '@campus/public-portal';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppViewModel } from './app.viewmodel';
import { PersistedUsersFacade } from './facades/persisted-users.facade';
import { PersistedUserCredentialsInterface } from './services/persisted-users.service.interface';

@Component({
  selector: 'kabas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class')
  defaultClasses = ['max-w-screen', 'overflow-x-clip'];

  user$: Observable<PersonInterface>;
  role$: Observable<string>;
  public classGroups$: Observable<ClassGroupInterface[]>;
  dropped = false;
  cookieInputs$: Observable<CookieInputInterface>;
  isHeaderPinned: boolean;
  isHomeRoute$: Observable<boolean>;
  loginSucceeded$: Observable<boolean>;
  showQrScanner$ = new BehaviorSubject(false);
  loginForm$: Observable<boolean>;
  public persistedUsers$: Observable<PersistedUserCredentialsInterface[]>;

  private auth = inject(AuthFacade);
  private form = inject(FormFacade);
  private persistedUsers = inject(PersistedUsersFacade);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private appViewModel = inject(AppViewModel);
  private cookieInputService = inject(COOKIE_INPUT_TOKEN);

  private isMaintenanceMode$: Observable<boolean>;

  public headerLinks = [
    { label: 'Wat is Kabas?', route: { url: '', goToId: 'wat-is-kabas' } },
    { label: 'Veelgestelde vragen', route: { url: '', goToId: 'veelgestelde-vragen' } },
    { label: 'Contact', route: { url: '', goToId: 'contact' } },
  ];

  public headerActions$: Observable<HeaderActionInterface>;

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.appViewModel.checkUserValidity();
  }

  ngOnInit(): void {
    this.user$ = this.auth.user$;
    this.role$ = this.auth.role$;
    this.cookieInputs$ = this.cookieInputService.cookieInputs$;
    this.loginForm$ = this.form.loginForm$;
    this.loginSucceeded$ = this.auth.isAuthenticated$;
    this.isHomeRoute$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => this.router.isActive('/', true))
    );
    this.persistedUsers$ = this.persistedUsers.getAll().asObservable();

    this.route.queryParams
      .pipe(
        filter((queryParams) => !!queryParams?.err),
        take(1)
      )
      .subscribe(({ err }: { err: string }) => {
        if (err === 'person_with_leerid_not_found') {
          this.logout();
          this.openLoginForm('student');
        }
      });
    this.isMaintenanceMode$ = this.appViewModel.isMaintenanceMode$;
    this.headerActions$ = this.setHeaderActions$();
  }

  toggleMenu() {
    this.dropped = !this.dropped;
  }

  closeMenu() {
    this.dropped = false;
  }

  goToPlatform() {
    this.appViewModel.redirectToPlatform();
  }

  logout() {
    this.auth.logout();
  }

  login(credentials: LoginCredentials) {
    this.auth.login(credentials);
  }

  openCookieConsent() {
    this.cookieInputService.setCookieInputs({
      visibility: true,
    });
  }

  closeCookieConsent() {
    this.cookieInputService.setCookieInputs({ visibility: false });
  }

  closeLoginForm() {
    this.form.closeLoginForm();
  }

  openLoginForm(userType: UserType) {
    this.form.openLoginForm(userType);
  }

  toggleQrScanner(event) {
    this.showQrScanner$.next(event);
  }

  resetPassword(email: string) {
    this.loginSucceeded$ = this.auth.resetPassword(email);
  }

  private setHeaderActions$() {
    return combineLatest([this.user$, this.isMaintenanceMode$]).pipe(
      map(([user, isMaintenanceMode]) => {
        if (isMaintenanceMode) {
          return null;
        } else if (user) {
          return {
            label: user.displayName,
            children: [
              {
                label: 'Naar het platform',
                icon: 'logo-letter',
                action: () => this.goToPlatform(),
              },
              {
                label: 'Afmelden',
                icon: 'lock',
                action: () => this.logout(),
              },
            ],
          };
        } else {
          return {
            label: 'Aanmelden',
            children: [
              {
                label: 'Ik ben een leerling',
                action: () => this.openLoginForm('student'),
              },
              {
                label: 'Ik ben een leerkracht',
                action: () => this.openLoginForm('teacher'),
              },
              {
                label: 'Ik ben een schoolbeheerder',
                action: () => this.openLoginForm('teacher'),
              },
            ],
          };
        }
      })
    );
  }

  public navigateTo(route: { url: string; goToId?: string }) {
    this.router.navigate([route.url], { fragment: route.goToId });
  }
}
