import { EnvironmentInterface } from '@campus/environment';
import { E2eModule } from '../app/e2e/e2e.module';
import { icons } from './icons';
import { svgs } from './svgs';

export const environment: Partial<EnvironmentInterface> = {
  production: true,
  enableDevTools: true,
  svgMapping: svgs,
  iconMapping: icons,
  api: {
    APIBase: 'https://api.staging.kabas.be',
    authBase: 'https://api.staging.kabas.be/auth',
  },

  login: {
    teacherAppUrl: 'https://api.staging.kabas.be/app/platform',
    studentAppUrl: 'https://api.staging.kabas.be/student',
    schooladminAppUrl: 'https://api.staging.kabas.be/app/platform',
    leerId: true,
    qr: true,
  },
  assets: {
    images: {
      faq: 'https://www.staging.kabas.be/assets/images/faq',
      illustrations: 'https://www.staging.kabas.be/assets/images/illustrations',
      ea: 'https://www.staging.kabas.be/assets/images/ea',
      news: 'https://avatar.staging.kabas.be/',
    },
  },
  recaptcha: {
    key: '6LcBpPAaAAAAACAV7e6zvf8FeFU5wexkAsIdWKfy',
  },
  googleAnalytics: {
    measurementId: '',
    requireConsent: true,
  },
  afterLogout: {
    url: '/student/login',
    logOutLeerIdUrl: 'https://authenticatie-ti.vlaanderen.be/op/v1/logout',
  },
  website: {
    url: 'https://staging.kabas.be',
    statusPageUrl: 'https://status.kabas.be',
  },
  testing: {
    removeDataCyAttributes: false,
  },
  envModules: [E2eModule],
};
