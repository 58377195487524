<h1>Verkoopsvoorwaarden</h1>
<div>
  <div class="[ stack stack-space-2xl ]">
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 1 - Toepasselijkheid </h2>
        <h3>1.1</h3>
        <p>
          De rechtsverhouding tussen die Keure NV - met maatschappelijke naam: Drukkerij-Uitgeverij die Keure -
          Imprimerie-Editions La Charte - en maatschappelijke zetel te Kleine Pathoekeweg 3, 8000 Brugge, België,
          ingeschreven in de Kruispuntbank van Ondernemingen onder het nummer 0405.108.325 (“die Keure”) en een school
          of onderwijsinstelling die een aankoop wenst te verrichten (“de Koper”) via de website www.kabas.be (“KABAS”),
          wordt uitsluitend beheerst door de onderhavige algemene verkoopsvoorwaarden (hierna de ‘Algemene
          Verkoopsvoorwaarden’) en gelden als overeenkomst tussen die Keure en de Koper. Alle andere of tegengestelde
          voorwaarden (eventueel van de Koper zélf en zelfs indien dergelijke voorwaarden dit zouden vermelden) of
          aanpassingen van onderhavige Algemene Verkoopsvoorwaarden zijn slechts van toepassing wanneer deze
          schriftelijk door die Keure zijn goedgekeurd en/of door die Keure uitdrukkelijk anders zouden worden vermeld
          in bijzondere voorwaarden.
        </p>

        <h3>1.2</h3>
        <p>
          Indien u vragen of klachten heeft met betrekking tot KABAS, de aangeboden producten op KABAS of de onderhavige
          Algemene Verkoopsvoorwaarden, gelieve dan contact op te nemen met die Keure’s klantendienst:</p
        >
        <ul class="[ title-small ]">
          <li>per e-mail op het volgende adres: kabas@diekeure.be</li>

          <li
            >telefonisch naar het volgend nummer: +32(0)50471272. Van maandag tot donderdag van 8.00 u. tot 16.30 u. en
            op vrijdag van 8.00 u. tot 14.30 u. of per fax op het volgende nummer: +32(0)50343768;</li
          >

          <li>door het zenden van een brief aan volgend adres: die Keure NV, Kleine Pathoekeweg 3, 8000 Brugge;</li>
        </ul>

        <h3>1.3</h3>
        <p>
          Door het plaatsen van een bestelling op KABAS bevestigt de Koper uitdrukkelijk de onderhavige Algemene
          Verkoopsvoorwaarden te hebben gelezen, goedgekeurd en aanvaard. De Koper vinkt hiertoe het voorziene Vakje met
          vermelding
          <i>“Ik ga akkoord met de algemene verkoopsvoorwaarden”</i> aan.
        </p>
        <h3>1.4</h3>
        <p>
          Die Keure behoudt zich het recht voor deze Algemene Verkoopsvoorwaarden ten allen tijde aan te passen. Telkens
          de Koper producten wenst te bestellen via KABAS, dient de Koper deze nieuwe algemene voorwaarden na te kijken
          om zich ervan te verzekeren dat hij/zij de nieuwe algemene verkoopsvoorwaarden begrijpt die van toepassing
          zijn op het moment dat de Koper de overeenkomst aangaat met die Keure.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2> Artikel 2 - Voorwerp en sluiting van de overeenkomst </h2>
        <h3>2.1</h3>
        <p>
          Die Keure biedt via KABAS producten aan onder de vorm een licentie, zijnde een toegang tot de inhoud van een
          methode-leerjaar (hierna ook afzonderlijk een “Product” of samen de “Producten”).
        </p>
        <p>
          Onderhavige Algemene Verkoopsvoorwaarden hebben tot doel de modaliteiten aangaande de verkoop via KABAS tussen
          die Keure en de Koper te bepalen. Onder andere met betrekking tot de bestelling, de betaling, de toegang en
          herroeping.
        </p>

        <h3>2.2</h3>
        <p>
          Tenzij anders is vermeld, gelden de aanbiedingen op KABAS slechts ten informatieve titel. Deze aanbiedingen
          zijn derhalve ook steeds vrijblijvend.
        </p>
        <h3>2.3</h3>
        <p>
          Licenties bestel je op KABAS of via de webshop van die Keure. Om een bestelling te plaatsen dient de Koper
          zich eerst aan te melden als schoolbeheerder. Via de productcataloog selecteert de Koper de Producten van zijn
          keuze en voegt deze Producten toe aan zijn “Winkelmandje”.
        </p>
        <p>
          Vervolgens krijgt de Koper een overzicht van de inhoud van zijn of haar winkelmandje en kan de Koper de
          geplaatste bestelling afronden door te drukken op de knop “Bestellen”. De totaalprijs welke getoond wordt in
          het winkelmandje is indicatief en onderhevig aan het aantal geregistreerde leerlingen op dat moment in KABAS
          voor elke leerjaar. De totaalprijs wordt definitief bepaald op 30 september.<br />
          De betalingsmodaliteiten zijn in artikel 4 - “Prijs en betaling” opgenomen.
        </p>
        <p
          >Bij de schooljaarovergang worden de licenties automatisch verlengd voor het volgend schooljaar tenzij deze
          automatische verlenging is uitgeschakeld.
        </p>
        <h3>2.4</h3>
        <p>
          Elke overgemaakte bestelling is in zijn geheel bindend voor de Koper, maar die Keure is slechts gebonden vanaf
          de verzending van een orderbevestiging per e-mail. Wij raden u aan deze e-mail af te drukken en op te slaan.
          die Keure behoudt zich het recht voor elke bestelling te weigeren. Een bestelling wordt geacht te zijn
          verwerkt en goedgekeurd door die Keure vanaf het moment dat de Koper bevestiging heeft ontvangen van die Keure
          via e-mail. Na verzending van de e-mail komt een overeenkomst ("Overeenkomst") tussen die Keure en de Koper
          tot stand.
        </p>
        <h3> 2.5 </h3>
        <p>
          Eventuele of beweerde onjuistheden in deze orderbevestiging moeten door de Koper op straffe van verval binnen
          de
          <b>8 dagen vanaf de verzendingsdatum van deze orderbevestiging schriftelijk kenbaar gemaakt worden.</b>
        </p>

        <h3> 2.6 </h3>
        <p>
          Meer informatie omtrent het plaatsen en afronden van bestellingen via KABAS vindt de Koper terug onder de
          “FAQ” op www.kabas.be.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2> Artikel 3 - Herroepingsrecht </h2>
        <h3>3.1</h3>
        <p>
          De Koper heeft het recht om
          <b>binnen een termijn van 14 dagen</b> zonder opgave van redenen de Overeenkomst te herroepen. De
          herroepingstermijn verstrijkt 14 dagen na de dag volgend op de dag van sluiting van de Overeenkomst.
        </p>
        <h3>3.2</h3>
        <p>
          Om het herroepingsrecht uit te oefenen, moet de Koper die Keure’s Klantendienst via een ondubbelzinnige
          verklaring (bv. schriftelijk per post of per e-mail) op de hoogte stellen van zijn/haar beslissing de
          overeenkomst te herroepen. Dit kan eveneens door gebruik te maken van het in bijlage 2 van het boek VI van het
          Wetboek Economisch recht voorziene Modelformulier. Tenslotte kan dit ook via het contactformulier op de
          Website.
        </p>
        <p>
          Om de herroepingstermijn na te leven volstaat het voor de Koper om zijn/haar mededeling betreffende de
          uitoefening van het herroepingsrecht te verzenden voordat de herroepingstermijn is verstreken.
        </p>

        <p>
          De Koper dient die Keure’s
          <b>Klantendienst via e-mail op <a href="mailto:info@diekeure.be">info@diekeure.be</a></b>
          op de hoogte te stellen van het feit dat hij/zij de Producten wenst te annuleren. Een
          <b>terugbetaling</b> zal worden toegekend.
        </p>

        <h3>3.3</h3>
        <p>
          Als de Koper de Overeenkomst herroept, ontvangt hij/zij alle betalingen die hij/zij tot op dat moment heeft
          gedaan, onverwijld en in ieder geval niet later dan 14 dagen nadat die Keure op de hoogte is gesteld van de
          beslissing van de Koper om de Overeenkomst te herroepen, van die Keure terug. Die Keure betaalt de Koper terug
          met hetzelfde betaalmiddel als waarmee de Koper de oorspronkelijke transactie heeft verricht, tenzij de Koper
          uitdrukkelijk anderszins heeft ingestemd. Er zullen de Koper geen kosten in rekening worden gebracht in
          verband met de terugbetaling, tenzij deze zouden gepaard gaan met een ander betaalmiddel dat door de Koper is
          voorgesteld en waarmee die Keure heeft ingestemd.
        </p>

        <h3>3.4</h3>
        <p>
          De Koper kan zich, naast de gevallen opgesomd in artikel VI. 53 van het Wetboek Economisch Recht, tenslotte
          niet beroepen op zijn/haar herroepingsrecht indien:
        </p>
        <ul class="[ title-small ]">
          <li
            >de geleverde producten zogenaamde ‘digitale uitgaven’ of gelijkaardige digitale producten zijn waarbij de
            Koper, in principe een code ontvangt die het gebruik van een bepaalde online dienst of product mogelijk
            maakt. Dit ongeacht het al dan niet downloaden of gebruik maken van de toegang tot de bestelde online
            dienst;
          </li>

          <li>de geleverde Producten op maat werden gemaakt naar de aanwijzingen en wensen van de Koper; </li>
          <li>de geleverde Producten van persoonlijke aard zijn; </li>
        </ul>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 4 - Prijs en betaling</h2>

        <h3>4.1</h3>
        <p>
          De prijs van de Producten wordt aangegeven in euro (EUR) en zijn inclusief BTW tenzij anders vermeld. De van
          kracht zijnde prijzen zijn diegene die vermeld worden op de Website op de dag van de effectieve en geldige
          bestelling door de Koper.
        </p>
        <h3> 4.2 </h3>
        <p>
          De Koper zal de mogelijkheid hebben om na ontvangst van een factuur over te gaan tot betaling van de prijs
          binnen [30] dagen na ontvangst van de factuur en voorts overeenkomstig alle andere voorwaarden opgenomen in
          deze Algemene Verkoopsvoorwaarden die integraal van toepassing blijven op dergelijke factuur.<br />
          Die Keure zal een administratie kost aanrekenen van €10 in geval de Koper foute factuurgegevens heeft
          meegedeeld.
        </p>

        <h3>4.3</h3>
        <p>
          In geval van niet of niet tijdige betaling van het volledig verschuldigd bedrag, is door de Koper van
          rechtswege en zonder ingebrekestelling een nalatigheidsinterest schuldig op het nog openstaand verschuldigd
          bedrag ten belope van 5% op jaarbasis te rekenen vanaf de datum dat het bedrag verschuldigd is tot op de datum
          van de integrale betaling van alle nog uitstaande sommen, alsook een forfaitair bedrag van 25€ voor de
          administratieve kosten.
        </p>
        <h3>4.4</h3>
        <p>
          Die Keure neemt alle redelijke maatregelen om ervoor te zorgen dat de prijzen van de Producten correct
          weergegeven zijn wanneer zij in het systeem worden opgenomen. De Website bevat echter een groot aantal
          producten en het is mogelijk dat, ondanks die Keure's redelijke inspanningen, een aantal van de Producten op
          de Website van een verkeerde prijs zijn voorzien. Indien die Keure een fout ontdekt in de prijs van de
          Producten die de Koper heeft besteld, zal die Keure de Koper van deze fout op de hoogte brengen en de Koper de
          keuze aanbieden om de aankoop voort te zetten aan de juiste lagere of hogere prijs of de bestelling te
          annuleren. Die Keure zal de bestelling van de Koper niet verwerken totdat Die Keure instructies van de Koper
          ontvangen heeft. Indien die Keure niet in staat is de Koper te contacteren via de contactgegevens die de Koper
          tijdens het bestelproces meedeelde, zal die Keure de bestelling als geannuleerd beschouwen en stelt Die Keure
          de Koper hiervan schriftelijk in kennis. De aansprakelijkheid van die Keure beperkt zich tot het voorgaande
          beschreven in dit artikel 4.4 en de Koper kan derhalve geen recht op schadevergoeding of enige andere
          compensatie laten gelden.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 5 - Intellectuele Eigendom</h2>

        <h3>5.1</h3>
        <p>
          De Koper erkent dat die Keure of één of meerdere van haar verbonden ondernemingen de exclusieve eigenaar
          blijft (blijven) van alle intellectuele eigendomsrechten met betrekking tot alle onderdelen van haar Website,
          haar Producten, het productie- en bestelproces, de chemische samenstelling en de technische specificaties van
          de Producten, alsook enig ander element waarop een intellectueel eigendomsrecht rust.
        </p>
        <p>
          Bovendien blijft die Keure de exclusieve eigenaar van de naam, het merk en het logo waaronder de Producten
          worden verkocht.
        </p>

        <h3> 5.2 </h3>
        <p>
          Zonder afbreuk te doen aan de algemeenheid van hetgeen in artikel 5.1 is bepaald, is het is de Koper  niet
          toegestaan om zonder uitdrukkelijke en schriftelijke toestemming van die Keure, rechtstreeks dan wel via een
          derde, enig onderdeel van de Website, alsook de Producten en/of hun inhoud, gedeeltelijk of volledig
        </p>
        <ul class="[ title-small ]">
          <li>geheel of gedeeltelijk na te bootsen op een identieke of gelijkaardige manier;</li>

          <li>te kopiëren op eender welke drager (op papier of digitaal); </li>

          <li>uit te lenen; </li>

          <li>te vertalen; </li>

          <li>aan te passen; </li>

          <li>te verspreiden; </li>

          <li>mee te delen aan het publiek via om het even welke wijze of media (analoog of digitaal).</li>
        </ul>

        <h3>5.3</h3>
        <p>
          De Koper zal die Keure daarenboven vrijwaren en schadeloos stellen voor elke klacht door een derde ten aanzien
          van die Keure, gebaseerd op een inbreuk op intellectuele eigendomsrechten.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 6 - Waarborgen en aansprakelijkheid</h2>

        <h3>6.1</h3>
        <p>
          Behalve indien uitdrukkelijk anders bepaald in andere artikelen van deze Algemene Verkoopsvoorwaarden zijn die
          Keure’s aansprakelijkheden en waarborgen beperkt tot hetgeen hieronder is beschreven.
        </p>
        <p>
          Die Keure is verantwoordelijk voor verborgen gebreken en gebreken in vakmanschap met betrekking tot de
          gekochte Producten die binnen een termijn van twee (2) jaar vanaf de datum van levering optreden. Indien een
          gebrek tijdens deze periode optreedt, dient die Keure het Product te vervangen of te herstellen, naargelang de
          keuze van de Koper. Om zich op deze garantie te kunnen beroepen, dient de Koper de gebreken aan die Keure per
          aangetekend schrijven te melden binnen een termijn van zestig (60) dagen nadat ze door de Koper zijn ontdekt
          of hadden moeten ontdekt worden en dient hij/zij het defecte product binnen die periode van zestig dagen via
          die Keure’s klantendienst terug te zenden naar het adres vermeld in artikel 1.2 van deze Algemene
          Verkoopsvoorwaarden. Deze garantie geldt enkel voor Producten verworven door een in België gevestigde
          consument. Deze garantie heeft geen invloed op het recht van de Koper om zich op de wettelijke garantie te
          beroepen zoals voorzien in artikel 1649ter van het Burgerlijk Wetboek.
        </p>

        <h3>6.2</h3>
        <p> Die Keure zal niet aansprakelijk zijn ten aanzien van de Koper indien:</p>
        <ul class="[ title-small ]">
          <li>
            deze laatste de Producten niet gebruikt heeft in overeenstemming met het doel waarvoor deze zijn
            bestemd;</li
          >

          <li>het betrokken Product werd aangepast, gewijzigd of hersteld.</li>
        </ul>
        <p>
          Die Keure is niet aansprakelijk voor - of verplicht tot het vergoeden van - immateriële, indirecte of
          gevolgschade, met inbegrip van (maar niet beperkt tot) gederfde winst, verloren inkomsten, beperkingen bij de
          productie, administratieve - of personeelskosten, verlies van klanten of vorderingen van derden.
        </p>

        <h3>6.3</h3>
        <p>
          De beperkingen inzake aansprakelijkheid in de twee bovenstaande paragrafen vinden geen toepassing in het geval
          van bedrog, opzet of grove fout begaan door die Keure of lichamelijke letsels of dood door toedoen van die
          Keure’s nalaten.
        </p>

        <h3>6.4</h3>
        <p>
          Die Keure is niet aansprakelijk voor technische problemen die een impact hebben op de communicatie van
          informatie via haar Website. Die Keure is niet aansprakelijk ten aanzien van de Koper voor enige wijziging,
          onderbreking, defect of beëindiging van haar Website. Noch is die Keure’s aansprakelijk voor websites waarnaar
          op haar Website verwezen wordt.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 7 - Persoonsgegevens</h2>

        <h3>7.1</h3>
        <p>
          Die Keure bewaart en verwerkt de persoonsgegevens van de Koper in overeenstemming met de toepasselijke privacy
          regelgeving, zoals nader omschreven in de <a routerLink="/legals/privacy">Privacy policy</a>.
        </p>

        <h2>Artikel 8 - Overmacht</h2>

        <h3>8.1</h3>
        <p>
          Noch die Keure, noch de Koper is aansprakelijk voor een vertraging of tekortkoming in de uitvoering van de
          Overeenkomst, indien deze te wijten is aan overmacht. Onder overmacht wordt verstaan, zonder dat deze
          opsomming beperkend is: bevel van de overheid, mobilisatie, oorlog, epidemie, lock-out, staking, demonstratie,
          technische defecten in de overdracht van communicatie, brand, overstroming, ontploffing, gebrek aan
          grondstoffen of arbeidskrachten, gewijzigde globale economische omstandigheden, vandalisme, uitzonderlijke
          weersomstandigheden en alle omstandigheden die buiten de controle van die Keure liggen en de normale gang van
          zaken verstoren, zonder dat van die Keure vereist is om het onverwachte karakter van deze omstandigheden aan
          te tonen.
        </p>
        <p>
          De partij die zich beroept op overmacht stelt de andere partij daarvan in kennis en neemt alle redelijke
          maatregelen nemen om de tijdelijke overmachtssituatie te overwinnen.
        </p>

        <h3>8.2</h3>
        <p>
          Indien de overmachtssituatie voor een periode van meer dan negentig (90) dagen duurt, heeft elke partij het
          recht de Overeenkomst te beëindigen zonder dat enige vorm van vergoeding verschuldigd is aan de andere partij.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 9 - Overdracht</h2>
        <p> Die Keure kan de Overeenkomst of enig deel daarvan aan elke persoon, firma of bedrijf overdragen. </p>
        <p>
          De Koper is niet gerechtigd om de Overeenkomst of enig deel daarvan, zonder de voorafgaande schriftelijke
          toestemming van die Keure over te dragen.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 10 - Overige</h2>

        <h3>10.1</h3>
        <p>
          Het loutere stilzwijgen van die Keure in verband met enige van de bepalingen voorzien in deze Algemene
          Verkoopsvoorwaarden (en/of in voorkomend geval bijzondere voorwaarden), kan niet als een afstand van recht
          worden beschouwd.  De afstand van recht is enkel geldig indien zij door een gemachtigde persoon van Die Keure
          schriftelijk wordt bevestigd.
        </p>

        <h3>10.2</h3>
        <p>
          Indien op enig moment enige bepaling van deze Algemene Verkoopsvoorwaarden op welke manier dan ook onwettig,
          ongeldig of niet-afdwingbaar zouden verklaard worden door een rechterlijke instantie of een andere
          gemachtigdeinstantie, onder de toepasselijke wetgeving, zullen de overige bepalingen onverminderd van kracht
          blijven.
        </p>
        <p>
          Die Keure en de Koper zullen alle redelijke inspanningen leveren en alle nodige maatregelen nemen om de
          onwettige, ongeldige of niet-afdwingbare bepaling van deze Algemene Verkoopsvoorwaarden te vervangen door een
          wettige, geldige en afdwingbare bepaling, met in wezen dezelfde economische omvang voor de partijen en die, in
          zoverre wettelijk is toegestaan, de oorspronkelijke bedoeling van partijen bevatten.
        </p>

        <h3>10.3</h3>
        <p>
          Tenzij anders aangegeven in deze Algemene Verkoopsvoorwaarden of anders vereist door toepasselijke wetgeving
          (bijvoorbeeld door de toepasselijke Privacy regelgeving) dient iedere kennisgeving, klacht, vraag enz. die
          betrekking heeft -of zich baseert op- onderhavige Algemene Verkoopsvoorwaarden  te gebeuren via email en in
          ieder geval per aangetekend schrijven met ontvangstmelding naar het adres van de klantendienst van die Keure
          zoals aangegeven in artikel 1.2 van deze Algemene Verkoopsvoorwaarden. De afzender is verantwoordelijk voor
          het bewijs van ontvangst van elke kennisgeving.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 11 - Toepasselijk recht – Bevoegde rechtbank</h2>

        <h3>11.1</h3>
        <p> Deze Algemene Verkoopsvoorwaarden zijn onderworpen aan het Belgische recht. </p>

        <h3>11.2</h3>
        <p>
          Beide partijen verbinden zich ertoe om in geval van mogelijke discussies of onenigheden met betrekking tot de
          toepassing of de interpretatie van onderhavige Algemene Verkoopsvoorwaarden eerst een minnelijke oplossing na
          te streven alvorens een gerechtelijke procedure te starten.
        </p>
        <p>
          De bevoegde rechtbanken en hoven voor kennisname van betwistingen omtrent deze Algemene Verkoopsvoorwaarden
          zullen de rechtbanken en hoven van het arrondissement Brugge zijn, behoudens andersluidende dwingende
          wettelijke bepalingen.
        </p>
      </div>
    </section>
  </div>
</div>
