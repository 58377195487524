import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowScrollService } from '@campus/browser';
import { ENVIRONMENT_ASSETS_TOKEN, ENVIRONMENT_WEBSITE_TOKEN, EnvironmentAssetsInterface } from '@campus/environment';
import {
  ClassGroupInterface,
  ContactFormEntryInterface,
  FaqCategoryInterface,
  PublicPortalFaqCategoryInterface,
  UserType,
} from '@campus/public-portal';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { PersonWithToken, Role } from '../../kabas.interfaces';
import { HomePageViewModel } from './home-page.viewmodel';

interface HomePageFaqCategoryInterface {
  img: string;
  title: string;
  description: string;
  role: string;
}
interface HomePageEaInterface {
  name: string;
  number: string;
  email: string;
  province: string;
  img: string;
}
@Component({
  selector: 'kabas-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit, OnDestroy {
  @HostBinding('class.kabas-homepage')
  hasKabasHomepageClass = true;

  public showModal = false;
  public role: Role;
  public user$: Observable<PersonWithToken>;
  public isMaintenanceMode$: Observable<boolean>;
  public statusPageUrl: string;

  // used to tunnel back contact response from API to the contact form
  public contactSuccess$: Observable<boolean>;
  public classGroups$: Observable<ClassGroupInterface[]>;
  public qrScanner = false;
  public advisors: HomePageEaInterface[];
  public isHeaderPinned = false;

  private subscriptions: Subscription = new Subscription();
  private _advisors = [
    {
      name: 'Laurens Diesveld',
      number: '(0497) 30 98 32',
      email: 'laurens.diesveld@diekeure.be',
      province: 'Antwerpen en Oost-Vlaanderen',
      img: 'ld-head.jpg',
    },
    {
      name: 'Robberd Vercruysse',
      number: '(0478) 71 86 61',
      email: 'robberd.vercruysse@diekeure.be',
      province: 'Oost-Vlaanderen en West-Vlaanderen',
      img: 'rv-head.jpg',
    },
    {
      name: 'Steve Bogaert',
      number: '(0485) 93 19 35',
      email: 'steve.bogaert@diekeure.be',
      province: 'West-Vlaanderen',
      img: 'sb-head.jpg',
    },
    {
      name: 'Linda Van Acker',
      number: '(0497) 33 07 62',
      email: 'linda.vanacker@diekeure.be',
      province: 'Brussel, Vlaams-Brabant en Oost-Vlaanderen',
      img: 'lv-head.jpg',
    },
    {
      name: 'Jasmine Simoni',
      number: '(0472) 84 06 94',
      email: 'jasmine.simoni@diekeure.be',
      province: 'Kempen en Limburg',
      img: 'js-head.jpg',
    },
    {
      name: 'Inge Lehaen',
      number: '(0475) 38 00 86',
      email: 'inge.lehaen@diekeure.be',
      province: 'Zuid-Antwerpen en Oost-Vlaams-Brabant',
      img: 'ih-head.jpg',
    },
  ];

  public faqCategories: HomePageFaqCategoryInterface[];
  private _faqCategories = [
    {
      img: 'student.svg',
      title: 'Leerling',
      description: 'Veelgestelde vragen voor leerlingen.',
      role: 'student',
    },
    {
      img: 'teacher.svg',
      title: 'Leerkracht',
      description: 'Veelgestelde vragen voor leerkrachten.',
      role: 'teacher',
    },
    {
      img: 'schooladmin.svg',
      title: 'Schoolbeheerder',
      description: 'Veelgestelde vragen voor schoolbeheerders.',
      role: 'schooladmin',
    },
  ];

  public subjects = [
    'Ik wil meer info over Kabas',
    'Ik wil een afspraak voor een demo',
    'Ik heb een vraag over mijn bestelling',
    'Ik heb een technische vraag of probleem',
    'Ik heb een taalfout gevonden',
    'Ik heb een andere vraag',
  ];

  private router = inject(Router);
  private vm = inject(HomePageViewModel);
  private environmentWebsite = inject(ENVIRONMENT_WEBSITE_TOKEN);

  constructor(
    @Inject(ENVIRONMENT_ASSETS_TOKEN) private assets: EnvironmentAssetsInterface,
    private scrollService: WindowScrollService,
    private cdRef: ChangeDetectorRef,
    public route: ActivatedRoute
  ) {
    this.subscriptions.add(
      this.scrollService.thresholdReached$.subscribe((reached) => {
        this.isHeaderPinned = reached;
        this.cdRef.markForCheck();
      })
    );
  }

  ngOnInit() {
    this.contactSuccess$ = this.vm.contactSuccess$;
    this.user$ = this.vm.user$.pipe(shareReplay(1));
    this.faqCategories = this._faqCategories.map(this.mapAssets('faq')) as HomePageFaqCategoryInterface[];

    this.advisors = this._advisors.map(this.mapAssets('ea')) as HomePageEaInterface[];
    this.isMaintenanceMode$ = this.vm.isMaintenanceMode$;
    this.statusPageUrl = this.environmentWebsite.statusPageUrl;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  logoutClicked() {
    this.vm.logout();
  }

  goToPlatform() {
    // redirect user to portal based on the role
    this.vm.redirectToPlatform();
  }

  closeModal() {
    this.showModal = false;
  }

  trackById(index: number, category: FaqCategoryInterface): number {
    return category.id;
  }

  private mapAssets(directory: 'ea' | 'faq') {
    const assetsPath = this.assets.images[directory].endsWith('/')
      ? this.assets.images[directory].slice(0, -1)
      : this.assets.images[directory];

    return (item: HomePageEaInterface | HomePageFaqCategoryInterface) => ({
      ...item,
      img: `${assetsPath}/${item.img}`,
    });
  }

  public showQrScanner(event: boolean) {
    this.qrScanner = event;
    this.cdRef.markForCheck();
  }

  public clickedFaqCategory(category: PublicPortalFaqCategoryInterface) {
    this.router.navigate(['faq'], { queryParams: { role: category.role } });
  }

  public contactFormSubmitted(formValues: ContactFormEntryInterface) {
    this.vm.sendContactForm(formValues);
  }

  public resetContactForm() {
    this.vm.resetContactForm();
  }

  public openLoginForm(type: UserType) {
    this.vm.openLoginForm(type);
  }
}
