import { PersonInterface, StudentCodeLoginCredentials } from '@campus/public-portal';
import { Action } from '@ngrx/store';

export enum AppAuthActions {
  STUDENT_CODE_LOGIN = '[Student Auth] Student Code Login',
  STUDENT_CODE_LOGIN_SUCCESS = '[Student Auth API] Student Code Login Success',
  STUDENT_CODE_LOGIN_FAIL = '[Student Auth API] Student Code Login Fail',
}

export class StudentCodeLogin implements Action {
  readonly type = AppAuthActions.STUDENT_CODE_LOGIN;
  constructor(public payload: { credentials }) {}
}

export class StudentCodeLoginSuccess {
  readonly type = AppAuthActions.STUDENT_CODE_LOGIN_SUCCESS;
  payload: { current: PersonInterface; credentials: StudentCodeLoginCredentials };

  constructor(current: PersonInterface, credentials: StudentCodeLoginCredentials) {
    this.payload = { current, credentials };
  }
}

export class StudentCodeLoginFail {
  readonly type = AppAuthActions.STUDENT_CODE_LOGIN_FAIL;
  constructor(public payload: { error: string | Error }) {}
}

export type AppAuthActionMethods = StudentCodeLogin | StudentCodeLoginSuccess | StudentCodeLoginFail;
