import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[kabasModalContent], [modalContent], kabas-modal-content',
})
export class ModalContentDirective {
  @HostBinding('class.kabas-modal-content')
  hasModalContentClass = true;
}

@Component({
  selector: 'kabas-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Output() close = new EventEmitter<boolean>();

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeEvent(event: KeyboardEvent) {
    this.onClose();
  }

  constructor() {}

  onClose() {
    this.close.emit(true);
  }
}
