import { inject, Injectable } from '@angular/core';
import { MaintenanceService } from '@campus/browser';
import {
  AuthFacade,
  ContactFormEntryInterface,
  ContactformEntryService,
  FormFacade,
  UserType,
} from '@campus/public-portal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RedirectService } from '../../services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class HomePageViewModel {
  private auth = inject(AuthFacade);
  private redirectService = inject(RedirectService);
  private form = inject(FormFacade);
  private contactformEntryService = inject(ContactformEntryService);
  private maintenanceService = inject(MaintenanceService);

  public user$ = this.auth.user$;
  public isMaintenanceMode$: Observable<boolean>;
  contactSuccess$: Observable<boolean> = this.contactformEntryService.contactSuccess$;

  constructor() {
    this.isMaintenanceMode$ = this.maintenanceService.isMaintenanceMode();
  }

  public logout() {
    this.auth.logout();
  }

  public redirectToPlatform(): void {
    this.user$.pipe(take(1)).subscribe((user) => {
      this.redirectService.toPlatform(user);
    });
  }

  public openLoginForm(type: UserType) {
    this.form.openLoginForm(type);
  }

  public sendContactForm(formValues: ContactFormEntryInterface) {
    this.contactformEntryService.submit(formValues);
  }

  public resetContactForm() {
    this.contactformEntryService.reset();
  }
}
