import { UserState, initialUserState } from '@campus/public-portal';
import { AppAuthActionMethods, AppAuthActions } from './app-auth.actions';

export const initialState = initialUserState;

export const appAuthReducer = function(
  state: UserState = initialState,
  action: AppAuthActionMethods
): UserState {
  switch (action.type) {
    case AppAuthActions.STUDENT_CODE_LOGIN:
      return {
        ...state,
        isAuthenticated: null,
        error: null,
      };

    case AppAuthActions.STUDENT_CODE_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };

    case AppAuthActions.STUDENT_CODE_LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload.error.toString(),
      };

    default:
      return state;
  }
}
