<ng-container *ngIf="isQuickLogin">
  <div class="kabas-menu-container" (clickOutside)="clickOutsideMenu()" (click)="$event.stopPropagation()">
    <div class="kabas-menu__trigger" (click)="toggleMenu()">
      <svg class="kabas-icon"><use xlink:href="#more-vert" /></svg>
    </div>
    <div *ngIf="menuOpen" class="kabas-menu__items">
      <span class="kabas-menu-item--small kabas-menu__item" (click)="quickLogin.emit(person)"
        ><svg class="kabas-menu__icon">
          <use xlink:href="#rocket" /></svg
        ><a>naar het platform</a></span
      >
      <span class="kabas-menu-item--small kabas-menu__item" (click)="forgetAboutMe.emit(person)"
        ><svg class="kabas-menu__icon"><use xlink:href="#forget" /></svg> <a>vergeet mij</a></span
      >
    </div>
  </div>

  <img
    *ngIf="person.avatar; else noAvatar"
    [src]="person.avatar"
    alt="avatar"
    class="kabas-persisted-user-card__avatar"
  />
  <ng-template #noAvatar
    ><div class="kabas-persisted-user-card__avatar--text">
      {{ person.name.charAt(0) }}{{ person.firstName.charAt(0) }}
    </div></ng-template
  >

  <div class="kabas-persisted-user-card__display-name">{{ person.displayName }}</div>
</ng-container>
<ng-container *ngIf="isAddNew">
  <div class="kabas-persisted-user-card__svg-icon">
    <svg class="kabas-menu__icon">
      <use xlink:href="#passcode" />
    </svg>
  </div>
  <div class="kabas-persisted-user-card__add-label">Code</div>
</ng-container>
<ng-container *ngIf="isQRLogin">
  <div class="kabas-persisted-user-card__svg-icon">
    <svg class="kabas-menu__icon">
      <use xlink:href="#qr" />
    </svg>
  </div>
  <div class="kabas-persisted-user-card__add-label">QR</div>
</ng-container>
<ng-container *ngIf="isLeerIDLogin">
  <div class="kabas-persisted-user-card__svg-icon">
    <svg class="kabas-menu__icon">
      <use xlink:href="#leerid" />
    </svg>
  </div>
  <div class="kabas-persisted-user-card__add-label">LeerId</div>
</ng-container>
