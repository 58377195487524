export const icons = {
  /* outlined */
  'camera-permission': 'assets/icons/outlined/camera-permission.svg',
  'arrow-right': 'assets/icons/outlined/arrow_right.svg',
  'chevron-down': 'assets/icons/outlined/chevron_down.svg',
  'chevron-up': 'assets/icons/outlined/chevron_up.svg',
  check: 'assets/icons/outlined/done.svg',
  'expand-more': 'assets/icons/outlined/expand_more.svg',
  close: 'assets/icons/outlined/close.svg',
  link: 'assets/icons/outlined/link.svg',
  menu: 'assets/icons/outlined/menu.svg',
  'arrow-drop-down': 'assets/icons/outlined/arrow_drop_down.svg',
  'logo-small': 'assets/svgs/logo_small.svg',
  lock: 'assets/icons/outlined/lock.svg',
  'logo-letter': 'assets/icons/outlined/logo_letter.svg',
  lightbulb: 'assets/icons/outlined/lightbulb.svg',
  newspaper: 'assets/icons/outlined/newspaper.svg',
};
