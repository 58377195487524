import { Injectable, InjectionToken } from '@angular/core';
import { AuthAdapterInterface, BaseAdapter, StudentCodeLoginCredentials } from '@campus/public-portal';
import { StudentCodeLogin } from '../../state/auth/app-auth.actions';

export const STUDENT_CODE_ADAPTER = new InjectionToken<AuthAdapterInterface>('STUDENT_CODE_ADAPTER');

@Injectable({
  providedIn: 'root',
})
export class StudentCodeAdapter extends BaseAdapter {
  public login(): void {
    const credentials = this.credentials as StudentCodeLoginCredentials;
    this.store.dispatch(new StudentCodeLogin({ credentials }));
  }
}
