/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolInterface } from '../interfaces';
import { ModelApiBase } from '../model-api.base';
import { PolpoApi } from '../polpo-api';

@Injectable({ providedIn: 'root' })
export class SchoolApi extends ModelApiBase {
  constructor(api: PolpoApi) {
    super(api, 'Schools');
  }

  findBySchoolCode(schoolCode: number): Observable<SchoolInterface> {
    const path = 'findBySchoolCode';

    return this.post<SchoolInterface>(path, undefined, { schoolCode });
  }
}
