<div
  *ngIf="showCameraSelect"
  class="camera-select__wrapper [ w-full m-0 flex align-center border-0 ] [ index-overlay relative ]"
>
  <ng-template #initializeCameras>
    <div class="[ p-b-2xs p-i-xs ]">Camera's zoeken, even geduld...</div>
  </ng-template>
  <select
    *ngIf="cameraDevices; else initializeCameras"
    class="camera-select [ w-full h-full p-i-xs ]"
    (change)="onDeviceSelectChange($event)"
  >
    <option *ngIf="!cameraDevices?.length" value="">Geen camera's gevonden</option>
    <option
      *ngFor="let device of cameraDevices"
      [selected]="selectedDeviceId === device.deviceId"
      [value]="device.deviceId"
      >{{ device.label }}</option
    >
  </select>
</div>
<div class="camera-container [ relative w-full h-full ]">
  <div *ngIf="cameraError; else showcamera" class="[ flex flex-column h-full justify-center ]">
    <div class="[ flex ]">
      <svg class="[ w-full max-w-2/4 m-auto fill-background ]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M9.56 8l-2-2-4.15-4.14L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.55-.18L19.73 21l1.41-1.41-8.86-8.86L9.56 8zM5 16V8h1.73l8 8H5zm10-8v2.61l6 6V6.5l-4 4V7c0-.55-.45-1-1-1h-5.61l2 2H15z"
        />
      </svg>
    </div>
    <p class="[ p-i-m text-background text-center weight-bold ]">
      {{ cameraError }}
    </p>
  </div>
  <ng-template #showcamera>
    <video
      #camera
      [class.camera--valid]="!!scanResult"
      [class.camera--mirror]="mirrorCamera"
      class="camera [ h-full w-full ]"
      autoplay
      playsinline
      muted
      data-cy="video-camera"
    ></video>
    <div *ngIf="isLoading" class="[ absolute top-1/2 left-1/2 transform -translate-1/2 ] [ title-medium on-primary ]">
      De camera word gestart...
    </div>
    <div
      *ngIf="showOverlay && !isLoading"
      class="camera-overlay [ absolute left-0 top-0 w-full h-full flex flex-column ]"
    >
      <svg class="camera-overlay-camera [ w-full ]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
        <path
          class="qr-overlay-background"
          d="m0,0v1024h1024V0H0Zm916.41,878.39c0,21-17.02,38.02-38.02,38.02H145.61c-21,0-38.02-17.02-38.02-38.02V145.61c0-21,17.02-38.02,38.02-38.02h732.79c21,0,38.02,17.02,38.02,38.02v732.79Z"
        />
        <path
          class="qr-overlay-corner"
          d="m881.22,94.09h-22.59v13.5h19.76c21,0,38.02,17.02,38.02,38.02v19.76h13.5v-22.59c0-26.89-21.8-48.69-48.69-48.69Z"
        />
        <path
          class="qr-overlay-corner"
          d="m94.09,142.78v22.59h13.5v-19.76c0-21,17.02-38.02,38.02-38.02h19.76v-13.5h-22.59c-26.89,0-48.69,21.8-48.69,48.69Z"
        />
        <path
          class="qr-overlay-corner"
          d="m94.09,858.63v22.59c0,26.89,21.8,48.69,48.69,48.69h22.59v-13.5h-19.76c-21,0-38.02-17.02-38.02-38.02v-19.76h-13.5Z"
        />
        <path
          class="qr-overlay-corner"
          d="m916.41,878.39c0,21-17.02,38.02-38.02,38.02h-19.76v13.5h22.59c26.89,0,48.69-21.8,48.69-48.69v-22.59h-13.5v19.76Z"
        />
      </svg>
    </div>
  </ng-template>
</div>
