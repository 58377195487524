import { inject, Injectable } from '@angular/core';
import { MaintenanceService, WINDOW } from '@campus/browser';
import { AuthFacade, ClassGroupInterface, SchoolApi, SchoolInterface } from '@campus/public-portal';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { PersistedUsersFacade } from './facades/persisted-users.facade';
import { PersistedUserCredentialsInterface } from './services/persisted-users.service.interface';
import { RedirectService } from './services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class AppViewModel {
  public persistedUsers$: BehaviorSubject<PersistedUserCredentialsInterface[]>;
  public isMaintenanceMode$: Observable<boolean>;

  private persistedUsers = inject(PersistedUsersFacade);
  private auth = inject(AuthFacade);
  private redirectService = inject(RedirectService);
  private schoolApi = inject(SchoolApi); // TODO SDK
  private window = inject(WINDOW);
  private maintenanceService = inject(MaintenanceService);

  constructor() {
    this.setupStreams();
  }

  private setupStreams(): void {
    this.isMaintenanceMode$ = this.maintenanceService.isMaintenanceMode();
    this.persistedUsers$ = this.persistedUsers.getAll();
  }

  redirectToPlatform(): void {
    this.auth.user$.pipe(take(1)).subscribe((user) => {
      this.redirectService.toPlatform(user);
    });
  }

  getClassGroupsForSchool(schoolCode: number): Observable<ClassGroupInterface[]> {
    return this.schoolApi.findBySchoolCode(schoolCode).pipe(
      map((school: SchoolInterface) => school.classGroups.sort(this.sortByName)),
      catchError((err) => of([]))
    );
  }

  forgetPerson(person: PersistedUserCredentialsInterface) {
    this.persistedUsers.remove(person.id);
  }

  public checkUserValidity() {
    this.auth
      .isCurrentUserInSync()
      .pipe(filter((isSynced) => !isSynced))
      .subscribe(() => {
        this.window.location.reload();
      });
  }

  private sortByName(classGroupA: ClassGroupInterface, classGroupB: ClassGroupInterface) {
    return classGroupA.name.localeCompare(classGroupB.name, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }
}
