import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[campusButtonIcon], [button-icon], [button-leading-icon]',
})
export class ButtonIconDirective {
  @HostBinding('class')
  leadingIconClasses = ['order-first', 'icon-current'];
}

@Directive({
  selector: '[campusButtonSuffixIcon], [button-suffix-icon]',
})
export class ButtonSuffixIconDirective {
  @HostBinding('class')
  trailingIconClasses = ['order-last', 'icon-current'];
}
