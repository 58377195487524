import { trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  InjectionToken,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthAdapterInterface, BaseFormComponent, LoginCredentials, StateEnum as State } from '@campus/public-portal';
import { Observable } from 'rxjs';
import { fadeInAnimation, slideInAnimation, wiggleAnimation } from '../../../animations';

// for toggling between login and password reset view
type Mode = 'login' | 'forgot-password';

@Component({
  selector: 'kabas-login-teacher',
  templateUrl: './login-teacher.component.html',
  styleUrls: ['./login-teacher.component.scss'],
  animations: [
    trigger('fadeIn', fadeInAnimation),
    trigger('slideIn', slideInAnimation),
    trigger('wiggle', wiggleAnimation),
  ],
})
export class LoginTeacherComponent extends BaseFormComponent<LoginCredentials> implements OnInit, OnChanges {
  @Output() forgotPassword: EventEmitter<string> = new EventEmitter();

  @HostBinding('class') classList = 'kabas-login-teacher w-full';

  public mode: Mode = 'login'; // start in login
  public changes$: Observable<boolean>;

  // update texts based on the component's mode & state
  public uiState = {
    login: {
      error: 'Het opgegeven e-mailadres of gebruikersnaam en/of wachtwoord is niet correct.', // api error
      success: 'Je bent aangemeld. We sturen je zo dadelijk naar het platform.',

      cta: 'Aanmelden',
      toggleMode: 'wachtwoord vergeten?', // toggle between mode
    },
    'forgot-password': {
      error: 'Dit e-mailadres heeft geen account op Kabas.', // api error
      success:
        'Check je inbox: er is een e-mail gestuurd naar het opgegeven e-mailadres om je wachtwoord opnieuw in te stellen.',
      cta: 'Stuur een e-mail',
      toggleMode: 'ik weet het weer',
    },
  };

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnInit() {
    this.buildForm();
    super.ngOnInit();
  }

  setMode(mode: Mode) {
    this.mode = mode;
    this.afterModeHook();
  }

  submit(adapter: string | InjectionToken<AuthAdapterInterface>) {
    if (this.mode === 'forgot-password') {
      this.sendEmail();
    } else {
      super.submit(adapter);
    }
  }

  private afterModeHook() {
    const passwordField = this.form.get('password');
    const emailField = this.form.get('email');
    if (this.mode === 'forgot-password') {
      passwordField.setValidators([]);
      emailField.setValidators([Validators.required, Validators.email]);
    } else {
      passwordField.setValidators([Validators.required]);
      emailField.setValidators([Validators.required]);
    }
    this.reset();
  }

  private buildForm() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  private sendEmail() {
    if (this.form.invalid) return;
    this.state = State.PROCESSING;
    this.forgotPassword.emit(this.form.get('email').value);
  }
}
