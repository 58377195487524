<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1056 666">
  <defs>
    <clipPath id="kabas-login-clip-path">
      <path
        id="SVGID"
        d="M72.12,14h959a23.42,23.42,0,0,1,23.42,23.42V642.58A23.42,23.42,0,0,1,1031.13,666h-959a21.57,21.57,0,0,1-21.57-21.57V35.61A21.57,21.57,0,0,1,72.12,14Z"
        style="fill: none"
      />
    </clipPath>
    <linearGradient
      id="kabas-login-gradient-3"
      x1="-497.6"
      y1="409.43"
      x2="-456.11"
      y2="409.43"
      gradientTransform="matrix(-1.03, -0.15, -0.14, 0.99, -289.84, -19.93)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.16" stop-color="#983848" />
      <stop offset="0.44" stop-color="#5d1929" />
      <stop offset="0.63" stop-color="#5d1929" />
      <stop offset="0.83" stop-color="#983848" />
    </linearGradient>
    <clipPath id="kabas-login-clip-path-2">
      <path
        id="SVGID-2"
        data-name="SVGID"
        d="M179.84,557.73s.73,5.15.93,6,1,5.3,1.17,7.22.41,3.92,1.7,4.75,3.06-.34,4.46-.69,2.05-1.55,1.43-2.56-3.81-3.23-4.18-3.83.05-5,.07-6,.46-5.14.46-5.14A30.06,30.06,0,0,0,179.84,557.73Z"
        style="fill: #f3a470"
      />
    </clipPath>
    <clipPath id="kabas-login-clip-path-3">
      <path
        id="SVGID-3"
        data-name="SVGID"
        d="M167.26,584.9s2.78,4.36,2.67,5,4.73,4.77,3,5.89-4.44,1.85-4.91,3,.22,1.67,3.14,1.34-2.61.07-.48-1.18a64.18,64.18,0,0,1,5.92-3c1.6-.72,1.51-1.45,1.22-2.5s-2.51-3.84-2.88-4.9-1.64-5.59-1.64-5.59A41.8,41.8,0,0,0,167.26,584.9Z"
        style="fill: none"
      />
    </clipPath>
    <clipPath id="kabas-login-clip-path-4">
      <path
        id="SVGID-4"
        data-name="SVGID"
        d="M158,462.38s2.21-2.62,4.91-6.16c0,0,9.45,14.88,8.92,19.34s2,15.87,3.08,17.07,2.46,2.23,2.2,3.3-1.05,3.1-1,3.47,3.07,2.93,3.29,4-4.7,2.56-6.89,3.38-9.15,3.15-11.67,3.21-8.25,2.41-9,2.58a30.45,30.45,0,0,1-4,.81,24.13,24.13,0,0,0-2.9.47,13.21,13.21,0,0,1-.84-5.26c.14-2.16,1.68-5.3.57-9.57-1.52-5.79-4.9-9.82-5.3-21.26,0-.73,3-4.47,3.75-4.78l4.8-5.16S150.78,469.26,158,462.38Z"
        style="fill: none"
      />
    </clipPath>
  </defs>
  <g id="kabas-login__authentication">
    <g id="kabas-login__bottom">
      <g style="clip-path: url(#kabas-login-clip-path)">
        <path
          d="M1066.72,590H-135.82c-6,0-10.85,2.29-10.85,5.11,0,15.74,27.09,28.5,60.5,28.5h61c14.88,0,26.94,5.68,26.94,12.69S13.8,649,28.68,649H183.74c11.41,0,20.65,4.36,20.65,9.73s9.25,9.73,20.65,9.73H885.17c11.4,0,20.65-4.36,20.65-9.73s9.24-9.73,20.65-9.73h63.4c8.88,0,16.08-3.39,16.08-7.58v-5.11c0-7,12.06-12.69,26.93-12.69,24.68,0,44.69-9.42,44.69-21.05v-7.45C1077.57,592.25,1072.71,590,1066.72,590Z"
          style="fill: #cd735e"
        />
        <path
          d="M-135.82,668.41H1066.71c6,0,10.86-2.29,10.86-5.12,0-15.74-27.09-28.5-60.5-28.5h-61c-14.87,0-26.93-5.68-26.93-12.68s-12.06-12.69-26.93-12.69H747.15c-11.4,0-20.65-4.36-20.65-9.73S717.26,590,705.85,590H45.73c-11.41,0-20.65,4.35-20.65,9.73s-9.25,9.73-20.65,9.73H-59c-8.89,0-16.09,3.39-16.09,7.57v5.12c0,7-12,12.68-26.93,12.68-24.67,0-44.68,9.43-44.68,21.05v7.45C-146.67,666.12-141.81,668.41-135.82,668.41Z"
          style="fill: #cd735e"
        />
        <path
          d="M25,661.47c0,2.42,4.28,4.4,9.5,4.4h29.7c5.23,0,9.5-2,9.5-4.4s-4.27-4.39-9.5-4.39H34.47C29.25,657.08,25,659.06,25,661.47Z"
          style="fill: #d68c7b"
        />
        <path
          d="M888.25,654.11c0,2.65-4.61,4.83-10.25,4.83H428.87c-5.64,0-10.25-2.18-10.25-4.83s4.61-4.83,10.25-4.83H878C883.64,649.28,888.25,651.45,888.25,654.11Z"
          style="fill: #d68c7b"
        />
        <path
          d="M543.2,617.56c.1-5.74-9.74-10.41-21.91-10.41H-12.22c-6,0-11.19,2.27-11.1,5.12s-4.89,5.29-11,5.29h-97.95c-7,0-13,2.52-13.29,5.8-.35,3.5,5.57,6.39,12.92,6.39H-63.6c7.22,0,12.88,2.79,13,6.19s5.86,6,13,6H454.73c7.23,0,12.89-2.78,13-6.18s5.85-6,13-6h62.16c7,0,12.95-2.52,13.28-5.8C556.47,620.45,550.55,617.56,543.2,617.56Z"
          style="fill: #d68c7b"
        />
        <path
          d="M997.69,608.45H589.09c-2.32,0-4.21-.89-4.21-2s1.89-2,4.21-2h408.6c2.32,0,4.21.89,4.21,2S1000,608.45,997.69,608.45Z"
          style="fill: #d68c7b"
        />
        <path
          d="M393.41,665.83c-.7-2.45-5.17-4.36-10.55-4.36H246.76c-5.38,0-9.85,1.91-10.55,4.36Z"
          style="fill: #d68c7b"
        />
        <path
          d="M731.6,598.09H652.72c-1.72,0-3.12.65-3.12,1.46h85.11C734.71,598.74,733.32,598.09,731.6,598.09Z"
          style="fill: #d68c7b"
        />
        <path
          d="M657.75,616.73c-2.5,0-4.53,1-4.53,2.13h9C662.27,617.69,660.24,616.73,657.75,616.73Z"
          style="fill: #d68c7b"
        />
        <path d="M541.1,637.19c-3.81-2-9.33-3.27-15.49-3.27s-11.68,1.26-15.49,3.27Z" style="fill: #d68c7b" />
        <path d="M313.52,600a19,19,0,0,0-20.86,0Z" style="fill: #d68c7b" />
        <path d="M974.84,627.32c0-3.25-5.58-5.88-12.46-5.88H749c-6.88,0-12.46,2.63-12.46,5.88Z" style="fill: #d68c7b" />
        <path
          d="M1092,580.9c-26.85-2.08-50.65-3.08-78.94-5.56-5.6-.71-13.37-1.08-26.11-1-41,.21-63.86,8.47-107.59,6.79s-41.65,1.57-79.83.32-55.57.91-91.67-1.81-42.3,0-81.87-1.85-36.78,6.59-90.93,2.61c-52.87-3.88-72-8.25-96.12-5.73-27.41.44-36.67,4-83.22,6.35-48.35,2.4-41.06,2.1-72.12,1.8s-55.64-9.19-89.17-8.19-77.28-16-130.22-1.59c-21.4,5.81-68.47-6.59-109.41-1.8s-97.61,12.65-114.31,13.34c-19,.78-29.94,7.42-29.94,7.42H1136.06S1131.73,584,1092,580.9Z"
          style="fill: #aa6343"
        />
        <path
          d="M971,584.47h-26.6c-12.93,0-23.42,4.94-23.42,11h73.43C994.36,589.41,983.88,584.47,971,584.47Z"
          style="fill: #cd735e"
        />
        <path
          d="M865.17,587.63H851.69c-8.1,0-14.67,3.1-14.67,6.92h42.81C879.83,590.73,873.27,587.63,865.17,587.63Z"
          style="fill: #cd735e"
        />
        <path d="M3.05,594c-.64-3.12-6.2-5.55-13-5.55s-12.33,2.43-13,5.55Z" style="fill: #cd735e" />
        <path d="M50.5,602.43a28.32,28.32,0,0,0-12.76-2.69A28.34,28.34,0,0,0,25,602.43Z" style="fill: #aa6343" />
        <path d="M229.28,604.64a31.63,31.63,0,0,0-25.53,0Z" style="fill: #aa6343" />
        <path
          d="M556.74,609.47a15.78,15.78,0,0,0-5.55,6.77h4.61C557.71,613.58,556.74,609.47,556.74,609.47Z"
          style="fill: #a16b46"
        />
        <path d="M356.92,622a12.2,12.2,0,0,0-1-5.75c-.71,2.37-1.18,4.18-1.77,5.75Z" style="fill: #aa6343" />
        <path d="M350.51,623.48a32.47,32.47,0,0,0-5.36-8.32c-.72,2.58,0,6,.71,8.32Z" style="fill: #aa6343" />
        <path d="M155.17,638.64s-1.22,4.75-.35,8.12h6.05A15,15,0,0,0,155.17,638.64Z" style="fill: #a16b46" />
        <path d="M1025.42,606.52a17.35,17.35,0,0,1-1.75-8.38s3.82,5.06,5,8.38Z" style="fill: #a16b46" />
        <path d="M1040.44,602.33a17,17,0,0,1,.21,5.92h-3.74A11.24,11.24,0,0,1,1040.44,602.33Z" style="fill: #a16b46" />
        <path d="M149.39,647.43c-2.7-2.77-8-4.73-8-4.73s2.22,2.47,4.07,4.73Z" style="fill: #a16b46" />
        <path d="M902.25,605.43s-1,6-1.33,9.17h4.13A40.74,40.74,0,0,0,902.25,605.43Z" style="fill: #a16b46" />
        <path
          d="M913.13,607.72a28.06,28.06,0,0,0-1.86,6.55h4.33A13.69,13.69,0,0,0,913.13,607.72Z"
          style="fill: #a16b46"
        />
        <path d="M131.68,596.37s-1,6-1.34,9.18h4.13A40.31,40.31,0,0,0,131.68,596.37Z" style="fill: #a16b46" />
        <path d="M140,603.29a11.08,11.08,0,0,0,.7-4.66,61.58,61.58,0,0,0-4.59,4.66Z" style="fill: #a16b46" />
        <path d="M559.73,637.37s-1.27,4.21-1.76,6.5h5.44A20.46,20.46,0,0,0,559.73,637.37Z" style="fill: #a16b46" />
        <path
          d="M570.05,635.32a28.06,28.06,0,0,0-1.86,6.55h4.33A13.69,13.69,0,0,0,570.05,635.32Z"
          style="fill: #a16b46"
        />
        <path
          d="M549.36,639.85a27.91,27.91,0,0,0-1.86,6.54h4.33A13.66,13.66,0,0,0,549.36,639.85Z"
          style="fill: #a16b46"
        />
        <path d="M698.19,612.37a69.58,69.58,0,0,0,5.79-5.22s-5.53,3-9.53,5.22Z" style="fill: #aa6343" />
        <path
          d="M686.61,607.26a15.92,15.92,0,0,1,5.55,6.77h-4.62C685.64,611.38,686.61,607.26,686.61,607.26Z"
          style="fill: #aa6343"
        />
        <path d="M542.35,612.47a41.81,41.81,0,0,1,7,3.77h-2.54A20.6,20.6,0,0,1,542.35,612.47Z" style="fill: #a16b46" />
        <path
          d="M836.64,639.88c-5.59,4.22-7.79,8.32-8.63,10.52h7.17C838.15,646.28,836.64,639.88,836.64,639.88Z"
          style="fill: #a16b46"
        />
        <path d="M814.25,644.55s7,2.94,10.93,5.85h-3.95A31.92,31.92,0,0,1,814.25,644.55Z" style="fill: #a16b46" />
        <path d="M877.08,635.8s.95-4.18-8.33-5.89C867.81,631.34,877.08,635.8,877.08,635.8Z" style="fill: #aa6343" />
        <path d="M881.29,634.89s-6.1-5.55,0-12.13C882.93,625.31,881.29,634.89,881.29,634.89Z" style="fill: #aa6343" />
        <path d="M248.9,627.15c-20.45,6.58-9.85,23-9.85,23S254.48,635.15,248.9,627.15Z" style="fill: #aa6343" />
        <path d="M51.76,646a146.15,146.15,0,0,0,12.42-16.47C55.62,633.17,51.76,646,51.76,646Z" style="fill: #aa6343" />
        <path d="M301.35,637.29c5,7.55,15.82,13.21,15.82,13.21S314,635.25,301.35,637.29Z" style="fill: #aa6343" />
        <path d="M48.29,646.32s10.46-17.62,12.19-28.75C44.14,622.17,48.29,646.32,48.29,646.32Z" style="fill: #aa6343" />
        <path d="M42.6,646.3s-4.4-11.16-15.85-12.86C29.21,640.12,42.6,646.3,42.6,646.3Z" style="fill: #aa6343" />
        <path d="M490.26,645s12.4-14.92,9.85-18.31C489.25,630,487.67,634.91,490.26,645Z" style="fill: #aa6343" />
        <path d="M219.82,641.1a26.18,26.18,0,0,0,7,9C225.64,642.64,219.82,641.1,219.82,641.1Z" style="fill: #aa6343" />
        <path d="M325.76,658.7s6.52-4.61,5.36-11.29C325.28,650.76,325.76,658.7,325.76,658.7Z" style="fill: #aa6343" />
        <path d="M15.9,639.72a122.08,122.08,0,0,0,10.45,6.78S25.9,643.1,15.9,639.72Z" style="fill: #aa6343" />
        <path
          d="M483.49,642.18a58.11,58.11,0,0,0,3.12-9.27C480.48,635.59,483.49,642.18,483.49,642.18Z"
          style="fill: #aa6343"
        />
        <path d="M468.75,632.51c3.61,5.42,11.36,9.48,11.36,9.48S477.83,631.05,468.75,632.51Z" style="fill: #aa6343" />
        <path
          d="M630.45,642.05s-6.58-8.86,7.86-13.8C640.69,633.52,630.45,642.05,630.45,642.05Z"
          style="fill: #aa6343"
        />
        <path d="M627.48,643.36s-.36-8.81-3.6-10.94C620.28,632.72,627.48,643.36,627.48,643.36Z" style="fill: #aa6343" />
        <path d="M709.88,650.19s0-4.23-2.06-6.48C703.85,645.52,709.88,650.19,709.88,650.19Z" style="fill: #aa6343" />
        <path d="M721.85,650.18s10.32-7,6.57-10.52C722.55,639.78,721.85,650.18,721.85,650.18Z" style="fill: #aa6343" />
        <path d="M616,638.09a76.67,76.67,0,0,0,6.52,4.22S622.26,640.19,616,638.09Z" style="fill: #aa6343" />
        <path d="M717,651.33s-8.49-10.06-9.89-16.42C720.39,637.54,717,651.33,717,651.33Z" style="fill: #aa6343" />
      </g>
    </g>
    <g id="kabas-login__clouds">
      <path
        d="M240.68,273.91a38.58,38.58,0,0,0-48.83-26.13,55,55,0,0,0-74.3,1.26,38.59,38.59,0,0,0-52.23,24.87H240.68Z"
        style="fill: #c7e7f6"
      />
      <path
        d="M662.67,155a22.09,22.09,0,0,0-28-15,31.55,31.55,0,0,0-42.62.72,22.13,22.13,0,0,0-30,14.26h100.6Z"
        style="fill: #d7edf8"
      />
      <path
        d="M367.09,54.81a34.53,34.53,0,0,0-43.68-23.38,49.25,49.25,0,0,0-66.47,1.13,34.52,34.52,0,0,0-46.71,22.25H367.09Z"
        style="fill: #d7edf8"
      />
      <path
        d="M571,162.22a48.69,48.69,0,0,0-14.86,2.31,69.69,69.69,0,0,0-94.07,1.6A48.85,48.85,0,0,0,396,197.61H618A48.84,48.84,0,0,0,571,162.22Z"
        style="fill: #c7e7f6"
      />
      <path
        d="M560.13,94.3A32.93,32.93,0,0,0,518.5,72a46.92,46.92,0,0,0-63.34,1.08,32.91,32.91,0,0,0-44.52,21.2H560.13Z"
        style="fill: #d7edf8"
      />
      <path d="M431.8,139A19.59,19.59,0,0,0,407,125.7,28,28,0,0,0,361.44,139H431.8Z" style="fill: #d7edf8" />
      <path
        d="M220.88,115.09a39.82,39.82,0,0,0-10.82,1.49,48.36,48.36,0,0,0-85.38,19,9.65,9.65,0,0,0-2-.2,9.52,9.52,0,0,0-9.52,9.52,9.24,9.24,0,0,0,.19,1.91,24.28,24.28,0,0,0-14.78,35.93H249.16a39.73,39.73,0,0,0-28.28-67.63Z"
        style="fill: #c7e7f6"
      />
      <path
        d="M669.08,176a22.09,22.09,0,0,1,28-15,31.55,31.55,0,0,1,42.62.72,22.13,22.13,0,0,1,30,14.26H669.08Z"
        style="fill: #d7edf8"
      />
      <path
        d="M871.73,191.73A48.69,48.69,0,0,1,886.59,194a69.69,69.69,0,0,1,94.07,1.6,48.85,48.85,0,0,1,66.11,31.48h-222A48.84,48.84,0,0,1,871.73,191.73Z"
        style="fill: #c7e7f6"
      />
      <path
        d="M882.62,123.81a32.89,32.89,0,0,1,41.63-22.27,46.93,46.93,0,0,1,63.34,1.07,32.91,32.91,0,0,1,44.52,21.2H882.62Z"
        style="fill: #d7edf8"
      />
      <path d="M789.59,159.38a19.6,19.6,0,0,1,24.85-13.29A28,28,0,0,1,860,159.38H789.59Z" style="fill: #d7edf8" />
    </g>
    <g id="kabas-login__cellphone">
      <path
        d="M505.85,564a36.21,36.21,0,0,1-36.22,36.22H310.29A36.21,36.21,0,0,1,274.08,564V239.85a36.21,36.21,0,0,1,36.21-36.21H469.63a36.21,36.21,0,0,1,36.22,36.21Z"
        style="fill: #4b4d61"
      />
      <rect x="299.29" y="234.38" width="179.31" height="298.8" style="fill: #f3f2f3" />
      <path d="M443.94,404.75H336.32a4.89,4.89,0,0,1,0-9.78H443.94a4.89,4.89,0,0,1,0,9.78Z" style="opacity: 0.32" />
      <path d="M443.94,429.21H336.32a4.89,4.89,0,0,1,0-9.78H443.94a4.89,4.89,0,0,1,0,9.78Z" style="opacity: 0.32" />
      <path d="M443.94,453.67H336.32a4.9,4.9,0,0,1,0-9.79H443.94a4.9,4.9,0,0,1,0,9.79Z" style="opacity: 0.32" />
      <path
        d="M415.32,564.87a10,10,0,0,1-10,10H374.57a10,10,0,0,1-10-10h0a10,10,0,0,1,10-10h30.79a10,10,0,0,1,10,10Z"
        style="fill: #2a2a36"
      />
      <circle
        cx="387.69"
        cy="314.25"
        r="55.03"
        transform="translate(-108.66 366.18) rotate(-45)"
        style="opacity: 0.32"
      />
      <path
        d="M408.67,302.18h-1.18v-4.61a18.49,18.49,0,1,0-37,0v4.61h-1.18a3.94,3.94,0,0,0-3.93,3.94v31.46a3.93,3.93,0,0,0,3.93,3.93h39.33a3.93,3.93,0,0,0,3.93-3.93V306.12A3.94,3.94,0,0,0,408.67,302.18Zm-32.25-4.61a12.59,12.59,0,1,1,25.17,0v4.61H376.42Z"
        style="fill: #fff"
      />
      <g style="opacity: 0.32">
        <path
          d="M393,317a4,4,0,1,0-6.16,3.32L385,328.94c0,1.34,1.09,1.77,2.44,1.77h3.08c1.34,0,2.43-.43,2.43-1.77l-1.79-8.67A4,4,0,0,0,393,317Z"
        />
      </g>
    </g>
    <g id="kabas-login__key">
      <path
        d="M130,317.48s-3.23,0-4.57,0c-6.69-.28-11.15-3.71-12.86-10.15a24.69,24.69,0,0,1,.32-14.12,12.22,12.22,0,0,1,11.61-8.8q10.12-.25,20.26,0a11.81,11.81,0,0,1,11.67,9.45,36.64,36.64,0,0,1,.45,12.31c-1.09,7.36-6.19,11.2-13.74,11.34-1.14,0-3.65,0-3.65,0v32.31h17.69v8.32h-6.74v8.57h6.76v8.8H139.4v5.32H130Zm12.33-7.38c5.82-.07,8.59-3.09,8.58-9.33,0-6.09-2.94-9.23-8.74-9.28-4.67,0-9.33,0-14,0-8.27,0-12.61,7.13-8.82,14.57a6.67,6.67,0,0,0,6.17,4C128.51,310.09,139.71,310.13,142.31,310.1Z"
        style="fill: #29265b"
      />
    </g>
    <g id="kabas-login__miss">
      <ellipse
        cx="302.62"
        cy="168.52"
        rx="4.51"
        ry="4.63"
        transform="translate(-25.98 58.98) rotate(-10.68)"
        style="fill: #51485c"
      />
      <path
        d="M316.52,191c-4.4-8.47-9.26-16.82-14.32-25s-10.44-16.16-16.13-23.91a254.79,254.79,0,0,0-18.23-22.29,143.29,143.29,0,0,0-21.29-19.25C238.82,95,230,91,221.05,87.49a198.33,198.33,0,0,0-27.72-8,272.41,272.41,0,0,0-28.56-4.55,278.82,278.82,0,0,0-28.89-1.66,277,277,0,0,1,28.94,1.2,272.69,272.69,0,0,1,28.7,4.1,200.25,200.25,0,0,1,28,7.61q6.84,2.47,13.44,5.58A87.93,87.93,0,0,1,247.63,99a145.71,145.71,0,0,1,22,19.13,263.62,263.62,0,0,1,18.84,22.18c5.88,7.71,11.43,15.67,16.71,23.81s10.28,16.41,14.95,25a2,2,0,0,1-3.56,1.94v0Z"
        style="fill: #51485c"
      />
      <line
        x1="135.02"
        y1="73.36"
        x2="135.02"
        y2="284.11"
        style="fill: none; stroke: #51485c; stroke-miterlimit: 10; stroke-width: 0.577752988636888px"
      />
      <ellipse
        cx="135.05"
        cy="284.63"
        rx="1.65"
        ry="1.4"
        style="fill: none; stroke: #51485c; stroke-miterlimit: 10; stroke-width: 0.577752988636888px"
      />
      <path
        d="M135.05,286.08c0,3-3.17,3.84-3.17,5.33a3.21,3.21,0,0,0,6.34,0"
        style="fill: none; stroke: #51485c; stroke-miterlimit: 10; stroke-width: 0.577752988636888px"
      />
      <path
        d="M135.37,73.36s18.89,9.62,47.48,4c0,0,16.47,11.22,41.19,11.62a76,76,0,0,0,35.37,22.84s3.39,16.83,22.77,25.64c0,0-3.39,20.83,17.93,28.45"
        style="
          fill: none;
          stroke: #51485c;
          stroke-linecap: round;
          stroke-miterlimit: 10;
          stroke-width: 0.577752988636888px;
        "
      />
      <path
        d="M341.14,142.48c1.25,2,.29,5.37-1.16,11.27s-4.31,9.35-4.63,10.67-2.22,2.18-2.52,3.34c-.38,1.42-2.8,3.41-3.44,4.92s-10-1-10.59,0,3.16,6.63-.39,4.88a8.21,8.21,0,0,1-4.47-7.38c.11-1.85,10.11-21.87,12.74-28.09S337.21,136.37,341.14,142.48Z"
        style="fill: #3c4795"
      />
      <path
        d="M381.45,154.8l4.35,1.41c1.29.4,2.19.79,2.51,1.76l4.05,13.62a1.64,1.64,0,0,1-1.07,2l-2.27.71a1,1,0,0,1-1.32-.69l-4.45-14.84C383.07,158.27,380.9,155,381.45,154.8Z"
        style="fill: #42b7b9"
      />
      <path
        d="M385.43,158.51c.18.58.57,1.73.76,2.3l.29.86.64-.21-.28-.86c-.19-.57-.57-1.73-.77-2.31a1.64,1.64,0,0,0-1-1.1l-2.09-.64.16.51,1.44.43a.8.8,0,0,1,.54.32A2.06,2.06,0,0,1,385.43,158.51Z"
        style="fill: #bcbcbb"
      />
      <path
        d="M389.62,163.68c.32.94-.9,1-.44.88-.36.12-.66.2-.88-.44s-.75-3.23-.38-3.35S389.39,163,389.62,163.68Z"
        style="fill: #3e3e3e"
      />
      <path
        d="M388.49,160.29c.24.7-.07.76-.44.88s-.66.2-.88-.44.08-.76.44-.88S388.26,159.59,388.49,160.29Z"
        style="fill: #2a2a2a"
      />
      <path
        d="M393,180.46l-9.54,3.11a2.44,2.44,0,0,1-3.09-1.55l-32.74-53.08c-.32-.94,17.21,4,7.5.18l4.32-1.44c15.48,1.89,20.69,4.5,22.29,9.32l12.86,40.4A2.43,2.43,0,0,1,393,180.46Z"
        style="fill: #1f9ea1"
      />
      <path
        d="M393.72,174.51l.9,2.89a2.45,2.45,0,0,1-1.6,3.07l-10.16,3.18a2.46,2.46,0,0,1-3.07-1.6l-.91-2.89"
        style="fill: #434343"
      />
      <path
        d="M358.34,129.74c-12.89-1.85-.69.49-1.23.61s-8.12-.39,1.34,1.18a73,73,0,0,1,14.76,3.79c2.35,1.27,3.46,3.33,4.45,6.29l4.23,12.7,2.51-.84-3.95-11.83c-1.12-3.39-2.16-6.08-5.42-7.79C375,133.85,371.22,131.59,358.34,129.74Z"
        style="fill: #42b7b9"
      />
      <path
        d="M357.25,130.78a75.61,75.61,0,0,1,15.64,3.79,9.68,9.68,0,0,1,5.6,6.26c1.65,4.95,3.4,10.3,3.54,10.74l.29.86.64-.22-.28-.86c-.15-.43-1.7-5.58-3.36-10.54a10.3,10.3,0,0,0-5.95-6.75c-6-2.34-14.9-3.5-14.93-3.58"
        style="fill: #bcbcbb"
      />
      <path
        d="M384.41,155.48c.23.71-.08.76-.44.88s-.67.21-.88-.44-.75-3.23-.38-3.35S384.18,154.78,384.41,155.48Z"
        style="fill: #3e3e3e"
      />
      <path
        d="M383.28,152.09c.23.71-.08.76-.44.88s-.67.21-.88-.44.08-.75.44-.87S383.05,151.39,383.28,152.09Z"
        style="fill: #2a2a2a"
      />
      <path
        d="M337.13,143.17s14.75,15,16.71,7.83c.2-.71,16.25,5.9,16.88-6.1l-9.4-5.13c-3.72-3.28-11.51-6.85-16-4.67Z"
        style="fill: #e69c6b"
      />
      <path d="M340,131.81s4.05,8.93,4.41,9.58,11.71,7.8,12.34-4.2l-13.12-22.31Z" style="fill: #e69c6b" />
      <path
        d="M380.55,177c5.36,13.8-3.21,20.26-3.21,20.26s-1.44-3.79-18.78-1.11c-4.81.74-6.68,3-6.68,3s-2.13-7.38-2.05-19.33c0,0,2,.44,8.24.37C364.09,180.13,380.55,177,380.55,177Z"
        style="fill: #373789"
      />
      <path
        d="M348.88,163.74s3.77,2.1,6.64,2.11c2.5,0,16.57-4.39,19-4,0,0,8.11,18.65,9.21,22.5,4,14-2.8,29.51-12.77,30.12s-18.9-10.44-20-14.06-2-11.88-2-20.31C349,172.23,348.88,164.91,348.88,163.74Z"
        style="fill: #202a4d"
      />
      <path d="M349.51,165.22c-.88.05-.74,2.53.14,2.48S350.38,165.17,349.51,165.22Z" style="fill: #d6bcb0" />
      <path
        d="M351.57,167.63a17.74,17.74,0,0,0,5,.55s.82,6.13-.12,8-4.41-.73-4.41-.73S351.72,168.64,351.57,167.63Z"
        style="fill: #1f3563"
      />
      <path
        d="M376,165.3l-2.49.69s3.28,8.47,3.72,9a49.61,49.61,0,0,0,4,3.18S377,167.69,376,165.3Z"
        style="fill: #1f3563"
      />
      <path
        d="M299.91,245.37s.64,8.23.14,9,.8,13.89-10.32,11.58c-2.67-.56,2.07-3.1.8-1.67s.7-1.83,4.31-.37-2.59,4.72.66,4.3a68.61,68.61,0,0,1,8.74-.46c2.32,0,2.65-1.12,2.94-2.86s-.64-7.29-.44-9.1,1.44-9.27,1.44-9.27S302.44,245.23,299.91,245.37Z"
        style="fill: #f2a370"
      />
      <path
        d="M367,180.62s-10.14.35-28.25,1.45a354.39,354.39,0,0,0-36,4c-3.46.62-6.1,4.52-6.42,9.6s.95,6.07,7.08,7.25,51.8,5.33,59.51,5.37,9.92-9.13,10.6-14S375.1,180.78,367,180.62Z"
        style="fill: #202a4d"
      />
      <path
        d="M296.82,194.24c.05,11.66,2.38,50.63,2.38,50.63a75.23,75.23,0,0,1,10.16,1.47s3.59-19.78,4.33-30.16,1-17-.3-21.91S296.76,181.06,296.82,194.24Z"
        style="fill: #202a4d"
      />
      <path
        d="M362.41,192.41s-2.58-5.28-17.66,5.28-14.49,12.56-17.14,14.9,1.19,8.1,3.79,12.35,8.21-.66,14.19-3,23.21-4.76,29.92-8.85,2.43-8.9.27-13.16S369.55,188.19,362.41,192.41Z"
        style="fill: #202a4d"
      />
      <path
        d="M326.27,220.55c2.77,10.88,9.55,42.5,9.55,42.5a77.47,77.47,0,0,1,10.39-.5s2.28-21.25.59-31.23-1.92-12.57-4.3-16.67S323.13,208.23,326.27,220.55Z"
        style="fill: #202a4d"
      />
      <path
        d="M336.58,263.4s1.4,7.9,1,8.79-4.23,9.15-6.88,10.15-4.59,1.3-5.74,2.94.54.57,4.32,1.3,5.29-3.06,8.54-4.09,2.87,2.37,5.21,1.93,2.56-1.59,2.7-3.35-1.31-7-1.27-8.77S345,263,345,263,339.12,262.78,336.58,263.4Z"
        style="fill: #f2a370"
      />
      <path
        d="M308.7,244.13c.46-.08,2.15-.81,2.3,0s.1,3.51-.43,3.83a23.46,23.46,0,0,1-8.11.94c-2.45-.4-4.45-.33-4.52-1.19s-.23-3.73.28-4.22,2.16.18,4,.47A53.31,53.31,0,0,0,308.7,244.13Z"
        style="fill: #1f3563"
      />
      <path
        d="M345,260.31c.46-.12,2.16-1,2.33-.21s.19,3.43-.34,3.8a25.31,25.31,0,0,1-8.19,1.84c-2.51-.11-4.53.17-4.62-.66s-.33-3.64.17-4.18,2.2-.06,4.05,0A56.35,56.35,0,0,0,345,260.31Z"
        style="fill: #1f3563"
      />
      <path
        d="M306.93,253a97.12,97.12,0,0,1,2.49,11.57,12.74,12.74,0,0,1-.57,2.67l-.71,2.18-4.5.35s-1-1.62-2.42-1.39c-2.57.42-3.8,1.81-5.07,2.17-1.89.53-12-2.65-12-2.65s-1.72-3.86,2.44-4.19a15,15,0,0,0,13.25-9.68Z"
        style="fill: #e63c59"
      />
      <path
        d="M344.76,269.39a30.79,30.79,0,0,1,1.8,11.18c0,.7-.47,1.9-.53,2.61l-.47,1.75-4.51,1.14a2.51,2.51,0,0,0-2.5-.94c-2.56.86-3.74,2.45-5,3-1.88.85-12.15-.51-12.15-.51s-1.9-3.51,2.27-4.57c10.52-2.68,13.59-12.48,13.59-12.48Z"
        style="fill: #e63c59"
      />
      <path
        d="M341.32,174s13.46,17.78,41.73,14.23c0,0,5-2.38.71-12.22s-1.59-31.66-19.67-36.16c0,0-4.37,12.59-22.54,3.82S341.32,174,341.32,174Z"
        style="fill: #373789"
      />
      <path
        d="M346.63,150.34c-.5,3.07-.19,6.27,3.66,7.16a8.73,8.73,0,0,0-.26,2.12,8.45,8.45,0,0,0,4,6.7,7.55,7.55,0,0,0-.06,2.5,7.74,7.74,0,0,0,4.66,5.36c0,2.88-.09,5.74,3.31,7.12a1.09,1.09,0,0,0,.41.24s0,0,0,.05a5.12,5.12,0,0,0,.13,1.95,2.83,2.83,0,0,0,.63,1.42,5.55,5.55,0,0,0,2.51,2.3.81.81,0,0,0,1.15-.65c.58.18,1.31,0,1.3-.69a6.27,6.27,0,0,0-3.54-5.28c0-.14,0-.28,0-.39a7.58,7.58,0,0,0-.25-3.09c-.62-1.66-2.21-2.87-3.85-3.89a10.3,10.3,0,0,0-.26-3.37,7.56,7.56,0,0,0-3.92-4.26,9.26,9.26,0,0,0,.45-3.17,7.47,7.47,0,0,0-4.14-5.91,7.36,7.36,0,0,0,.13-1.15c0-2.43-1.78-4.21-3.93-5.77.36-1.56.46-1.59.63-2.3.24-1-1.7-1.61-1.94-.57-.19.83-.13.55-.43,1.77m19,37.13-.06,0c.11,0-.49-.47-.49-.47s-.22-.22-.32-.33a.82.82,0,0,0-.35-.55l-.16-.16a3.38,3.38,0,0,1-.35-.87,4.25,4.25,0,0,1,0-.8A4.14,4.14,0,0,1,366,185.67Zm-17.62-34.18c1.39,1.14,2.44,2.44,2.24,4.17l0,.23C348.54,155.49,348.18,153.58,348.41,151.49Zm3.63,7.69a6.74,6.74,0,0,1,.14-1c2.74,1.52,2.54,3.88,2.12,6.28A6.29,6.29,0,0,1,352,159.18Zm6.52,13.15a5.08,5.08,0,0,1-2.68-5C358,168.48,358.46,170.33,358.56,172.33Zm1.83,2.92A6.05,6.05,0,0,1,362.1,177a4.69,4.69,0,0,1,.47,2.79C360.8,179,360.43,177.2,360.39,175.25Z"
        style="fill: #3c4795"
      />
      <path
        d="M336.06,147.25c-.5,3.07-.19,6.27,3.66,7.16a8.73,8.73,0,0,0-.26,2.12,8.44,8.44,0,0,0,3.94,6.7,8.13,8.13,0,0,0-.06,2.5,7.76,7.76,0,0,0,4.67,5.36c0,2.88-.09,5.74,3.3,7.12a1.22,1.22,0,0,0,.42.24l0,.06a5.3,5.3,0,0,0,.14,1.94,2.83,2.83,0,0,0,.63,1.42,5.55,5.55,0,0,0,2.51,2.3.81.81,0,0,0,1.15-.65c.58.18,1.31,0,1.3-.69a6.24,6.24,0,0,0-3.54-5.27c0-.15,0-.29,0-.4a7.58,7.58,0,0,0-.25-3.09c-.63-1.66-2.21-2.87-3.85-3.89a10.3,10.3,0,0,0-.26-3.37,7.55,7.55,0,0,0-3.92-4.25,9.11,9.11,0,0,0,.44-3.18,7.43,7.43,0,0,0-4.13-5.91,7.36,7.36,0,0,0,.13-1.15c0-2.43-1.78-4.21-3.93-5.77.36-1.56.87-3,1-3.7.24-1-1.7-1.6-1.94-.56-.2.83-.54,1.94-.84,3.16m19,37.13-.06,0c.11,0-.49-.47-.49-.47s-.22-.22-.32-.33a.84.84,0,0,0-.35-.55l-.16-.16a3.38,3.38,0,0,1-.35-.87,4.25,4.25,0,0,1,0-.8A4.14,4.14,0,0,1,355.46,182.58ZM337.84,148.4c1.39,1.14,2.44,2.44,2.24,4.17l0,.23C338,152.4,337.61,150.49,337.84,148.4Zm3.63,7.69a6.74,6.74,0,0,1,.14-1c2.74,1.52,2.54,3.89,2.12,6.28A6.27,6.27,0,0,1,341.47,156.09ZM348,169.24a5.08,5.08,0,0,1-2.68-5C347.41,165.39,347.89,167.24,348,169.24Zm1.83,2.92a6.18,6.18,0,0,1,1.71,1.75,4.69,4.69,0,0,1,.47,2.79C350.23,175.92,349.86,174.11,349.82,172.16Z"
        style="fill: #3c4795"
      />
      <path
        d="M384.31,173.91s-5,3.81-18.11-3.81c-5.55-3.23-13.34-17.63-8.56-27.15,1.79-3.58,3.05-6.52,6.18-6.68,4-.2,9.57,4.64,10.41,8.52l2,4.23s-9-12.33-13.85-5.13c-5.34,7.87.85,17.21.85,17.21s6,10.13,20.07,8.21"
        style="fill: #1f9ea1"
      />
      <path
        d="M376.12,152c.81,2.17-.86,5.3-3.54,10.76s-6.22,8.2-6.81,9.43-6.77,7.4-7.31,8.47c-.67,1.3-7.22,7.15-8.16,8.49s-3.16,2.86-3.95,3.68-4.74,1.22-7.83-1.25a8.22,8.22,0,0,1-2.79-8.17c.5-1.78,22.45-29.36,26.34-34.87S373.59,145.2,376.12,152Z"
        style="fill: #3c4795"
      />
      <path
        d="M343.82,193.28c-5,2.63-6.07.72-9.17-1s-3.47-2.48-5.59-3.47-14.62-12.45-16-12.67-4.38-3.72-2.24-5.53,3.34-2.72,3.79-3.09,1.93-.11,4.5,1.26,23.75,12.62,27.51,15.26S346,192.11,343.82,193.28Z"
        style="fill: #3c4795"
      />
      <path
        d="M329.27,170.8c-4,4.17-5.69,2.42-9.26,1.38s-7.59-4.72-10.2-6.53c-2-1.41-7.57-4.77-9.65-6.36-1.19-.91-5.44-2.8-3.93-5.32s2.4-3.82,2.72-4.33,1.85-.64,4.78.12,16.51,2.44,23.34,10C330.36,163.43,331.06,168.94,329.27,170.8Z"
        style="fill: #3c4795"
      />
      <path
        d="M289.94,146.78l5.66-1.64a6.09,6.09,0,0,1,4,7.07L295.4,156s-1.34,0-3.22-2.84a53.16,53.16,0,0,1-2.84-4.94A1,1,0,0,1,289.94,146.78Z"
        style="fill: #f2a370"
      />
      <path
        d="M365,134.25l-.1-1.88a3.37,3.37,0,0,0-2.53-2.7l-.4-.11c-1.34-.36-2.37.26-2.32,1.38l.13,2.32-1.67-.45-.17-3.18c-.09-1.84,1.6-2.86,3.79-2.26l.66.18c2.19.59,4,2.57,4.13,4.41l.15,2.75Z"
        style="fill: #42b7b9"
      />
      <path
        d="M363.62,130.3a3.78,3.78,0,0,0-1.27-.63l-.4-.11-.48-.09A3.5,3.5,0,0,0,358,130.7l-.06-1.07c-.09-1.84,1.6-2.86,3.79-2.26l.66.18c2.19.59,4,2.57,4.13,4.41l.07,1.14A9.48,9.48,0,0,0,363.62,130.3Z"
        style="fill: #94d0d0"
      />
      <path
        d="M339.52,170.1c-5.55-3.23-13.22-20.15-8.45-29.67,1.8-3.59,3.06-6.52,6.18-6.68,4-.2,2.43-2,3.27,1.89l1.43,1.25s-1.2-2.69-6.13,4.48c-3.69,5.38.6,20.63.6,20.63Z"
        style="fill: #1f9ea1"
      />
      <path
        d="M334.75,112.11c-4.61.58-14,3.5-14.14,3.93s1.27,7.27,4,13c.66,1.38,1.72,7,2.22,7.72.92,1.33,2.71,3.53,2.71,3.53s3.76,4.34,4.24,4.47c2.24.58,8.24-4.92,8.73-5.44,5.69-6,.27-16.13-.94-18.49C340.12,118,334.75,112.11,334.75,112.11Z"
        style="fill: #f2a370"
      />
      <path
        d="M325.86,131.35c-.62.19-1.52,3-1.38,3.79s2.06,1.77,2.37,1.66,2.14-2.09,2.07-2.47S326.21,131.25,325.86,131.35Z"
        style="fill: #f2a370"
      />
      <path d="M334.23,108h0C334.22,108.08,334.22,108.08,334.23,108Z" style="fill: #e63c59" />
      <path d="M334.23,108h0C334.22,108.08,334.22,108.08,334.23,108Z" style="fill: #e63c59" />
      <path
        d="M338.29,122.23c1.55-1.9,15.74,9.78,14.39,8.13-4.54-5.51,4.29-6.57-.44-19.34-2-5.45-4.28-5.87-7.71-8.93s-10.69-2.87-17,1.22-6.92,8.42-7,11.74a55,55,0,0,0,.57,7.73,29.16,29.16,0,0,1,3.39-1.21,30.36,30.36,0,0,1-1-5,5,5,0,0,1,.29-2.15,20.57,20.57,0,0,0,1.07,7,29,29,0,0,1,5-1"
        style="fill: #e63c59"
      />
      <path
        d="M332.63,94.73c-1.15,1.56-2.34,3-2,5s2.08,3.37,5.14,2.51,5.12.54,7.11,1.2,3.8,1.33,5-1,1.12-3.74.1-5.26C345.38,93.33,335.92,90.22,332.63,94.73Z"
        style="fill: #e63c59"
      />
      <path
        d="M336.84,120.91s2-2.38,2.84-2.21,3.26,6.15,3.14,7.24-3,2.91-3,2.91A30.81,30.81,0,0,0,336.84,120.91Z"
        style="fill: #f2a370"
      />
      <path d="M336.88,120.89s1.25-2.91,3.58-2.8,4.77,4.21,2.65,7.55-4.23-.8-4.23-.8" style="fill: #f2a370" />
      <path
        d="M314.46,169.34s-.51-4.45-3.72-5.86-5.83,1.27-5.83,1.27l4.34,6.45a22.66,22.66,0,0,0,3.55,1.49C314,173,314.46,169.34,314.46,169.34Z"
        style="fill: #f2a370"
      />
    </g>
    <g id="kabas-login__girl">
      <path
        d="M143.12,437.36s6.52-1.78-1.54-4.81-12.29,4-12.59,8.23.2,8.64-3.12,12.1-3.94,9.36-3.48,12.52,1.22,8.6,5.46,8.64,10.74-3.71,14.46-2.89,6.08,6.39,9.75,7.66,8.25-1.91,8.25-1.91c5.28-3,5.47-10.83,4-14.6a7.66,7.66,0,0,1,1.09-7.05s-2.18-7.52-7.65-11S149.45,435.55,143.12,437.36Z"
        style="fill: url(#kabas-login-gradient-3)"
      />
      <path
        d="M170.93,589.77s3.14,5.81,1.32,6.56-5.72,2.86-3.72,3.18-1.55,1,.34-.12,5.06-3,7-3.45,2.14-1.33,1.64-2.42a39.58,39.58,0,0,0-2.61-4.4S173,587,170.93,589.77Z"
        style="fill: #f8cab3"
      />
      <path
        d="M185,562.72s-1.22,5.24.67,6.64,4,3.09,3.79,4.14c-.31,1.3-2.52,1.68-6,2.19-2.54.36-.47-.75-.77-1.82a34.53,34.53,0,0,1-.75-4.39,36,36,0,0,0-1-5.94C180.58,561.58,184.48,560.41,185,562.72Z"
        style="fill: #f8cab3"
      />
      <path
        id="SVGID-5"
        data-name="SVGID"
        d="M179.84,557.73s.73,5.15.93,6,1,5.3,1.17,7.22.41,3.92,1.7,4.75,3.06-.34,4.46-.69,2.05-1.55,1.43-2.56-3.81-3.23-4.18-3.83.05-5,.07-6,.46-5.14.46-5.14A30.06,30.06,0,0,0,179.84,557.73Z"
        style="fill: #f3a470"
      />
      <g style="clip-path: url(#kabas-login-clip-path-2)">
        <path
          d="M179.77,557.75s.8,5.13,1,6,1,5.3,1.17,7.22.41,3.92,1.7,4.75,3.06-.34,4.46-.69,2.05-1.55,1.43-2.56-3.81-3.23-4.18-3.83.05-5,.07-6,.46-5.14.46-5.14A31.42,31.42,0,0,0,179.77,557.75Z"
          style="fill: #f3a470"
        />
        <path
          d="M184.54,568.86a1.91,1.91,0,0,0,1,.31c.6,0-1.32-.15-.83-.52a1,1,0,0,0,.35-.47.9.9,0,0,0,0-1.06c-.18-.25-.35-.54-.56-.78a1.17,1.17,0,0,0-.29-.38c-.51-.43,1.5-.22.92.13a2,2,0,0,0-.32.25,2.63,2.63,0,0,0-.56.47C183.54,567.49,183.53,568.58,184.54,568.86Z"
          style="fill: #fdf4f1"
        />
        <path
          d="M185.48,568.28c.24.29-2.08.19-1.7.05a.79.79,0,0,0,.51-1A.82.82,0,0,0,184,567a.92.92,0,0,0-.4-.15,1.17,1.17,0,0,0-.45.06c-.08,0,2.62.26,2.55.32l0,0A.74.74,0,0,0,185.48,568.28Z"
          style="fill: #f6a735"
        />
        <path
          d="M180.69,573a1.84,1.84,0,0,0,.94.31,3.1,3.1,0,0,0,2-.33,1,1,0,0,0,.35-.46.9.9,0,0,0-.05-1.06,8,8,0,0,0-.56-.78,1,1,0,0,0-.29-.38,1.61,1.61,0,0,0-1.85-.08,1.65,1.65,0,0,0-.32.26,2.7,2.7,0,0,0-.57.47C179.68,571.59,179.68,572.68,180.69,573Z"
          style="fill: #fdf4f1"
        />
        <path
          d="M181.63,572.38a1,1,0,0,0,1.07.25.77.77,0,0,0,.22-1.39.91.91,0,0,0-.4-.14,1.16,1.16,0,0,0-.45,0,.76.76,0,0,0-.23.13s0,0,0,0A.73.73,0,0,0,181.63,572.38Z"
          style="fill: #f6a735"
        />
        <path
          d="M186.22,571.37a1.94,1.94,0,0,0-.94.29c-.49.3-1.17.77-1.29,1.32a.89.89,0,0,0,0,.54,1,1,0,0,0,.78.77,9,9,0,0,0,1,.27,1.32,1.32,0,0,0,.48.13,1.49,1.49,0,0,0,1.49-1,1.51,1.51,0,0,0,.07-.37,2,2,0,0,0,.12-.66A1.3,1.3,0,0,0,186.22,571.37Z"
          style="fill: #fdf4f1"
        />
        <path
          d="M185.9,572.33a1,1,0,0,0-1,.4.74.74,0,0,0,.31,1.06,1,1,0,0,0,.47.1.81.81,0,0,0,.41-.11,1,1,0,0,0,.31-.29.43.43,0,0,0,.09-.22s0,0,0,0A.75.75,0,0,0,185.9,572.33Z"
          style="fill: #f6a735"
        />
      </g>
      <g style="clip-path: url(#kabas-login-clip-path-3)">
        <path
          d="M167.26,584.9s2.78,4.36,2.67,5,4.73,4.77,3,5.89-4.44,1.85-4.91,3,.22,1.67,3.14,1.34-2.61.07-.48-1.18a64.18,64.18,0,0,1,5.92-3c1.6-.72,1.51-1.45,1.22-2.5s-2.51-3.84-2.88-4.9-1.64-5.59-1.64-5.59A41.8,41.8,0,0,0,167.26,584.9Z"
          style="fill: #f3a470"
        />
        <path
          d="M175,595.05a1.83,1.83,0,0,0-1-.12,3.06,3.06,0,0,0-1.85.68,1,1,0,0,0-.24.52.9.9,0,0,0,.27,1,7.4,7.4,0,0,0,.71.65,1,1,0,0,0,.36.32,1.63,1.63,0,0,0,1.84-.27,1.92,1.92,0,0,0,.26-.32,2.63,2.63,0,0,0,.46-.56C176.28,596.2,176.06,595.13,175,595.05Z"
          style="fill: #fdf4f1"
        />
        <path
          d="M174.21,595.8a1,1,0,0,0-1.1,0,.78.78,0,0,0-.3,1.11.94.94,0,0,0,.36.29,1.05,1.05,0,0,0,.43.07,1.36,1.36,0,0,0,.43-.14.79.79,0,0,0,.2-.17l0,0A.72.72,0,0,0,174.21,595.8Z"
          style="fill: #f6a735"
        />
        <path
          d="M174.7,590.88a.93.93,0,0,1-.85.67.83.83,0,0,1-.94-.71.78.78,0,0,1,.11-.44.86.86,0,0,1,.3-.3,1.08,1.08,0,0,1,.43-.13.47.47,0,0,1,.26,0h.05A.75.75,0,0,1,174.7,590.88Z"
          style="fill: #f6a735"
        />
      </g>
      <path
        d="M135.58,448.78s-1-1.41-1.65-1.08-1.1,5.84-.67,6.7a5.33,5.33,0,0,0,2.05,1.61A23.58,23.58,0,0,1,135.58,448.78Z"
        style="fill: #d79163"
      />
      <path
        d="M157.33,452s1.38-1,1.92-.47-1,5.87-1.67,6.53a5.28,5.28,0,0,1-2.47.84A23.68,23.68,0,0,0,157.33,452Z"
        style="fill: #d79163"
      />
      <path
        d="M134.84,519.75s-.16,4.14-.1,5a22,22,0,0,1-.23,5.48,1.85,1.85,0,0,1-1.29,1.35c0-.12-2.13-7.1-2-7.75S134.84,519.75,134.84,519.75Z"
        style="fill: #f3a470"
      />
      <path
        d="M136.2,515.77c.4.81.28,9.23.19,10.55-.06.81-1.25.68-1.4.32a39.81,39.81,0,0,1-1.18-5.21C133.75,521,135.46,514.31,136.2,515.77Z"
        style="fill: #f3a470"
      />
      <path
        d="M136.2,516.59c.42-2.14-2.79-4.9-5.07-.92-.64,1.11-2.19,7.75-2.37,8.59s2.9,6.86,3.25,7.3,1.3.13,1.38-.06c.14-.36-1.43-6-1.59-7.1C131.73,523.89,135.86,518.3,136.2,516.59Z"
        style="fill: #f3a470"
      />
      <path
        d="M147.18,509.23s10.72-.33,13.77-.77S174,503.78,174,503.78s6.06,8.63,7.26,11.06,3.18,6.79,3.54,9.85-8.36,6.18-18.91,8.24-16.7,2.56-18.14.36-3.18-9.12-2.23-14.83S147.26,510,147.18,509.23Z"
        style="fill: #212a4d"
      />
      <path
        d="M152.25,524.48c5.7-.64,13.63,3.31,13.44,6.89-.95,18.3,9.51,52.62,9.51,52.62-2.37,2-7.53,1.26-7.53,1.26a52.39,52.39,0,0,1-5.33-9.59c-2.51-5.7-8.15-18-10.26-24.12-2.35-6.78-5.63-21.54-5.63-21.54C145.63,527.4,146.54,525.11,152.25,524.48Z"
        style="fill: #212a4d"
      />
      <path
        d="M158.41,508.71s3.24,14.41,4.32,17.54a44.43,44.43,0,0,0,3.18,6.68l2-.4s-3.21-7.61-4.07-12-2.05-12.18-2.05-12.18Z"
        style="fill: #213663"
      />
      <path
        d="M176.26,519.56c5.23-2.25,7.2-.15,7.9,2.47,0,0,1.42,4.2,3.08,11.15,1.41,5.88,1.76,3.87.63,14.83-.78,7.56-1,9.31-1,9.31s-3.16,2.47-6,2.67c-.64,0-1.46-.25-1.47-.84-.53-23-10.33-23.87-11.56-26.95C166.49,528.85,171,521.81,176.26,519.56Z"
        style="fill: #212a4d"
      />
      <path d="M160.09,508.67a.82.82,0,1,0,.33,1.61A.82.82,0,1,0,160.09,508.67Z" style="fill: #d7bcb1" />
      <path
        d="M167.3,509.56s5.63-1,8.57-3.11l3.35,5s-6.48,3.07-8.06,2.75S167.3,509.56,167.3,509.56Z"
        style="fill: #213663"
      />
      <path
        d="M146.74,512a33.18,33.18,0,0,0,5.88.34,41.28,41.28,0,0,1-1.53,5.47c-.45.65-5.55.61-5.55.61S146.13,512.93,146.74,512Z"
        style="fill: #213663"
      />
      <path
        d="M173.71,582.32c.33-.11,1.48-.8,1.69-.32s.52,2,.17,2.32a21.33,21.33,0,0,1-5.82,1.79c-1.85.14-3.31.49-3.46,0s-.64-2.16-.33-2.53,1.6-.22,3-.33S173.71,582.32,173.71,582.32Z"
        style="fill: #213663"
      />
      <path
        d="M179.73,557.69c-.35,0-1.69-.08-1.66.44s.51,2,1,2.14a21.45,21.45,0,0,0,6-.93c1.71-.68,3.17-1,3.08-1.52s-.45-2.21-.9-2.4-1.54.5-2.8,1S179.73,557.69,179.73,557.69Z"
        style="fill: #213663"
      />
      <path
        d="M140.9,476.39c-.72,1.56-3.81,1-3.24,5.65s4.39,11,4.51,12a6.36,6.36,0,0,0,1,2.33c.4.84-.14,2.2.21,3.4a26.52,26.52,0,0,0,2,3.67c.38.79,3.27,2.13,6.28,1.19a6.21,6.21,0,0,0,4.3-5.1c.09-1.4,1,3.07,0-2C148.7,464.09,142.57,472.75,140.9,476.39Z"
        style="fill: #1b6e7f"
      />
      <path
        d="M151.55,496.27c2,3.76.56,4.23-.75,6.07s-1.84,2-2.58,3.3-9.25,8.13-9.41,9.06-2.77,2.44-4.13.73-2-2.66-2.32-3-.08-1.32.93-2.87,9.36-14.31,11.32-16.48S150.67,494.6,151.55,496.27Z"
        style="fill: #1b6e7f"
      />
      <g style="clip-path: url(#kabas-login-clip-path-4)">
        <path
          d="M158.26,462.55s3.31-10,5.31-9.09c0,0,8.79,17.64,8.26,22.1s2,15.87,3.08,17.07,2.46,2.23,2.2,3.3-1.05,3.1-1,3.47,4.26,3.74,4.48,4.76-5.4,2.1-7.59,2.92-9.05,3.41-11.57,3.48-8.37,2.13-9.15,2.24c-2.59.35-2.57.44-4.23.61a15.51,15.51,0,0,0-3.39.83,20.53,20.53,0,0,1-.59-5.7c.14-2.16,1.77-4.66-.62-8.44-13.49-21.32-9.62-35-9.62-35l13-.95S149.81,469.66,158.26,462.55Z"
          style="fill: #124c73"
        />
        <path
          d="M169.24,500.75c-.13-.14-.23-.27-.32-.38a12.28,12.28,0,0,1-2-3.26,8.48,8.48,0,0,1,.38-5.7,16,16,0,0,1-2.2-3.55,7.62,7.62,0,0,1,.18-6.09,15.81,15.81,0,0,1-2.64-3,8.43,8.43,0,0,1-.82-7.87,12.66,12.66,0,0,1-.94-1.12c-1.77-2.46-1.54-5.09-.89-7.64-1.42-1.43-2.86-2.66-3.5-3.31-1-1,.25-2.39,1.19-1.44.76.76,1.84,1.75,3,2.86a5,5,0,0,0-.45-3.71.92.92,0,1,1,1.53-1,7.77,7.77,0,0,1,.61,6.13,1,1,0,0,1-.1.26c2.62,2.91,4.65,6.31,2.12,8.95a14.33,14.33,0,0,1,1.73,2,10.21,10.21,0,0,1,1.55,8.59,13.4,13.4,0,0,1,1.84,2.53,8,8,0,0,1,0,7.54c2.1,2.93,4.19,5.83,2.38,8.75a.89.89,0,0,1-.17.42l.05.05a8.35,8.35,0,0,1,1.28,2.05,3.72,3.72,0,0,1,.5,1.73c.17,1.05.19,2.71-.43,3.47-.4.5-1,.31-1.41-.16a.94.94,0,0,1-1.56-.11A7.16,7.16,0,0,1,169.24,500.75Zm2.31,5.82,0,0c-.08.06.07-.71.07-.7a3.87,3.87,0,0,0,0-.48,1,1,0,0,1-.11-.72.9.9,0,0,0,0-.23,4.75,4.75,0,0,0-.33-1,6.91,6.91,0,0,0-.54-.83A5.08,5.08,0,0,0,171.55,506.57Zm-10-42.72a5.91,5.91,0,0,0,1.14,5.24l.19.23C164.35,468,163.28,465.87,161.59,463.85Zm2.52,9.46c-.26-.34-.54-.65-.82-.95-1.17,2.77.68,5.1,2.74,7.35A8.37,8.37,0,0,0,164.11,473.31Zm4,16.32a6.32,6.32,0,0,0-1.39-6.33C165.87,485.44,166.8,487.55,168.15,489.63Zm.59,3.79a5.94,5.94,0,0,0-.16,2.56,7.33,7.33,0,0,0,1.61,3C171.09,497.44,170.1,495.43,168.74,493.42Z"
          style="fill: #1b6e7f"
        />
        <path
          d="M156,510.45a.92.92,0,0,1-1.28.81.86.86,0,0,1-1.14.78,5.15,5.15,0,0,1-2.24-2.55,3.3,3.3,0,0,1-.5-1.6,6.89,6.89,0,0,1,0-2.22s0,0,0-.06a.92.92,0,0,1-.37-.26c-3.14-1.48-2.9-4.74-2.68-8a7.62,7.62,0,0,1-4.07-6,10.9,10.9,0,0,1,.22-2.85,9.25,9.25,0,0,1-3.31-7.53,12.1,12.1,0,0,1,.39-2.42c-3.6-.92-3.68-4.55-3-8.06a1.88,1.88,0,0,1-.23-.16c-1.5-1.39-2.77-3-2.79-5.15,0-1.24,1.86-1.14,1.87.1a4.53,4.53,0,0,0,1.62,3.15c.36-1.39.76-2.67,1-3.62.3-1.19,2.1-.6,1.81.59-.21.81-.79,2.45-1.23,4.24,1.93,1.72,3.55,3.7,3.35,6.46a9.55,9.55,0,0,1-.2,1.32A7.77,7.77,0,0,1,146.8,484a12.85,12.85,0,0,1-.63,3.62,7.28,7.28,0,0,1,3.44,4.75,13.78,13.78,0,0,1,0,3.83,8.33,8.33,0,0,1,3.4,4.33,10.34,10.34,0,0,1,0,3.51c0,.13,0,.28-.06.45A6.62,6.62,0,0,1,156,510.45Zm-3.34-3.84a5.63,5.63,0,0,0,0,.91,4.3,4.3,0,0,0,.28,1,1.15,1.15,0,0,0,.14.17.93.93,0,0,1,.3.62,3.27,3.27,0,0,0,.28.37s.54.53.44.53h0A4.61,4.61,0,0,0,152.69,506.61ZM141.48,476.7c0-.09,0-.18,0-.27a5.28,5.28,0,0,0-1.85-4.68C139.31,474.13,139.53,476.3,141.48,476.7Zm2.93,9.67c.56-2.73.9-5.43-1.6-7.09a10.68,10.68,0,0,0-.2,1.13A7.29,7.29,0,0,0,144.41,486.37Zm1.32,3.18a5.64,5.64,0,0,0,2.21,5.66C148,492.94,147.64,490.84,145.73,489.55Zm5.53,14.06a6.18,6.18,0,0,0-.26-3.17,5.79,5.79,0,0,0-1.52-1.95C149.4,500.71,149.63,502.76,151.26,503.61Z"
          style="fill: #1b6e7f"
        />
        <path
          d="M164.12,508.76a1,1,0,0,1-1.37.66.88.88,0,0,1-1.22.63,4.8,4.8,0,0,1-1.93-2.63,3.08,3.08,0,0,1-.31-1.57,6.3,6.3,0,0,1,.25-2.1l0-.06a.91.91,0,0,1-.35-.28c-2.94-1.69-2.33-4.77-1.73-7.86a7.06,7.06,0,0,1-3.36-6.06,9.7,9.7,0,0,1,.55-2.68,8.43,8.43,0,0,1-2.42-7.45,11.34,11.34,0,0,1,.67-2.26c-3.47-1.21-3.14-4.66-2.06-7.94a.79.79,0,0,1-.2-.17c-1.33-1.45-2.41-3.12-2.18-5.14.14-1.18,2-.91,1.85.27a4.16,4.16,0,0,0,1.24,3.14c.52-1.29,1.07-2.47,1.42-3.35.43-1.1,2.16-.37,1.72.73-.29.75-1.06,2.25-1.71,3.91,1.73,1.81,3.1,3.84,2.59,6.45a8.67,8.67,0,0,1-.36,1.23,7.1,7.1,0,0,1,2.76,6.61,12.25,12.25,0,0,1-1,3.39,6.72,6.72,0,0,1,2.87,4.82,12.67,12.67,0,0,1-.42,3.63,7.67,7.67,0,0,1,2.88,4.43,9.44,9.44,0,0,1-.37,3.34q0,.18-.12.42A6,6,0,0,1,164.12,508.76Zm-2.88-3.95a5.54,5.54,0,0,0-.12.86,4,4,0,0,0,.16,1,1.09,1.09,0,0,0,.13.17.89.89,0,0,1,.22.62,2.92,2.92,0,0,0,.24.37s.47.56.37.55l0,0A4.19,4.19,0,0,0,161.24,504.81Zm-7.68-29.42c0-.09.05-.17.07-.25a4.8,4.8,0,0,0-1.3-4.62C151.7,472.75,151.67,474.83,153.56,475.39Zm1.79,9.45c.88-2.55,1.53-5.07-.77-6.88a9.76,9.76,0,0,0-.32,1.05A6.64,6.64,0,0,0,155.35,484.84Zm.94,3.14a5.16,5.16,0,0,0,1.55,5.57C158.15,491.4,158.05,489.38,156.29,488Zm3.88,13.85a5.59,5.59,0,0,0,.11-3,5.45,5.45,0,0,0-1.28-2C158.65,498.91,158.65,500.88,160.17,501.83Z"
          style="fill: #1b6e7f"
        />
      </g>
      <path d="M153,459.29l3,6.13c.27.53-1.56,9.9-10.37,4.85l-3.22-6.93Z" style="fill: #d79163" />
      <path
        d="M140.12,438c.12,0,17.81,2.45,18.06,2.74s-.58,11.37-.81,12.54-2.7,7.5-3.57,8.35a69.53,69.53,0,0,1-7.41,4.49,9.24,9.24,0,0,1-3.47-.64c-.47-.37-7.15-6.23-7.47-7.68-.13-.65-1-7.52.19-9.28S140.12,438,140.12,438Z"
        style="fill: #f3a470"
      />
      <path
        d="M181.06,562s4.2-1.14,5.45,2c.37.94.49,4.6,1.91,6.42s2.62,2.48,2.16,4.84-.57,2.63-3.89,2.32-9.34-2.85-10.08-5.42S180.61,561.78,181.06,562Z"
        style="fill: #343a63"
      />
      <path
        d="M177.09,568.94a9.29,9.29,0,0,0,3.7,3.82c2.85,1.76,5.27,1.67,7,2.19a5.65,5.65,0,0,0,2.77.24s.76,2.47-2.62,2.45S173.61,574.57,177.09,568.94Z"
        style="fill: #292c4e"
      />
      <path d="M180.3,567.89c-.54.35.29,1.1.84.75S180.85,567.53,180.3,567.89Z" style="fill: #181d32" />
      <path d="M181.09,565.7c-.55.35.29,1.1.84.75S181.64,565.34,181.09,565.7Z" style="fill: #181d32" />
      <path d="M181.49,563.44c-.54.35.29,1.11.84.75S182,563.08,181.49,563.44Z" style="fill: #181d32" />
      <path d="M185.85,569.71c-.35.49.71,1,1.06.54S186.19,569.22,185.85,569.71Z" style="fill: #181d32" />
      <path d="M185.66,567.34c-.34.49.72,1,1.07.54S186,566.85,185.66,567.34Z" style="fill: #181d32" />
      <path d="M185.1,565c-.35.49.72,1,1.06.54S185.45,564.5,185.1,565Z" style="fill: #181d32" />
      <path
        d="M174.11,585.85c-.16.16-4.22,3.31-4.52,4.1s-1.13,7.92-2.65,9.56-3.66,4.7-2.43,6.94,2.55,1.79,3.57,1.36a32.35,32.35,0,0,0,8.85-8.16c2.8-4,5.93-8.11,6.21-9.11s-1.21-1.5-2.3-2S174.33,585.64,174.11,585.85Z"
        style="fill: #343a63"
      />
      <path
        d="M175.55,595.92c-1.78,3.44-3.67,6.78-7.44,9.58a22.68,22.68,0,0,1-2.93,1.9c.8,1.05,1.88.84,2.9.41a32.35,32.35,0,0,0,8.85-8.16c2.8-4,6.19-8.38,6.48-9.37s-2.3-1.6-3.39-2.08C180,588.2,177,593.21,175.55,595.92Z"
        style="fill: #292c4e"
      />
      <path d="M169.55,596.84c-.55.54.15,1.15.7.6S170.09,596.3,169.55,596.84Z" style="fill: #181d32" />
      <path d="M170.5,594.2c-.54.54.16,1.14.7.6S171.05,593.65,170.5,594.2Z" style="fill: #181d32" />
      <path d="M171.11,591.58c-.54.54.16,1.15.7.6S171.65,591,171.11,591.58Z" style="fill: #181d32" />
      <path
        d="M180,590.91l-2.47,3.62a.71.71,0,0,0,.18,1.1h0a1.1,1.1,0,0,0,1.25-.47l2.46-3.62a.69.69,0,0,0-.18-1.09h0A1.07,1.07,0,0,0,180,590.91Z"
        style="fill: #343a63"
      />
      <path
        d="M140.65,438.42s3-.92,6.52,2.05a10.24,10.24,0,0,0,2.37,1.47c1.53.68,3.79,2.16,3.69,4.83a8.94,8.94,0,0,0,4.18,7.62,25.84,25.84,0,0,1,3.67,2.68s4.1,2.46,5.14-4.89-2.93-8.65-5.35-12.28-2.33-5.76-5.76-7.94-10.54-2.72-13.68.2A4.76,4.76,0,0,0,140.65,438.42Z"
        style="fill: #983848"
      />
      <path
        d="M143.83,438s.63,2.71-4.14,5c0,0-1.85.21-3.17,5.26s-6.49,7.32-6.49,7.32l1.79-13.42s.21-12.33,12.25-8.74S143.83,438,143.83,438Z"
        style="fill: #983848"
      />
      <path
        d="M160.76,413.07s-3.61-3.63-4.48-4.36c-.48-.39-3.86-3.23-4.76-4.81a2.17,2.17,0,0,1-.15-2.24c.11.1,8.18,4.15,8.68,4.79S160.76,413.07,160.76,413.07Z"
        style="fill: #f3a470"
      />
      <path
        d="M163.22,417.58c-1.06-.33-8.57-7.54-9.68-8.73-.68-.73.43-1.64.88-1.47a49.69,49.69,0,0,1,5.67,3.39C160.54,411.09,165.15,418.19,163.22,417.58Z"
        style="fill: #f3a470"
      />
      <path
        d="M162.48,416.9c1.58,2.15,6.73,1.74,5-3.57-.47-1.48-5.18-8.41-5.79-9.28s-8.61-3.3-9.28-3.37-1.21,1-1.1,1.23c.2.43,6.58,3.82,7.73,4.63C159.59,406.91,161.21,415.16,162.48,416.9Z"
        style="fill: #f3a470"
      />
      <path
        d="M170,474.56c-.84-1.61-7.74-12.26-7.32-17.21s3.57-7.55,3.66-8.66a7.07,7.07,0,0,1,.93-2.56c.4-.92-.25-2.32.09-3.63a29.1,29.1,0,0,1,2-4.07c.38-.87,3.44-2.52,6.73-1.75a6.37,6.37,0,0,1,4.86,5.1c.16,1.49-6,18.68-7.4,24.08S172,478.3,170,474.56Z"
        style="fill: #1b6e7f"
      />
      <path
        d="M173,443.79c-4.83-.49-4.44-2.07-5.45-4.39s-.84-2.9-1.66-4.36-2.34-13.38-3.15-14.09-.71-4,1.77-4.2,3.81-.24,4.32-.27,1.34.72,2.24,2.59,8.29,17.25,9.22,20.36S175.14,444,173,443.79Z"
        style="fill: #1b6e7f"
      />
    </g>
    <g id="kabas-login__boy">
      <path
        d="M26,617s1.84-3.47,4.3-.56c.75.88,2.75,5.72,4.74,7.07s3.25,1.48,4,4.4.8,3.3-2,4.64-8.72,1.56-10.53-1S25,618.34,26,617Z"
        style="fill: #665d55"
      />
      <path
        d="M25.42,627.72a8.59,8.59,0,0,0,4.74,2.46c3.09.54,5-.8,6.58-1.12a4,4,0,0,0,2.3-1.15,2.8,2.8,0,0,1-.89,4.15C35.47,633.78,25.36,635.93,25.42,627.72Z"
        style="fill: #4d4740"
      />
      <path d="M27.64,625.11a.55.55,0,0,0,1,.43A.55.55,0,0,0,27.64,625.11Z" style="fill: #322c27" />
      <path d="M27.1,622.51a.55.55,0,0,0,1,.43A.55.55,0,0,0,27.1,622.51Z" style="fill: #322c27" />
      <path d="M27.07,620a.55.55,0,0,0,1,.42A.55.55,0,0,0,27.07,620Z" style="fill: #322c27" />
      <path d="M32.69,624.1a.55.55,0,1,0,1.1.07A.55.55,0,1,0,32.69,624.1Z" style="fill: #322c27" />
      <path d="M31.42,621.49a.55.55,0,1,0,1.09.07A.55.55,0,1,0,31.42,621.49Z" style="fill: #322c27" />
      <path d="M30.3,619.24a.55.55,0,1,0,1.1.07A.55.55,0,0,0,30.3,619.24Z" style="fill: #322c27" />
      <path d="M54.35,603.12s8.93-7.77,7.74-3.14.08,16.62-.23,17.42-4.54,2.61-4.78-.09" style="fill: #1f9ea2" />
      <path
        d="M62.5,617.08s5.37,5.25,9.18,6.21,4.66,1.37,4.64,2.52-3.83,1.45-7.38,1.72-7.7-1.81-10.2-2.29S56.35,625,56.4,623a24.12,24.12,0,0,1,.68-5.71C57.5,616.12,61.27,615,62.5,617.08Z"
        style="fill: #1f9ea2"
      />
      <path
        d="M34.34,607.05s1.4.4-1,4.45-2.78,4.6-3,5.85c-.14.84-2.73,2.56-3.77-1-.32-1.09,1.3-28.67,11.63-22.82C41.57,595.47,30.34,605.63,34.34,607.05Z"
        style="fill: #1f9ea2"
      />
      <path
        d="M62.93,548.9c-.09.25-.9,13.62-2.36,16.48s-26.43-6-27.39-7.44,5.78-15.23,6.47-15.51S62.93,548.9,62.93,548.9Z"
        style="fill: #f8cab3"
      />
      <path
        d="M34.82,545.22l30.86,1.4L66,611l-15.18,1.62L48,574.16l.34-.47L41.9,593c-1.44,1.69-6.62,20.27-6.62,20.27l-13.34-1.83,7.14-35.17Z"
        style="fill: #1a3353"
      />
      <path
        d="M53.34,549.36s-.29,13.12-1.14,16.95a18.63,18.63,0,0,1-3.89,7.2l-.61-1.25a16.22,16.22,0,0,0,2.85-7.38c.79-4.89.43-15.52.43-15.52Z"
        style="fill: #1f4569"
      />
      <polygon points="64.63 554.88 57.76 556.46 58.03 557.8 64.9 556.21 64.63 554.88" style="fill: #1f4569" />
      <polygon points="50.11 612.35 66.92 610.83 66.71 614.27 49.91 615.79 50.11 612.35" style="fill: #1f4569" />
      <polygon points="21.11 610.96 36.54 613.17 35.73 616.51 20.31 614.29 21.11 610.96" style="fill: #1f4569" />
      <path
        d="M56.88,617.4c.2,0,5.08-1.28,5.83-.91s6.58,5.49,8.63,5.43,5.51.49,6.53,3.48,0,3.85-.89,4.44-6.4,2.17-10.92,1.47-9.31-1.21-10.21-1.76-.48-2.37-.25-3.78S56.6,617.43,56.88,617.4Z"
        style="fill: #665d55"
      />
      <path
        d="M64,627c3.55,1.19,7.09,2.19,11.23,1a15.77,15.77,0,0,0,3-1.14,2.6,2.6,0,0,1-1.25,3c-.88.6-6.4,2.17-10.92,1.47s-9.31-1.21-10.21-1.76-.31-3.43-.08-4.84C55.77,624.71,61.19,626,64,627Z"
        style="fill: #4d4740"
      />
      <path d="M67.93,622.14c.7-.06.77,1.09.07,1.14S67.22,622.19,67.93,622.14Z" style="fill: #322c27" />
      <path d="M65.42,620.84a.58.58,0,0,1,.07,1.15C64.79,622,64.72,620.9,65.42,620.84Z" style="fill: #322c27" />
      <path d="M63.12,619.24a.58.58,0,0,1,.08,1.15A.58.58,0,0,1,63.12,619.24Z" style="fill: #322c27" />
      <path
        d="M57.85,626.9l4,.7a.93.93,0,0,1,.73,1.07h0a.91.91,0,0,1-1,.77l-4.05-.7a.94.94,0,0,1-.73-1.08h0A.9.9,0,0,1,57.85,626.9Z"
        style="fill: #665d55"
      />
      <polygon points="35.12 551.94 43.94 553.97 43.66 555.31 34.84 553.27 35.12 551.94" style="fill: #1f4569" />
      <polygon points="65.89 591.34 65.95 590.45 59.68 590.06 59.63 590.94 65.89 591.34" style="fill: #1e426e" />
      <polygon points="58.85 593 65.3 593.4 65.32 593.04 58.88 592.64 58.85 593" style="fill: #1e426e" />
      <polygon points="57.79 591.88 64.59 592.31 64.62 591.8 57.82 591.38 57.79 591.88" style="fill: #1e426e" />
      <path
        d="M78,491.42a78.36,78.36,0,0,0,.21-9.77c-.23-5,0-6.73,0-8.64s2.28-3.42,3.48-2.14,5.06,18.52,2.75,22.81C83.24,495.94,79.51,493.4,78,491.42Z"
        style="fill: #d79163"
      />
      <path
        d="M62.9,513.84c-.47-7.72,15.41-22.34,16.25-23.58s5.25-2,5.31,3.18S70.62,515.9,70.17,516.55,63.26,519.7,62.9,513.84Z"
        style="fill: #d79163"
      />
      <path
        d="M64.7,518.79s-5.06-3.68-1.64-10.38c4.53-8.89,7.18-9.69,7.18-9.69l11.11,6.08S72.41,521.49,64.7,518.79Z"
        style="fill: #1f9ea2"
      />
      <path
        d="M73.41,465.4a12,12,0,0,0,4.21,6.75C79.38,473.26,82,472,82,472c.79-3.41,2.12-7.64,1.73-9.53-.32-1.53-3.65-3.5-5.15-3.67-4.3-.49-3.06,1.47-3.38,4.69-.13,1.3-1.76,1.88-1.76,1.88"
        style="fill: #d79163"
      />
      <rect
        x="71.67"
        y="455.78"
        width="9.62"
        height="2.56"
        rx="1.28"
        transform="translate(-377.42 550.5) rotate(-92.13)"
        style="fill: #d79163"
      />
      <rect
        x="75.87"
        y="454.9"
        width="8.38"
        height="2.56"
        rx="1.22"
        transform="translate(-383.02 464.02) rotate(-81.02)"
        style="fill: #d79163"
      />
      <rect
        x="79.41"
        y="457.87"
        width="7.82"
        height="2.56"
        rx="1.18"
        transform="translate(-380.79 410.49) rotate(-73.73)"
        style="fill: #d79163"
      />
      <rect
        x="69.89"
        y="462.78"
        width="7.82"
        height="2.56"
        rx="1.18"
        transform="translate(-283.94 768.74) rotate(-121.39)"
        style="fill: #d79163"
      />
      <path
        d="M42.42,511.61C34.83,513.2,25,532.32,24,533.46s-.53,5.57,4.49,4.26,18-19.23,18.52-19.84S48.19,510.41,42.42,511.61Z"
        style="fill: #d79163"
      />
      <path
        d="M46.22,507.72s-1.95-1.29-5.19.95-9,11.11-9,11.11l9.41,7.65S53.62,513.22,46.22,507.72Z"
        style="fill: #1f9ea2"
      />
      <path
        d="M68.29,513.89l1.62,1.51-3.3,39.7s-2.57,7.22-17.14,3a52.43,52.43,0,0,1-16.63-8.33l12.8-42.23L58,509.37s1.53.36,4,1C66.6,511.48,64.88,510.71,68.29,513.89Z"
        style="fill: #1f9ea2"
      />
      <path
        d="M49.46,538.34a2.72,2.72,0,0,1-.32-1.24,3.91,3.91,0,0,0,3.65.68,2.7,2.7,0,0,0,2-2.58,3.2,3.2,0,0,1,.87,1.06A2.66,2.66,0,0,1,54,540.09,3.83,3.83,0,0,1,49.46,538.34Z"
        style="fill: #45b7b9"
      />
      <path
        d="M60.9,556.35a2.73,2.73,0,0,0,2-2.59,3.3,3.3,0,0,1,.87,1.06,2.66,2.66,0,0,1-1.64,3.83,3.82,3.82,0,0,1-4.52-1.74,2.64,2.64,0,0,1-.32-1.25A3.92,3.92,0,0,0,60.9,556.35Z"
        style="fill: #45b7b9"
      />
      <path
        d="M64,524.43a2.65,2.65,0,0,0,0,3.22,3.86,3.86,0,0,0,3.34,1.57,2.76,2.76,0,0,1-1,.81,3.85,3.85,0,0,1-4.7-1.23,2.66,2.66,0,0,1,1-4A3.68,3.68,0,0,1,64,524.43Z"
        style="fill: #45b7b9"
      />
      <path
        d="M49.64,518.29a3.62,3.62,0,0,1-3.76-2.88,2.45,2.45,0,0,1,.15-1.24,3.85,3.85,0,0,0,3.3,1.66A3,3,0,0,0,52.18,514a2.71,2.71,0,0,1,.46,1.22A2.85,2.85,0,0,1,49.64,518.29Z"
        style="fill: #45b7b9"
      />
      <path
        d="M44.59,526.31a2.67,2.67,0,0,1,.15-1.23,3.84,3.84,0,0,0,3.3,1.65,3,3,0,0,0,2.86-1.83,3,3,0,0,1,.46,1.22,2.86,2.86,0,0,1-3,3.08A3.61,3.61,0,0,1,44.59,526.31Z"
        style="fill: #45b7b9"
      />
      <path
        d="M63.24,541.64a3.51,3.51,0,0,1,1,1,3.42,3.42,0,0,0-3.4,1,2.63,2.63,0,0,0-.13,3.2,4.12,4.12,0,0,1-1.33-.57,2.82,2.82,0,0,1-.85-4.19A3.63,3.63,0,0,1,63.24,541.64Z"
        style="fill: #45b7b9"
      />
      <path
        d="M45.22,548.33a3.79,3.79,0,0,1,4.29,2.21,2.71,2.71,0,0,1-2.19,3.58,3.68,3.68,0,0,1-1.44,0,2.59,2.59,0,0,0,.95-3A3.63,3.63,0,0,0,44,548.88,3.18,3.18,0,0,1,45.22,548.33Z"
        style="fill: #45b7b9"
      />
      <path
        d="M40.52,538.5a3.37,3.37,0,0,0-2.28-2.68,3.72,3.72,0,0,1,5.08,2.64,2.86,2.86,0,0,1-3.07,3,4,4,0,0,1-1.43-.24A2.67,2.67,0,0,0,40.52,538.5Z"
        style="fill: #45b7b9"
      />
      <path
        d="M12.09,541.8c3.75-2.59,9.71-10.71,14.13-11.39,5.57-.86,5.2,5.7,4.06,6.59-4.26,3.32-19.57,9.75-19.57,9.75S8.18,544.5,12.09,541.8Z"
        style="fill: #f3a470"
      />
      <path
        d="M6.82,551.72a14.7,14.7,0,0,0,6.79-5.33c1.11-2-.18-4.41-.18-4.41-3.47-.28-7.78-1-9.7-.28-1.54.56-3.5,4.25-3.65,5.8-.45,4.46,1.53,2.89,4.8,2.72,1.32-.07,1.94,1.5,1.94,1.5"
        style="fill: #f3a470"
      />
      <path d="M65.44,505.25c0,.14-.13,8.64-.13,8.64s-6.78,8.58-11.89-3.31l2.23-11.37Z" style="fill: #d79163" />
      <path
        d="M51.29,498.47s-2.66,1.35-2.75,2.27S53.82,505.81,55,506s4-2.56,4-2.56A24.38,24.38,0,0,1,51.29,498.47Z"
        style="fill: #d79163"
      />
      <path
        d="M48.39,489.14s5-5.11,8.81-8.1,5.6-2.32,5.86-2.11,3.33,3.85,6.16,7.18a26.89,26.89,0,0,1,5.86,15.75c-.19.65-3.58,4-4.62,4.52s-9.42.71-10.73.11c-3.45-1.59-5.81-6.63-7.18-8.1S48.09,489.43,48.39,489.14Z"
        style="fill: #cc8a5e"
      />
      <path
        d="M40.68,489.18s10.34.22,11.73-.32,8.29-6.33,9.65-7.37,4-1.84,3.27-5a31.82,31.82,0,0,0-3.19-7.08,9,9,0,0,1-4.78,3.63c-3.62,1.37-12.47,4.59-14.9,8.55A10.74,10.74,0,0,0,40.68,489.18Z"
        style="fill: #61352c"
      />
      <path
        d="M40.68,489.18a14.52,14.52,0,0,0,3.89,7.32A36.29,36.29,0,0,0,49,500s2-1.49,2.54-1.13a29.44,29.44,0,0,1,3.09,2.43s-.69-2-1.28-3.48c-2.22-5.52-1-8.92-1-8.92S41.28,488.24,40.68,489.18Z"
        style="fill: #874a3d"
      />
    </g>
  </g>
</svg>
